import * as React from "react";

import { Helmet } from "react-helmet";

interface PayrollPageProps {}

interface PayrollPageState {}

export default class PayrollPage extends React.Component<PayrollPageProps, PayrollPageState> {
  constructor(props: PayrollPageProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.title = "Payroll services | Acader Pty Ltd";
  }

  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Payroll services | Acader Pty Ltd</title>
          <meta name="description" content="Acader Pty Ltd Support" />
        </Helmet>
        <div className="theme-primary">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1>Payroll servcies</h1>
                <div className="h1Divider"></div>
                <br />
                <p>Hassle free payment</p>
              </div>
            </div>
          </div>
        </div>

        <div className="theme-white payroll">
          <div className="container">
            <section className="pricing py-5">
              <div className="container">
                <div className="row">
                  <div className="col-lg-5">
                    <div className="card mb-5 mb-lg-0">
                      <div className="card-body">
                        <h5 className="card-title text-muted text-uppercase text-center">Standard</h5>
                        <h6 className="card-price text-center">1.5%</h6>
                        <hr />
                        <ul className="fa-ul">
                          <li>
                            <span className="fa-li">
                              <i className="fas fa-check"></i>
                            </span>
                            No contract
                          </li>
                          <li>
                            <span className="fa-li">
                              <i className="fas fa-check"></i>
                            </span>
                            Superannuation paid quaterly
                          </li>
                          <li>
                            <span className="fa-li">
                              <i className="fas fa-check"></i>
                            </span>
                            Free online timesheet
                          </li>
                          <li>
                            <span className="fa-li">
                              <i className="fas fa-check"></i>
                            </span>
                            Referal discount of 0.25% per candidate
                          </li>
                          <li>
                            <span className="fa-li">
                              <i className="fas fa-check"></i>
                            </span>
                            Fully insured
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="card mb-5 mb-lg-0">
                      <div className="card-body">
                        <h5 className="card-title text-muted text-uppercase text-center">Comfort</h5>
                        <h6 className="card-price text-center">2.5%</h6>
                        <hr />
                        <ul className="fa-ul">
                          <li>
                            <span className="fa-li">
                              <i className="fas fa-check"></i>
                            </span>
                            No contract
                          </li>
                          <li>
                            <span className="fa-li">
                              <i className="fas fa-check"></i>
                            </span>
                            Superannuation paid monthly
                          </li>
                          <li>
                            <span className="fa-li">
                              <i className="fas fa-check"></i>
                            </span>
                            Free online timesheet
                          </li>
                          <li>
                            <span className="fa-li">
                              <i className="fas fa-check"></i>
                            </span>
                            Referal discount of 0.25% per candidate
                          </li>
                          <li>
                            <span className="fa-li">
                              <i className="fas fa-check"></i>
                            </span>
                            Fully insured
                          </li>
                          <li>
                            <span className="fa-li">
                              <i className="fas fa-check"></i>
                            </span>
                            Advanced payment
                          </li>
                          <li>
                            <span className="fa-li">
                              <i className="fas fa-check"></i>
                            </span>
                            Salary sacrifice (work related equipments)
                          </li>
                          <li>
                            <span className="fa-li">
                              <i className="fas fa-check"></i>
                            </span>
                            Novated lease
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="row">
              <div className="col">
                <h3>Pay cycle</h3>
                <hr />
                <p>
                  When on{" "}
                  <b>
                    <u>Standard</u>
                  </b>{" "}
                  package your pay cycle depends on your recruitement agency. We will pay you immediately upon receipt of payment. When you are on the{" "}
                  <b>
                    <u>Comfort</u>
                  </b>{" "}
                  package, we will pay you in advance. Your pay cycle still depends on the pay cycle from your recruitement agency.
                </p>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col">
                <h3>Terms and conditions</h3>
                <hr />
                <p>
                  Maximum discount for referrals can go up 0% of your payroll fees. For example, if you are on a standard package, you can take advantage of
                  referal program by refering a maximum of 6 candidates.
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
