import { Col, Container, Row } from "reactstrap";

export const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="theme theme-dark-blue">
          <Container className="text-center">
            <Row>
              <Col xs={12} md={4}>
                <i className="fas fa-copyright me-1"></i> Copyright Acader Pty Ltd 2024
              </Col>
              <Col xs={12} md={4}>
                ABN 92 611 649 873
              </Col>
              <Col xs={12} md={4}>
                <a href="/privacy" target="_blank">
                  Privacy policy
                </a>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <Col>
                  <a href="/">
                    <img src="/images/acader-logo-white.png" alt="Acader Pty Ltd"></img>
                  </a>
                </Col>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};
