/* eslint-disable react/jsx-no-target-blank */
import { useEffect, useState } from "react";
import { getRoles, getUsers, IRole, IUser, IUserRole, modeOptions, register, update, userExists, userSchema } from "../../../model/user";
import Select from "react-select";
import { BaseProps } from "../../../model/auth";
import { IXeroCustomer, contacts } from "../../../model/xero";
import { MainContent } from "../../../components/mainContent";

import { Alert, Button, Col, Container, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from "reactstrap";
import { UserActions } from "./ userActions";

import { currencyBlock, ISelectOption, percentBlock } from "../../../model/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { ToggleMenu } from "../../../components/toggleMenu";
import { IMaskInput } from "react-imask";
import { ISystemVariable, list } from "../../../model/systemVariable";
import { useParams } from "react-router-dom";

interface UsersPageProps extends BaseProps {}

export const UsersPage = (props: UsersPageProps) => {
  const { id } = useParams();
  const newUser: IUser = {
    XeroAccountCode: "200",
    UserRoles: [],
  };
  const [registererror, setRegistererror] = useState<string>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [users, setUsers] = useState<IUser[]>([]);
  const [variables, setVariables] = useState<ISystemVariable[]>([]);
  const [mode, setMode] = useState<"Create" | "Update">("Create");
  const [selectedRoles, setSelectedRoles] = useState<ISelectOption[]>([]);
  const [selectedXeroCustomer, setSelectedXeroCustomer] = useState<ISelectOption>();
  const [user, setUser] = useState<IUser>(newUser);
  const [userErrors, setUserErrors] = useState<IUser>({});
  const [xeroCustomerOptions, setXeroCustomerOptions] = useState<ISelectOption[]>([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    const payrollVariable = variables?.find((x) => x.Name === "PayrollTax");
    if (payrollVariable) {
      const payrollPercentage = parseFloat(payrollVariable?.Value);
      const PayrollTaxAmount = user?.PayrollFeeNotApplicable ? 0 : Math.round(((user?.TotalRate * payrollPercentage) / 100) * 100) / 100;
      const payRollIncomeAmount = user?.PayrollIncomePercentage ? Math.round(((user?.TotalRate * user?.PayrollIncomePercentage) / 100) * 100) / 100 : 0;
      const rateMinusPayrollFee = user?.TotalRate - PayrollTaxAmount - payRollIncomeAmount;

      const ChargeoutRate = user?.TotalRate + PayrollTaxAmount + user?.ServiceFee;
      const superVariable = variables?.find((x) => x.Name === "SuperAnnuation");
      if (superVariable) {
        const superPercentage = parseFloat(superVariable?.Value);
        const RateExclusiveSuper = Math.round((rateMinusPayrollFee / (1 + superPercentage / 100)) * 100) / 100;
        setUser((x) => ({ ...x, RateExclusiveSuper, PayrollTaxAmount, ChargeoutRate }));
      }
    }
  }, [user?.TotalRate, user?.ServiceFee, user?.PayrollFeeNotApplicable, user?.PayrollIncomePercentage]);

  const initialize = async () => {
    setLoading(true);
    let response: any = await getUsers(props.tokenUser?.Company?.Id, true);
    if (response && response.status === 200) {
      let users: IUser[] = response.data?.users;
      users = users.filter(
        (x) =>
          (x.UserRoles?.length === 0 && id.toLocaleLowerCase() === "employee") || x.UserRoles.find((x) => x.Role.toLocaleLowerCase() === id.toLocaleLowerCase())
      );
      setUsers(users);
      let variableResponse: any = await list();
      if (variableResponse && variableResponse.status === 200) {
        let variables: ISystemVariable[] = variableResponse.data?.variables;

        setVariables(variables);
      }
    }
    setLoading(false);
  };

  const initXeroContacts = async () => {
    if (props.tokenUser?.Company?.Subscription === "Exclusive") {
      let response: any = await contacts(props.tokenUser?.Id);
      if (response.data?.auth) {
        let xeroCustomers: IXeroCustomer[] = response.data?.contacts.contacts;
        let options: ISelectOption[] = [];
        xeroCustomers?.map((x) => {
          options.push({ label: x.name, value: x.contactID });
        });
        setXeroCustomerOptions(options);
      }
    }
  };

  const checkUserName = async (value: string) => {
    if (user?.Id || !user?.Email) {
      return;
    }
    let response: any = await userExists(user?.Email);
    if (response && response.status === 200) {
      if (response.data?.exists) {
        setRegistererror("User already registered!!");
      } else {
        setRegistererror(null);
      }
    } else {
      setRegistererror("Server error occured");
    }
  };

  const addOrUpdateUser = async () => {
    try {
      await userSchema.validateSync(user, {
        abortEarly: false,
      });
      if (registererror) {
        return;
      }
      setShowModal(false);
      setLoading(true);
      let u: IUser = user;
      u.Password = user?.Password;
      let response: any;
      if (mode === "Create") {
        response = await register(u);
      } else {
        console.log(u);
        response = await update(u);
      }
      if (response && response.status === 200) {
        await initialize();
      } else {
        setRegistererror("An error occurred");
      }

      setLoading(false);
    } catch (yupErrors) {
      let errors: IUser = {};
      yupErrors?.inner?.map((x: any) => {
        errors[x.path] = x.message;
      });
      console.log(errors);
      setUserErrors(errors);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="theme-primary">
        <Container>
          <Row>
            <Col xs={10}>
              <h1>Users</h1>
              <div className="h1Divider"></div>
            </Col>
            <Col xs={2} className="text-end">
              <ToggleMenu onClick={(toggle) => props.onMenuToggleClick(toggle)} />
            </Col>
          </Row>
        </Container>
      </div>
      <MainContent loading={loading}>
        <Modal size="lg" isOpen={showModal} toggle={() => setShowModal((x) => (x = !x))}>
          <ModalHeader>
            <h5 className="modal-title">{mode === "Create" ? "Add a new user" : "Update user"}</h5>
          </ModalHeader>
          <ModalBody>
            {registererror && <div className="alert alert-danger">{registererror}</div>}
            <Alert color="info">User details</Alert>
            <Row>
              <Col>
                <FormGroup>
                  <Label className="required">Email (user name)</Label>
                  <Input
                    id="txtEmail"
                    type="text"
                    value={user.Email}
                    onChange={(e) => {
                      e.persist();
                      setUser((x) => ({ ...x, Email: e.target.value }));
                    }}
                    onBlur={async (e) => {
                      e.persist();
                      await checkUserName(e.target.value);
                    }}
                  />
                  {userErrors.Email && <Label className="text-danger">{userErrors.Email}</Label>}
                </FormGroup>
                <FormGroup>
                  <Label className="required">First name</Label>
                  <Input
                    id="txtFirstname"
                    type="text"
                    value={user.Firstname}
                    onChange={(e) => {
                      e.persist();
                      setUser((x) => ({ ...x, Firstname: e.target.value }));
                    }}
                  />
                  {userErrors.Firstname && <Label className="text-danger">{userErrors.Firstname}</Label>}
                </FormGroup>
                <FormGroup>
                  <Label className="required">Last name</Label>
                  <Input
                    id="txtLastname"
                    type="text"
                    value={user.Lastname}
                    onChange={(e) => {
                      e.persist();
                      setUser((x) => ({ ...x, Lastname: e.target.value }));
                    }}
                  />
                  {userErrors.Lastname && <Label className="text-danger">{userErrors.Lastname}</Label>}
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label className="required">Manager's name</Label>
                  <Input
                    id="txtManagerName"
                    type="text"
                    value={user.ManagerName}
                    onChange={(e) => {
                      e.persist();
                      setUser((x) => ({ ...x, ManagerName: e.target.value }));
                    }}
                  />
                  {userErrors.ManagerName && <Label className="text-danger">{userErrors.ManagerName}</Label>}
                </FormGroup>
                <FormGroup>
                  <Label className="required">Manager's email</Label>
                  <Input
                    id="txtManagerEmail"
                    type="text"
                    value={user.ManagerEmail}
                    onChange={(e) => {
                      e.persist();
                      setUser((x) => ({ ...x, ManagerEmail: e.target.value }));
                    }}
                  />
                  {userErrors.ManagerEmail && <Label className="text-danger">{userErrors.ManagerEmail}</Label>}
                </FormGroup>
                <FormGroup>
                  <Label className="required">Timesheet mode</Label>
                  <select
                    className="form-control"
                    defaultValue={user?.Mode?.toString()}
                    onChange={(e) => {
                      e.persist();
                      setUser((x) => ({ ...x, Mode: e.target.value as modeOptions }));
                    }}
                  >
                    <option value={null}>Please select...</option>
                    <option value={"Monthly"}>Monthly</option>
                    <option value={"Weekly"}>Weekly</option>
                  </select>
                  {userErrors.Mode && <Label className="text-danger">{userErrors.Mode}</Label>}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Roles</Label>
                  <Select
                    placeholder="Select roles..."
                    isMulti
                    value={selectedRoles}
                    name="colors"
                    options={getRoles(props.tokenUser?.Roles)}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(x) => {
                      let roles: IUserRole[] = [];
                      let roleOptions: ISelectOption[] = [];
                      x.map((i) => {
                        roles.push({ UserId: user.Id, Role: i.value as IRole });
                        roleOptions.push(i);
                      });
                      setSelectedRoles(roleOptions);
                      setUser((x) => ({ ...x, UserRoles: [...roles] }));
                    }}
                  />
                  {userErrors.UserRoles && <Label className="text-danger">{userErrors.UserRoles?.toString()}</Label>}
                </FormGroup>
              </Col>
            </Row>
            {props.tokenUser?.Company?.Subscription === "Exclusive" && user?.UserRoles?.find((x) => x.Role === "Employee") && (
              <>
                <Alert color="info">Accounting details</Alert>
                <hr />
                <Row>
                  <Col>
                    <FormGroup>
                      <Label className="required">Rate (inc super)</Label>
                      <IMaskInput
                        id={`txtRate`}
                        className="form-control"
                        value={user?.TotalRate ? user?.TotalRate?.toString() : ""}
                        mask="$num"
                        unmask={true}
                        blocks={currencyBlock}
                        onAccept={(value: string) => {
                          let v = value ? parseFloat(value) : null;
                          setUser((x) => ({
                            ...x,
                            TotalRate: v,
                          }));
                        }}
                        placeholder="0"
                      ></IMaskInput>
                      {userErrors.TotalRate && <Label className="text-danger">{userErrors.TotalRate}</Label>}
                    </FormGroup>
                    <FormGroup>
                      <Label className="required">Payroll income (%)</Label>
                      <IMaskInput
                        id={`txtServiceFee`}
                        className="form-control"
                        value={user?.PayrollIncomePercentage ? user?.PayrollIncomePercentage?.toString() : ""}
                        mask="num %"
                        unmask={true}
                        lazy={true}
                        blocks={percentBlock}
                        onAccept={(value: string) => {
                          let v = value ? parseFloat(value) : null;
                          setUser((x) => ({
                            ...x,
                            PayrollIncomePercentage: v,
                          }));
                        }}
                        placeholder="0"
                      ></IMaskInput>
                    </FormGroup>
                    <FormGroup>
                      <Label>Payroll tax ({variables?.find((x) => x.Name === "PayrollTax")?.Value}%)</Label>
                      <span className="ms-2 me-2">Exclude tax</span>
                      <Input
                        id="chkExclude"
                        type="checkbox"
                        checked={user?.PayrollFeeNotApplicable}
                        onChange={(e) => {
                          setUser((x) => ({ ...x, PayrollFeeNotApplicable: e.currentTarget.checked }));
                        }}
                      />
                      <Input id="txtPayrollFee" disabled type="number" value={user?.PayrollTaxAmount} />
                    </FormGroup>

                    <FormGroup>
                      <Label className="required">Invoice number prefix</Label>
                      <Input
                        id="txtInvoiceNumberPrefix"
                        type="text"
                        value={user.InvoiceNumberPrefix}
                        onChange={(e) => {
                          e.persist();
                          setUser((x) => ({ ...x, InvoiceNumberPrefix: e.target.value }));
                        }}
                      />
                      {userErrors.InvoiceNumberPrefix && <Label className="text-danger">{userErrors.InvoiceNumberPrefix}</Label>}
                    </FormGroup>
                    <FormGroup>
                      <Label className="required">Contract number</Label>
                      <Input
                        id="txtContractNumber"
                        type="text"
                        value={user.ContractNumber}
                        onChange={(e) => {
                          e.persist();
                          setUser((x) => ({ ...x, ContractNumber: e.target.value }));
                        }}
                      />
                      {userErrors.ContractNumber && <Label className="text-danger">{userErrors.ContractNumber}</Label>}
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label className="required">Service fee</Label>
                      <IMaskInput
                        id={`txtServiceFee`}
                        className="form-control"
                        value={user?.ServiceFee ? user?.ServiceFee?.toString() : ""}
                        mask="$num"
                        unmask={true}
                        blocks={currencyBlock}
                        onAccept={(value: string) => {
                          let v = value ? parseFloat(value) : null;
                          setUser((x) => ({
                            ...x,
                            ServiceFee: v,
                          }));
                        }}
                        placeholder="0"
                      ></IMaskInput>
                    </FormGroup>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>Charge out rate</Label>
                          <Input id="txtSuper" disabled type="number" value={user?.ChargeoutRate} />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>Rate (ex super)</Label>
                          <Input id="txtEmployeeRate" disabled type="number" value={user?.RateExclusiveSuper} />
                        </FormGroup>
                      </Col>
                    </Row>

                    <FormGroup>
                      <Label className="required">Payroll type</Label>
                      <select
                        className="form-control"
                        value={user?.PayrollType?.toString()}
                        onChange={(e) => {
                          e.persist();
                          setUser((x) => ({ ...x, PayrollType: e.target.value as any }));
                        }}
                      >
                        <option value={null}>Please select...</option>
                        <option value={"PayG"}>PayG</option>
                        <option value={"Company"}>Company</option>
                      </select>
                      {userErrors.PayrollType && <Label className="text-danger">{userErrors.PayrollType}</Label>}
                    </FormGroup>
                    <FormGroup>
                      <Label className="required">Xero account code</Label>
                      <Input
                        id="txtAccountCode"
                        placeholder="Xero account code (eg: 200)"
                        type="text"
                        value={user.XeroAccountCode}
                        onChange={(e) => {
                          e.persist();
                          setUser((x) => ({ ...x, XeroAccountCode: e.target.value }));
                        }}
                      />
                      {userErrors.XeroAccountCode && <Label className="text-danger">{userErrors.XeroAccountCode}</Label>}
                    </FormGroup>
                    <FormGroup>
                      <Label className="required">Due date from invoice date</Label>
                      <select
                        className="form-control"
                        id="txtDaysFromInvoice"
                        value={user.DaysFromInvoice}
                        onChange={(e) => {
                          e.persist();
                          setUser((x) => ({ ...x, DaysFromInvoice: parseInt(e.target.value) }));
                        }}
                      >
                        <option value={null}>Please select...</option>
                        <option value={7}>7</option>
                        <option value={15}>15</option>
                        <option value={30}>30</option>
                      </select>
                      {userErrors.DaysFromInvoice && <Label className="text-danger">{userErrors.DaysFromInvoice}</Label>}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label className="required">Xero contacts</Label>
                      <Select
                        placeholder="Please select a customer from xero..."
                        isDisabled={props.tokenUser?.Source !== "Xero"}
                        value={selectedXeroCustomer}
                        name="colors"
                        options={xeroCustomerOptions}
                        onChange={(x) => {
                          setSelectedXeroCustomer(x);
                          setUser((y) => ({ ...y, ManagerCompanyId: x.value, ManagerCompanyName: x.label }));
                        }}
                      />
                    </FormGroup>
                    {userErrors.ManagerCompanyId && (
                      <>
                        <Label className="text-danger">{userErrors.ManagerCompanyId}</Label>
                        <br />
                      </>
                    )}
                  </Col>
                </Row>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="dark" data-dismiss="modal" onClick={() => setShowModal(false)}>
              Close
            </Button>
            <Button
              type="button"
              color="acader"
              data-dismiss="modal"
              onClick={async () => {
                await addOrUpdateUser();
              }}
            >
              <FontAwesomeIcon icon={faSave} className="me-2" />
              {mode === "Create" ? "Create a new user" : "Update user"}
            </Button>
          </ModalFooter>
        </Modal>

        <div className="theme-white">
          <Container>
            <Row>
              <Col xs={2} md={3}>
                <Button
                  onClick={async () => {
                    let n = newUser;
                    n.CompanyId = props.tokenUser?.Company?.Id;
                    setUser(newUser);
                    setSelectedRoles([]);
                    setSelectedXeroCustomer(null);
                    setMode("Create");
                    setRegistererror(null);

                    if (xeroCustomerOptions?.length === 0 && props.tokenUser?.Source === "Xero") {
                      setLoading(true);
                      await initXeroContacts();
                      setLoading(false);
                    }
                    setUserErrors({});
                    setShowModal(true);
                  }}
                  color="acader"
                >
                  <FontAwesomeIcon icon={faUserPlus} className="me-2" />
                  <span className="d-none d-md-inline">Add a new user</span>
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <Table striped responsive>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      {id.toLocaleLowerCase() === "employee" && (
                        <>
                          <th>Type</th>
                          <th>Rate / hr</th>
                          <th>Contract number</th>
                          <th>Client</th>
                        </>
                      )}
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {users?.map((x, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            {x.Firstname} {x.Lastname}
                          </td>
                          <td>{x.Email}</td>
                          {id.toLocaleLowerCase() === "employee" && (
                            <>
                              <td>{x.PayrollType}</td>
                              <td>${x.TotalRate?.toLocaleString()}</td>
                              <td>{x.ContractNumber}</td>
                              <td>{x.ManagerCompanyName}</td>
                            </>
                          )}
                          <td className="text-end">
                            <UserActions
                              {...props}
                              user={x}
                              onEdit={async () => {
                                let u: IUser = users.find((u) => u.Id === x.Id);
                                if (u) {
                                  let roleOptions: ISelectOption[] = [];
                                  u.UserRoles?.map((ur) => {
                                    roleOptions.push({ label: ur.Role, value: ur.Role });
                                  });
                                  setSelectedRoles(roleOptions);
                                  setUser(u);
                                  setSelectedXeroCustomer({ label: u.ManagerCompanyName, value: u.ManagerCompanyId });
                                  setMode("Update");
                                }
                                setRegistererror(null);
                                if (xeroCustomerOptions?.length === 0) {
                                  setLoading(true);
                                  await initXeroContacts();
                                  setLoading(false);
                                }
                                setUserErrors({});
                                setShowModal(true);
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Container>
        </div>
      </MainContent>
    </>
  );
};
