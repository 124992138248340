import { object, string } from "yup";
import { axiosAuth, tokenName } from "./auth";
import Cookies from "js-cookie";
import { disconnect } from "./xero";

export const loginSchema = object<ILogin>({
  username: string().email().label("Username").required(),
  password: string().label("Password").required(),
});

export interface ILogin {
  username?: string;
  password?: string;
}

export const logout = async () => {
  localStorage.setItem(tokenName, null);
  await deleteCookie();
  await disconnect();
  window.location.href = "/login";
};

export const login = async (email: string, password: string) => {
  let response = await axiosAuth.post(process.env.REACT_APP_API_URL + "/login", { email, password }).catch(function (error) {
    if (error.response) {
      console.log(error);
    }
  });
  return response;
};

export const deleteCookie = async () => {
  let response = await axiosAuth.post(process.env.REACT_APP_API_URL + "/logout").catch(function (error) {
    if (error.response) {
      console.log(error);
    }
  });
  return response;
};

export const refreshToken = async (id: string) => {
  let response = await axiosAuth.post(process.env.REACT_APP_API_URL + "/refreshToken", { id }).catch(function (error) {
    if (error.response) {
      console.log(error);
    }
  });
  return response;
};
