/* eslint-disable react/jsx-no-target-blank */
import { useEffect, useState } from "react";
import { MainContent } from "../../components/mainContent";
import { Button, Col, Container, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from "reactstrap";

import { BaseProps } from "../../model/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faSave } from "@fortawesome/free-solid-svg-icons";
import { ISystemVariable, list, update, variableSchema } from "../../model/systemVariable";
import { ToggleMenu } from "../../components/toggleMenu";

export const VariablesPage = (props: BaseProps) => {
  const [error, setError] = useState<string>(null);

  const [loading, setLoading] = useState<boolean>(true);
  const [variables, setVariables] = useState<ISystemVariable[]>([]);
  const [variable, setVariable] = useState<ISystemVariable>();
  const [variableErrors, setVariableErrors] = useState<ISystemVariable>({});
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    let response: any = await list();
    if (response && response.status === 200) {
      setVariables(response.data.variables);
    }
    setLoading(false);
  };

  const updateVariable = async () => {
    try {
      await variableSchema.validateSync(variable, {
        abortEarly: false,
      });
      if (error) {
        return;
      }
      setLoading(true);
      let v: ISystemVariable = variable;
      let response: any;

      response = await update(v);
      setShowModal(false);
      await initialize();

      setLoading(false);
    } catch (yupErrors) {
      let errors: ISystemVariable = {};
      yupErrors?.inner?.map((x: any) => {
        errors[x.path] = x.message;
      });
      setVariableErrors(errors);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="theme-primary">
        <Container>
          <Row>
            <Col xs={10}>
              <h1>System Variables</h1>
              <div className="h1Divider"></div>
            </Col>
            <Col xs={2} className="text-end">
              <ToggleMenu onClick={(toggle) => props.onMenuToggleClick(toggle)} />
            </Col>
          </Row>
        </Container>
      </div>
      <MainContent loading={loading}>
        <Modal fade id="userModal" isOpen={showModal} toggle={() => setShowModal((x) => (x = !x))}>
          <ModalHeader>
            <h5 className="modal-title">{"Update variable"}</h5>
          </ModalHeader>
          <ModalBody>
            {error && <div className="alert alert-danger">{error}</div>}
            <Row>
              <Col>
                <FormGroup>
                  <Label className="required">Name</Label>
                  <Input id="txtName" type="text" value={variable?.Name || ""} disabled />
                </FormGroup>
                <FormGroup>
                  <Label className="required">Value</Label>
                  <Input
                    id="txtEmail"
                    type="text"
                    value={variable?.Value || ""}
                    onChange={(e) => {
                      e.persist();
                      setVariable((x) => ({ ...x, Value: e.target.value }));
                    }}
                  />
                  {variableErrors.Value && <Label className="text-danger">{variableErrors.Value}</Label>}
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="dark" onClick={() => setShowModal(false)}>
              Close
            </Button>
            <Button color="acader" onClick={async () => await updateVariable()}>
              <FontAwesomeIcon icon={faSave} className="me-2"></FontAwesomeIcon>
              {"Update variable"}
            </Button>
          </ModalFooter>
        </Modal>

        <div className="theme-white">
          <Container>
            <Row>
              <Col>
                <Table responsive striped>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Value</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {variables?.map((x, i) => {
                      return (
                        <tr key={i}>
                          <td>{x.Name}</td>
                          <td>{x.Value}</td>
                          <td>
                            <Button
                              color="acader"
                              onClick={() => {
                                setVariable(x);
                                setShowModal(true);
                              }}
                            >
                              <FontAwesomeIcon icon={faPencil} className="me-2" />
                              Edit
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Container>
        </div>
      </MainContent>
    </>
  );
};
