import { useState, useEffect, Fragment } from "react";
import moment, { Duration, Moment } from "moment";
import { TimeEntryControl } from "./timeEntryControl";
import * as compliantTimesheetController from "../../../model/compliantTimesheet";
import { changeTimesheetStatus, ICompliantTimesheet, ICompliantTimesheetData, IDayOption, reject } from "../../../model/compliantTimesheet";
import TimePicker from "rc-time-picker";
import { BaseProps } from "../../../model/auth";
import { Alert, Button, Col, Container, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from "reactstrap";
import { MainContent } from "../../../components/mainContent";
import { importWeeklyTimesheet } from "../../../model/openai";
import { ChatCompletion } from "openai/resources";
import { useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faFileImport, faFileInvoice, faTimes } from "@fortawesome/free-solid-svg-icons";
import { TimeMobileEntryControl } from "./timeMobileEntryControl";
import { commentSchema } from "../../../model/common";
import { ToggleMenu } from "../../../components/toggleMenu";

interface WeeklyTimesheetControlProps extends BaseProps {
  mode: compliantTimesheetController.modeOption;
  timesheet?: any;
  firstName?: string;
  lastName?: string;
  managerName?: string;
  userId?: number;
}

interface TimeTemplate {
  start: Moment;
  end: Moment;
  breakDuration: Moment;
}

interface StorageTimeTemplate {
  startHours: number;
  startMinutes: number;
  endHours: number;
  endMinutes: number;
  breakHours: number;
  breakMinutes: number;
}

export const WeeklyTimesheetControl = (props: WeeklyTimesheetControlProps) => {
  const navigate = useNavigate();
  document.title = "Weekly timesheet | Welcome to Timesheet approval";

  const toTemplate = (timeTemplate: StorageTimeTemplate) => {
    let template: TimeTemplate = {
      start: moment({
        hours: timeTemplate.startHours,
        minutes: timeTemplate.startMinutes,
      }),
      end: moment({
        hours: timeTemplate.endHours,
        minutes: timeTemplate.endMinutes,
      }),
      breakDuration: moment({
        hours: timeTemplate.breakHours,
        minutes: timeTemplate.breakMinutes,
      }),
    };
    return template;
  };

  let storageTimeTemplate: StorageTimeTemplate = JSON.parse(localStorage.getItem("time-template"));
  let timeTemplate: TimeTemplate = {
    start: moment({ hours: 8, minutes: 0 }),
    end: moment({ hours: 16, minutes: 30 }),
    breakDuration: moment({ hours: 0, minutes: 30 }),
  };
  if (storageTimeTemplate) {
    let v = toTemplate(storageTimeTemplate);
    timeTemplate = v;
  }

  const [startDate, setStartDate] = useState<Moment>(props.timesheet ? moment(props.timesheet.StartDate).startOf("isoWeek") : moment().startOf("isoWeek"));
  const [endDate, setEndDate] = useState<Moment>(props.timesheet ? moment(props.timesheet.StartDate).endOf("isoWeek") : moment().endOf("isoWeek"));
  const [days, setDays] = useState<IDayOption[]>([]);
  const [timesheet, setTimesheet] = useState<ICompliantTimesheet>();
  const [successMessageContent, setSuccessMessageContent] = useState<string>("");
  const [totlaHours, setTotalHours] = useState<number>(0);
  const [template, setTemplate] = useState<TimeTemplate>(timeTemplate);
  const [userId, setUserId] = useState<number>(props.userId || props.tokenUser?.Id);
  const [loading, setLoading] = useState<boolean>(true);
  const [saved, setSaved] = useState<boolean>(false);
  const [importedTimesheetError, setImportedTimesheetError] = useState<string>();
  const [importFile, setImportFile] = useState<File>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showRejectModal, setShowRejectModal] = useState<boolean>(false);
  const [rejectComment, setRejectComment] = useState<string>();
  const [rejectCommentError, setRejectCommentError] = useState<string>();
  const [isMobile, setIsMobile] = useState<boolean>(false);

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // create an event listener
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
  }, []);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop: (files) => setImportFile(files?.at(0)),
    accept: {
      "application/pdf": [".pdf"],
    },
    multiple: false,
  });

  useEffect(() => {
    setImportedTimesheetError(null);
    initialise();
  }, [endDate]);

  useEffect(() => {
    if (saved) {
      setTimeout(() => {
        initialise();
        setSaved(false);
        setSuccessMessageContent("");
      }, 1000);
    }
  }, [saved]);

  const initialise = async () => {
    setLoading(true);

    let startDateString: string = startDate.format("DD MMMM YYYY");
    let year: string = startDate.format("YYYY");
    let timesheet: ICompliantTimesheet;
    if (props.timesheet) {
      timesheet = props.timesheet;
      if (!Array.isArray(props.timesheet?.Data)) {
        timesheet.Data = JSON.parse(props.timesheet.Data);
      }
    } else {
      let response: any = await compliantTimesheetController.getTimesheet(startDateString, userId);
      if (response && response.status === 200 && response.data.timesheet) {
        timesheet = response.data.timesheet;
        if (response.data.timesheet.Data) {
          timesheet.Data = JSON.parse(response.data.timesheet.Data);
        }
        if (response.data.timesheet.Comments) {
          timesheet.Comments = JSON.parse(response.data.timesheet.Comments);
        }
      } else {
        timesheet = {
          Data: null,
          Token: "",
          StartDate: startDateString,
          Status: "New",
          UserId: userId,
          TotalHours: 0,
          Year: year,
        };
      }
    }

    initialiseDays(timesheet);
    setTimesheet(timesheet);
    setLoading(false);
  };

  const initialiseDays = (t: ICompliantTimesheet) => {
    let days: IDayOption[] = [];
    let day: Moment = startDate.clone();
    while (day <= endDate) {
      let dateToAdd = day.clone();
      let data: ICompliantTimesheetData = null;
      if (t.Data) {
        data = t.Data.find((x) => x?.day === dateToAdd.format("D"));
        if (!data) {
          data = {
            day: dateToAdd.format("D"),
            hours: "0",
            start: "0",
            end: "0",
            breakDuration: "0",
          };
        }
      }
      days.push({ data: data, day: dateToAdd });
      day = day.add(1, "d");
    }
    setDays(days);
  };

  const isWeekday = (day: Moment) => {
    return day.day() !== 6 && day.day() !== 0;
  };

  const handleTemplateChange = () => {
    let v = toStorage(template);
    localStorage.setItem("time-template", JSON.stringify(v));

    if (days) {
      let lDays = [...days];
      let day: Moment = startDate.clone();
      let i = 0;
      while (day <= endDate) {
        let breakHour: Duration = moment.duration(template.breakDuration.hours(), "hours");
        let breakMinute: Duration = moment.duration(template.breakDuration.minutes(), "minutes");

        let duration = moment.duration(template.end.diff(template.start));
        if (breakHour.hours() > 0) {
          duration = duration.subtract(template.breakDuration.hours());
        }
        if (breakMinute.minutes() > 0) {
          duration = duration.subtract(breakMinute);
        }
        let totalHours = duration.hours().toString() + "." + duration.minutes().toString();
        let dateToAdd = day.clone();
        if (lDays[i].data) {
          if (isWeekday(day)) {
            lDays[i].data.start = moment(template.start).format("hh:mm a");
            lDays[i].data.end = moment(template.end).format("hh:mm a");
            lDays[i].data.breakDuration = `${v.breakHours}:${v.breakMinutes}`;
            lDays[i].data.day = dateToAdd.format("D");
            lDays[i].data.hours = totalHours;
          }
        } else {
          lDays[i].data = {
            start: moment(template.start).format("hh:mm a"),
            end: moment(template.end).format("hh:mm a"),
            breakDuration: `${v.breakHours}:${v.breakMinutes}`,
            day: isWeekday(day) ? dateToAdd.format("D") : "",
            hours: isWeekday(day) ? totalHours : "0",
          };
        }

        day = day.add(1, "d");
        i++;
      }
      setDays(lDays);
      saveTimesheet(lDays);
    }
  };

  const toStorage = (timeTemplate: TimeTemplate) => {
    let storageTemplate: StorageTimeTemplate = {
      startHours: timeTemplate.start.hour(),
      startMinutes: timeTemplate.start.minute(),
      endHours: timeTemplate.end.hour(),
      endMinutes: timeTemplate.end.minute(),
      breakHours: timeTemplate.breakDuration.hour(),
      breakMinutes: timeTemplate.breakDuration.minute(),
    };
    return storageTemplate;
  };

  const getStatusClass = () => {
    let className: string = "";
    switch (timesheet.Status) {
      case "New":
        className = "secondary";
        break;
      case "Draft":
        className = "warning";
        break;
      case "Sent":
        className = "info";
        break;
      case "Approved":
        className = "success";
        break;
      case "Rejected":
        className = "danger";
        break;
      case "Closed":
        className = "dark";
        break;
    }
    return className;
  };

  const getHeading = () => {
    let heading: any;
    if (props.mode === "employee") {
      heading = (
        <>
          <h1>
            Timesheet for week ending: {endDate.format("DD MMMM YYYY")}
            <span id="status-label" className={` ms-2 badge bg-${getStatusClass()}`}>
              {timesheet.Status}
            </span>
          </h1>
          {timesheet?.Status === "Rejected" && (
            <Alert color="warning">
              {timesheet?.Comments?.map((x, i) => {
                return (
                  <Fragment key={i}>
                    <p>
                      {x.userName}: "{x.comment}""
                    </p>
                  </Fragment>
                );
              })}
            </Alert>
          )}
        </>
      );
    } else {
      if (timesheet.Status === "Sent") {
        heading = (
          <>
            <h1>
              Please review timesheet for {props.firstName} {props.lastName}
            </h1>
            <h2>Duration: week ending {endDate.format("DD MMMM YYYY")}</h2>
          </>
        );
      } else if (timesheet.Status === "Approved" || timesheet.Status === "Closed" || timesheet?.Status === "Rejected") {
        heading = (
          <>
            <h1>
              Timesheet for {props.firstName} {props.lastName}: week ending {endDate.format("DD MMMM YYYY")}
              <span className={` ms-2 badge bg-${getStatusClass()}`}>{timesheet.Status}</span>
            </h1>
          </>
        );
      }
    }
    return heading;
  };

  const previous = () => {
    let sDate: Moment = startDate.clone().subtract(1, "weeks").startOf("isoWeek");
    let eDate: Moment = endDate.clone().subtract(1, "weeks").endOf("isoWeek");
    setStartDate(sDate);
    setEndDate(eDate);
  };

  const next = () => {
    let sDate: Moment = startDate.clone().add(1, "weeks").startOf("isoWeek");
    let eDate: Moment = endDate.clone().add(1, "weeks").endOf("isoWeek");
    setStartDate(sDate);
    setEndDate(eDate);
  };

  const sendForApproval = async () => {
    try {
      await saveTimesheet();
      setLoading(true);
      let t: ICompliantTimesheet = timesheet;
      t.Status = "Sent";
      let response: any = await compliantTimesheetController.sendForApproval(t);
      if (response && response.status === 200) {
        let successMessageContent: string = "Timesheet sent for approval!";
        setTimesheet(t);
        setSuccessMessageContent(successMessageContent);
        setSaved(true);
        setLoading(false);
        setShowModal(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const approve = async () => {
    try {
      setLoading(true);
      let t: ICompliantTimesheet = timesheet;
      t.Status = "Approved";
      let response: any = await compliantTimesheetController.approve(t.Id);
      if (response && response.status === 200) {
        let successMessageContent: string = "Timesheet has been approved!";
        setTimesheet(t);
        setSuccessMessageContent(successMessageContent);
        setSaved(true);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const rejectTimesheet = async () => {
    try {
      setRejectCommentError(null);
      await commentSchema.validateSync(rejectComment, {
        abortEarly: false,
      });
      setSaved(true);
      let t: ICompliantTimesheet = timesheet;
      t.Status = "Rejected";
      let response: any = await reject(timesheet?.Id, rejectComment, timesheet?.Owner?.ManagerName);
      if (response && response.status === 200) {
        setTimesheet(t);
        setShowRejectModal(false);
        setSaved(false);
      }
      initialise();
    } catch (err) {
      setRejectCommentError(err.message);
    } finally {
      setSaved(false);
    }
  };

  const closeTimesheet = async () => {
    let t = timesheet;
    t.Status = "Closed";
    let response: any = await compliantTimesheetController.changeTimesheetStatus(t);
    if (response && response.status === 200) {
      let successMessageContent: "This timesheet is now closed";
      setTimesheet(t);
      setSuccessMessageContent(successMessageContent);
      setSaved(true);
    }
  };

  const saveTimesheet = async (lDays?: IDayOption[]) => {
    try {
      setSaved(true);
      if (!lDays) {
        lDays = [...days];
      }

      let t: ICompliantTimesheet = timesheet;
      let data: ICompliantTimesheetData[] = [];
      t.Status = "Draft";

      let totalHours: number = 0;
      lDays.map((r) => {
        if (r.data && r.data.hours && r.data?.hours?.trim() !== "") {
          totalHours += hoursStringToDecimal(r.data.hours);
        }
        data.push(r.data);
      });

      t.TotalHours = totalHours;
      t.Data = data;
      let response: any = await compliantTimesheetController.saveTimesheet(t);
      if (response && response.status === 200) {
        let successMessageContent: string = "Timesheet saved successfully!";
        setTimesheet(t);
        setSuccessMessageContent(successMessageContent);
        setSaved(false);
        setTotalHours(totalHours);
      }
    } finally {
      setSaved(false);
    }
  };

  const footerActions = () => {
    let actions: any;
    switch (timesheet.Status) {
      case "New":
        if (props.mode === "employee") {
          actions = (
            <>
              <Button id="btn-new-save" color="acader" className="mt-3" onClick={() => saveTimesheet()}>
                <i className="fas fa-save me-1"></i>
                Save
              </Button>
            </>
          );
        } else {
          actions = <></>;
        }
        break;
      case "Draft":
      case "Rejected":
        if (props.mode === "employee") {
          actions = (
            <>
              <Button className="btn-success mt-3 me-3" onClick={() => setShowModal(true)}>
                <i className="fas fa-paper-plane me-1"></i>
                Send for approval
              </Button>
              <Button id="btn-draft-save" color="acader" className="mt-3" onClick={async () => await saveTimesheet()}>
                <i className="fas fa-save me-1"></i>
                Save
              </Button>
            </>
          );
        } else {
          actions = <></>;
        }
        break;
      case "Sent":
        if (props.mode === "employee") {
          actions = <></>;
        } else {
          actions = (
            <Row className="mt-3">
              <Col md={8}></Col>
              <Col md={4} className="text-end">
                <Button color="success" onClick={async () => await approve()}>
                  <FontAwesomeIcon icon={faCheck} className={"me-2"}></FontAwesomeIcon>
                  Approve
                </Button>
                <Button color="danger" onClick={async () => setShowRejectModal(true)} className="ms-2">
                  <FontAwesomeIcon icon={faTimes} className={"me-2"}></FontAwesomeIcon>
                  Reject
                </Button>
              </Col>
            </Row>
          );
        }
        break;
      case "Approved":
        if (props.mode === "manager") {
          actions = (
            <>
              <a
                href={`/weekly/token/${timesheet?.Token}`}
                className="btn btn-acader"
                role="button"
                aria-pressed="true"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fas fa-file-pdf me-2"></i>
                Print view
                <i className="fas fa-external-link-alt ms-2"></i>
              </a>
            </>
          );
        } else if (props.mode === "employee") {
          actions = (
            <>
              <a href={`/weekly/id/${timesheet?.Id}`} className="btn btn-acader" role="button" aria-pressed="true" target="_blank" rel="noopener noreferrer">
                <i className="fas fa-file-pdf me-2"></i>
                Print view
                <i className="fas fa-external-link-alt ms-2"></i>
              </a>
            </>
          );
        }
        break;
      case "Closed":
        actions = <></>;

        break;
    }
    return actions;
  };

  const hoursStringToDecimal = (hoursString: string) => {
    console.log(hoursString);
    const [hoursPart, minutesPart] = hoursString.indexOf(".") !== -1 ? hoursString.split(".") : [hoursString, "0"];
    return Number(hoursPart) + Number(minutesPart) / 60;
  };

  const handleChange = (start: string, end: string, breakDuration: string, date: Moment, hours: string) => {
    let totalHours = 0;
    let ds = [...days];
    let day: string = date.format("D");
    let existingData = ds.find((x) => x.day.format("D") === day);
    if (existingData.data) {
      existingData.data.start = start;
      existingData.data.end = end;
      existingData.data.breakDuration = breakDuration;
      existingData.data.hours = hours;
    } else {
      existingData.data = { start, end, breakDuration, day: day, hours };
    }
    ds?.map((x) => {
      totalHours += x.data?.hours && x.data?.hours?.trim() != "" ? hoursStringToDecimal(x.data?.hours) : 0;
    });
    let t = timesheet;
    t.TotalHours = totalHours;
    setTimesheet(t);
    setTotalHours(totalHours);
    setDays(ds);
  };

  return (
    <>
      <div className="theme theme-primary">
        <Container>
          <Row>
            <Col xs={10}>
              {timesheet && getHeading()}
              <div className="h1Divider"></div>
            </Col>
            <Col xs={2} className="text-end">
              {props.mode !== "manager" && <ToggleMenu onClick={(toggle) => props.onMenuToggleClick(toggle)} />}
            </Col>
          </Row>
        </Container>
      </div>
      <MainContent loading={loading} saved={saved}>
        <Modal fade id="confirmModal" isOpen={showModal} toggle={() => setShowModal((x) => (x = !x))}>
          <ModalHeader>
            <h5 className="modal-title" id="confirmSendModal">
              Confirm Send
            </h5>
          </ModalHeader>
          <ModalBody>
            <p>Are you sure you want to send your timesheet for approval?</p>
            <p>Press 'OK' to send now or 'Cancel' to return</p>
          </ModalBody>
          <ModalFooter>
            <Button color="dark" onClick={() => setShowModal(false)}>
              Cancel
            </Button>
            <Button color="acader" id="confirm-send" onClick={async () => await sendForApproval()}>
              <i className="fas fa-paper-plane me-1"></i>
              OK
            </Button>
          </ModalFooter>
        </Modal>

        <Modal fade id="confirmRejectModal" isOpen={showRejectModal} toggle={() => setShowRejectModal((x) => (x = !x))}>
          <ModalHeader>
            <h5 className="modal-title" id="confirmSendModal">
              Reject timesheet
            </h5>
          </ModalHeader>
          <ModalBody>
            <p>Please provide reason:</p>
            <Input
              type="textarea"
              onChange={(e) => {
                e.persist();
                setRejectComment(e.target.value);
              }}
            ></Input>
            {rejectCommentError && <Label className="text-danger">{rejectCommentError}</Label>}
          </ModalBody>
          <ModalFooter>
            <Button color="dark" onClick={() => setShowRejectModal(false)}>
              Cancel
            </Button>
            <Button color="danger" id="confirm-reject" onClick={async () => rejectTimesheet()}>
              <FontAwesomeIcon icon={faTimes} className="me-2"></FontAwesomeIcon>
              Reject
            </Button>
          </ModalFooter>
        </Modal>

        <div className="theme-white">
          <Container>
            <Row>
              <Col>
                {timesheet && props.mode === "employee" ? (
                  <>
                    {(timesheet?.Status === "Draft" || timesheet?.Status === "New") && (
                      <Row>
                        <Col>
                          <Alert color="secondary">
                            <i className="fa fa-question-circle me-2"></i>
                            (Optional) Set your default hours and click on the 'check' button to set hours for <b>Monday</b> to <b>Friday</b>.
                          </Alert>
                        </Col>
                      </Row>
                    )}
                    <Table responsive>
                      <tbody>
                        <tr className="d-flex">
                          <td className="col-2 text-start pl-0">
                            <Button color="acader" onClick={() => previous()}>
                              <i className="fas fa-chevron-left me-2"></i>
                              <span className="d-none d-md-inline">Previous</span>
                            </Button>
                          </td>
                          {timesheet?.Status !== "Draft" && timesheet?.Status !== "New" && <td className="col-8"></td>}
                          {(timesheet?.Status === "Draft" || timesheet?.Status === "New") && (
                            <>
                              <td className="col template-col">
                                <TimePicker
                                  showSecond={false}
                                  value={template.start}
                                  onChange={(e) => {
                                    let v = template;
                                    v.start = e;
                                    setTemplate(v);
                                  }}
                                  placeholder=""
                                  minuteStep={15}
                                />
                              </td>
                              <td className="col template-col">
                                <TimePicker
                                  showSecond={false}
                                  value={template.breakDuration}
                                  onChange={(e) => {
                                    let v = template;
                                    v.breakDuration = e;
                                    setTemplate(v);
                                  }}
                                  placeholder=""
                                  minuteStep={15}
                                />
                              </td>
                              <td className="col template-col">
                                <div className="input-group">
                                  <TimePicker
                                    showSecond={false}
                                    value={template.end}
                                    onChange={(e) => {
                                      let v = template;
                                      v.end = e;
                                      setTemplate(v);
                                    }}
                                    placeholder=""
                                    className="c-time"
                                    minuteStep={15}
                                  />
                                  <div className="input-group-append">
                                    <Button
                                      className="btn-dark"
                                      style={{
                                        height: "28px",
                                        paddingTop: "1px",
                                      }}
                                      onClick={() => handleTemplateChange()}
                                    >
                                      <i className="fas fa-check"></i>
                                    </Button>
                                  </div>
                                </div>
                              </td>
                            </>
                          )}
                          <td className="col-2 text-end pr-0">
                            <Button color="acader" onClick={() => next()}>
                              <span className="d-none d-md-inline">Next</span>
                              <i className="fas fa-chevron-right ms-2"></i>
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </Container>
          <Container id="pdf-content">
            <Row>
              <Col>
                {isMobile ? (
                  <Table responsive striped className="week-table">
                    {days.map((r, i) => (
                      <TimeMobileEntryControl mode={props.mode} key={i} day={r?.day} data={r?.data} status={timesheet?.Status} OnChange={handleChange} />
                    ))}
                  </Table>
                ) : (
                  <Table responsive striped className="week-table">
                    <thead>
                      <tr className="d-flex">
                        <th className="col-2">Day</th>
                        <th className="col">Start</th>
                        <th className="col">Break</th>
                        <th className="col">End</th>
                        <th className="col-2">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {days.map((r, i) => (
                        <TimeEntryControl mode={props.mode} key={i} day={r?.day} data={r?.data} status={timesheet?.Status} OnChange={handleChange} />
                      ))}
                    </tbody>
                  </Table>
                )}

                <div className="text-end">
                  <h2>
                    <span className="badge bg-dark">Total hours: {totlaHours.toFixed(2)}</span>
                  </h2>
                </div>
                <div className="text-end">{timesheet && footerActions()}</div>
              </Col>
            </Row>
          </Container>
          {props.tokenUser?.Roles?.find((x) => x === "PayrollAdmin" || x === "SuperAdmin") &&
            props.tokenUser?.Company?.Subscription === "Exclusive" &&
            timesheet?.Status !== "Approved" &&
            timesheet?.Status !== "Sent" && (
              <Container>
                {importedTimesheetError && <Alert color="danger">{importedTimesheetError}</Alert>}
                <Row>
                  <Col></Col>
                </Row>
                <Row>
                  <Label>Import pdf timesheet</Label>
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <p>Drag 'n' Drop logo, or click to select a logo</p>
                  </div>
                </Row>
                {importFile && (
                  <>
                    <br />
                    <Row className="mt-2">
                      <Col md={4}>{importFile.name}</Col>
                      <Col md={4}>
                        <Button
                          color="acader"
                          onClick={async () => {
                            setImportedTimesheetError(null);
                            setLoading(true);
                            let response: any = await importWeeklyTimesheet(importFile);
                            const chatChoices: ChatCompletion.Choice[] = response.data;

                            const choice = chatChoices.find((x) => x.message?.role === "assistant");
                            const importedTimesheet: ICompliantTimesheet = JSON.parse(choice.message.content);
                            console.log(importedTimesheet);
                            if (importedTimesheet.StartDate !== timesheet.StartDate) {
                              setImportedTimesheetError(
                                `Imported timesheet is for the month of ${importedTimesheet.StartDate} however the current timesheet is for ${timesheet.StartDate}. Import failed.`
                              );
                              setLoading(false);
                              return;
                            }

                            if (importedTimesheet.Year !== timesheet.Year) {
                              setImportedTimesheetError(
                                `Imported timesheet is for the year ${importedTimesheet.Year} however the current timesheet is year ${timesheet.Year}. Import failed.`
                              );
                              setLoading(false);
                              return;
                            }

                            if (importedTimesheet.Status !== "Approved") {
                              setImportedTimesheetError(`The imported timesheet is not approved`);
                              setLoading(false);
                              return;
                            }

                            importedTimesheet.Status = timesheet?.Status || "New";
                            importedTimesheet.UserId = userId || props.tokenUser?.Id;
                            setTimesheet(importedTimesheet);
                            initialiseDays(importedTimesheet);
                            setLoading(false);
                          }}
                        >
                          <FontAwesomeIcon icon={faFileImport} className="me-2"></FontAwesomeIcon>
                          Import
                        </Button>
                        {importFile && timesheet?.Status === "Draft" && (
                          <Button
                            className="ms-2"
                            color="success"
                            onClick={async () => {
                              setLoading(true);
                              const t = timesheet;
                              t.Status = "Approved";
                              await changeTimesheetStatus(t);
                              setLoading(false);
                            }}
                          >
                            <FontAwesomeIcon icon={faCheck} className="me-2"></FontAwesomeIcon>Review and mark as approve
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </>
                )}
              </Container>
            )}
          {timesheet?.Status === "Approved" && !timesheet.InvoiceId && importFile && (
            <Row>
              <Col md={3}>
                <Button
                  color="acader"
                  onClick={() => {
                    navigate("/timesheet/admin/invoices/" + userId, { state: importFile });
                  }}
                >
                  <FontAwesomeIcon icon={faFileInvoice} className="me-2"></FontAwesomeIcon>
                  Create invoice
                </Button>
              </Col>
              <Col md={3}>{importFile.name}</Col>
            </Row>
          )}
        </div>
      </MainContent>
    </>
  );
};
