import * as React from "react";
import moment from "moment";
import { Helmet } from "react-helmet";
import axios from "axios";

interface PublicHolidayRecord {
  Date: string;
  Information: string;
  Jurisdiction: string;
}

interface PublicHolidayResource {
  year: number;
  resourceId: string;
}

interface PublicHolidayProps {}

interface PublicHolidayState {
  selectedResourceId: string;
  resources: PublicHolidayResource[];
  records: PublicHolidayRecord[];
}

var r = [
  { year: 2019, resourceId: "bda4d4f2-7fde-4bfc-8a23-a6eefc8cef80" },
  { year: 2020, resourceId: "c4163dc4-4f5a-4cae-b787-43ef0fcf8d8b" },
  { year: 2021, resourceId: "2dee10ef-2d0c-44a0-a66b-eb8ce59d9110" },
  { year: 2022, resourceId: "768053da-b12b-4196-8fef-9262829998f3" },
  { year: 2023, resourceId: "d256f989-8f49-46eb-9770-1c6ee9bd2661" },
  { year: 2024, resourceId: "9e920340-0744-4031-a497-98ab796633e8" },
];
export default class PublicHoliday extends React.Component<PublicHolidayProps, PublicHolidayState> {
  constructor(props: PublicHolidayProps) {
    super(props);

    let resource = r?.find((x) => x.year === moment().year());
    let s = "";
    if (resource) {
      s = resource.resourceId;
    } else {
      s = r?.find((x) => x.year === moment().year() - 1).resourceId;
    }
    this.state = {
      resources: r,
      records: [],
      selectedResourceId: s,
    };
  }

  componentDidMount() {
    document.title = "Public Holidays | Welcome to Timesheet approval";
    let currentResource = this.state.resources.find((x) => x.year === moment().year());
    if (!currentResource) {
      currentResource = this.state.resources.find((x) => x.year === moment().year() - 1);
    }
    this.handleYearChange(currentResource.resourceId);
  }

  async handleYearChange(resourceId: string) {
    let publicHolidayResponse = await this.getData(resourceId);
    if (
      publicHolidayResponse &&
      publicHolidayResponse.data.result &&
      publicHolidayResponse.data.result.records &&
      publicHolidayResponse.data.result.records.length > 0
    ) {
      let records = publicHolidayResponse.data.result.records.filter((x: PublicHolidayRecord) => x.Jurisdiction === "act");
      this.setState({ records });
    }
  }

  getData = async (resourceId: string) => {
    let publicHolidayResponse: any = await axios
      .get("https://data.gov.au/data/api/3/action/datastore_search?resource_id=" + resourceId)
      .catch(function (error) {
        if (error.response) {
          console.log(error.response);
        }
      });

    return publicHolidayResponse;
  };

  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Public Holidays | Acader HR</title>
          <meta name="description" content="ACT public holidays" />
        </Helmet>
        <div className="theme-primary">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1>ACT public holidays</h1>
                <div className="h1Divider"></div>
                <p className="mt-3">Please change the selection in the dropdown in order to view public holidays for a different year.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="theme-white">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <label htmlFor="ddlYear">Public holiday for:</label>
                <select
                  id="ddlYear"
                  className="form-control"
                  defaultValue={this.state.selectedResourceId}
                  onChange={async (e) => await this.handleYearChange(e.currentTarget.value)}
                >
                  {this.state.resources.map((r, i) => (
                    <option key={i} value={r.resourceId}>
                      {r.year}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-md-12">
                {this.state.records && this.state.records.length > 0 ? (
                  <table className="table table-striped mt-3">
                    <thead className="thead-dark">
                      <tr className="d-flex">
                        <th className="col-4">Date</th>
                        <th className="col-8">Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.records.map((r, i) => (
                        <tr className="d-flex" key={i}>
                          <td className="col-4">{moment(r.Date).format("DD MMMM YYYY")}</td>
                          <td className="col-8">{r.Information}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
