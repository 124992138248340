import { useState, useEffect, Fragment } from "react";
import { BaseProps } from "../../../model/auth";
import { getApprovedAndNotInvoiced, ITimesheet } from "../../../model/timesheet";
import { Alert, Button, Col, Container, Row, Table } from "reactstrap";
import { MainContent } from "../../../components/mainContent";
import { IUser } from "../../../model/user";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { BreadcrumbControl } from "../../../components/breadcrumb";
import { ToggleMenu } from "../../../components/toggleMenu";

import { TimesheetUserInvoice } from "./timesheetUserInvoice";

export const TimesheetInvoice = (props: BaseProps) => {
  const { id } = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [owners, setOwners] = useState<IUser[]>([]);
  const [selectedTimesheets, setSelectedTimesheets] = useState<ITimesheet[]>([]);

  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    setErrorMessage(null);
    if (props.tokenUser) {
      initialise();
    }
  }, [props.tokenUser]);

  const initialise = async () => {
    setLoading(true);
    let response: any = await getApprovedAndNotInvoiced(id);
    if (response && response.status === 200) {
      let owners: IUser[] = response.data.users;
      setOwners(owners);
      setLoading(false);
    }
  };

  return (
    <>
      <div className="theme theme-primary">
        <Container>
          <Row>
            <Col xs={10}>
              <h1>Create Xero Invoices</h1>
              <div className="h1Divider"></div>
            </Col>
            <Col xs={2} className="text-end">
              <ToggleMenu onClick={(toggle) => props.onMenuToggleClick(toggle)} />
            </Col>
          </Row>
        </Container>
      </div>
      <MainContent loading={loading}>
        {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
        <BreadcrumbControl items={[{ name: "Users", link: "/timesheet/admin/users" }, { name: "Invocing" }]} />
        <a className="btn btn-acader mb-2" href="https://go.xero.com/AccountsReceivable/Search.aspx?invoiceStatus=INVOICESTATUS%2fDRAFT" target="_blank">
          <FontAwesomeIcon icon={faExternalLink} className="me-2" />
          Go to draft invoices
        </a>
        {owners?.length > 0 ? (
          <>
            <Alert color="info">
              <FontAwesomeIcon icon={faInfoCircle} className="me-2" /> Select an approved timesheet.
            </Alert>
            <Table striped responsive>
              <tbody>
                {owners?.map((x, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        <TimesheetUserInvoice
                          key={i}
                          user={x}
                          onCreateClicked={async (loading) => {
                            if (!loading) {
                              await initialise();
                            } else {
                              setLoading(loading);
                            }
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </>
        ) : (
          <Alert color="warning" className=" mt-2">
            No approved timesheet found
          </Alert>
        )}
      </MainContent>
    </>
  );
};
