/* eslint-disable react/jsx-no-target-blank */
import { useEffect, useState } from "react";
import { getUsers, IUser } from "../../../model/user";
import { BaseProps } from "../../../model/auth";
import { MainContent } from "../../../components/mainContent";
import { Col, Container, Row, Table } from "reactstrap";
import { UserActions } from "./ userActions";
import { ToggleMenu } from "../../../components/toggleMenu";

interface InactiveUsersPageProps extends BaseProps {}

export const InactiveUsersPage = (props: InactiveUsersPageProps) => {
  const newUser: IUser = {
    XeroAccountCode: "200",
    UserRoles: [],
  };

  const [loading, setLoading] = useState<boolean>(true);
  const [users, setUsers] = useState<IUser[]>([]);

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    setLoading(true);
    let response: any = await getUsers(props.tokenUser?.Company?.Id, false);
    if (response && response.status === 200) {
      let users: IUser[] = response.data?.users;
      setUsers(users);
    }
    setLoading(false);
  };

  return (
    <>
      <div className="theme-primary">
        <Container>
          <Row>
            <Col xs={10}>
              <h1>Users</h1>
              <div className="h1Divider"></div>
            </Col>
            <Col xs={2} className="text-end">
              <ToggleMenu onClick={(toggle) => props.onMenuToggleClick(toggle)} />
            </Col>
          </Row>
        </Container>
      </div>
      <MainContent loading={loading}>
        <div className="theme-white">
          <Container>
            <Row>
              <Col>
                <Table striped responsive>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {users?.map((x, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            {x.Firstname} {x.Lastname}
                          </td>
                          <td>{x.Email}</td>

                          <td className="text-end">
                            <UserActions {...props} user={x} onEdit={async () => {}} />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Container>
        </div>
      </MainContent>
    </>
  );
};
