import * as React from "react";
import { Col, Container, Input, Label, Row } from "reactstrap";

interface ContractorPayCalculatorProps {}

interface ContractorPayCalculatorState {
  rate: number;
  weeksOff: number;
  feesPercentage: number;
  superPercentage: number;
  fees: number;
  superValue: number;
  total: number;

  hourlyTaxable: number;
  dailyTaxable: number;
  weeklyTaxable: number;
  fortnightlyTaxable: number;
  monthlyTaxable: number;
  yearlyTaxable: number;

  hourlySuper: number;
  dailySuper: number;
  weeklySuper: number;
  fortnightlySuper: number;
  monthlySuper: number;
  yearlySuper: number;

  hourlyFees: number;
  dailyFees: number;
  weeklyFees: number;
  fortnightlyFees: number;
  monthlyFees: number;
  yearlyFees: number;

  hourlyPackage: number;
  dailyPackage: number;
  weeklyPackage: number;
  fortnightlyPackage: number;
  monthlyPackage: number;
  yearlyPackage: number;

  totalWeeks: number;
}

export default class ContractorPayCalculator extends React.Component<ContractorPayCalculatorProps, ContractorPayCalculatorState> {
  constructor(props: ContractorPayCalculatorProps) {
    super(props);
    this.state = {
      rate: null,
      weeksOff: 0,
      feesPercentage: 1.5,
      superPercentage: 11,
      fees: null,
      superValue: null,
      total: null,

      hourlyTaxable: null,
      dailyTaxable: null,
      weeklyTaxable: null,
      fortnightlyTaxable: null,
      monthlyTaxable: null,
      yearlyTaxable: null,

      hourlySuper: null,
      dailySuper: null,
      weeklySuper: null,
      fortnightlySuper: null,
      monthlySuper: null,
      yearlySuper: null,

      hourlyFees: null,
      dailyFees: null,
      weeklyFees: null,
      fortnightlyFees: null,
      monthlyFees: null,
      yearlyFees: null,

      hourlyPackage: null,
      dailyPackage: null,
      weeklyPackage: null,
      fortnightlyPackage: null,
      monthlyPackage: null,
      yearlyPackage: null,
      totalWeeks: 52,
    };
  }

  componentWillMount() {}

  calculate(value: string) {
    let rate: number = parseFloat(value);
    let fees = rate * (this.state.feesPercentage / 100);
    let superValue = rate * (this.state.superPercentage / 100);
    this.setState({ rate, fees, superValue }, () => this.calculateResult());
  }

  weeksOffChange(value: string) {
    let weeksOff: number = parseFloat(value);
    this.setState({ weeksOff }, () => this.calculateResult());
  }

  feesChange(value: string) {
    let feesPercentage: number = parseFloat(value);
    let fees = this.state.rate * (feesPercentage / 100);
    this.setState({ fees, feesPercentage }, () => this.calculateResult());
  }

  superChange(value: string) {
    let superPercentage: number = parseFloat(value);
    let superValue = this.state.rate * (superPercentage / 100);
    this.setState({ superValue, superPercentage }, () => this.calculateResult());
  }

  calculateResult() {
    let total: number = this.state.fees + this.state.superValue;
    let totalWeeksInAYear: number = 52;

    let hourlyTaxable = this.state.rate - total;
    let dailyTaxable = hourlyTaxable * 8;
    let weeklyTaxable = dailyTaxable * 5;
    let fortnightlyTaxable = weeklyTaxable * 2;
    let monthlyTaxable = fortnightlyTaxable * 2;

    let weeksOffValue = this.state.weeksOff * 40 * this.state.rate + this.state.weeksOff * 40 * this.state.fees;
    let yearlyTaxable = monthlyTaxable * 12 - weeksOffValue;

    let hourlySuper = this.state.superValue;
    let dailySuper = hourlySuper * 8;
    let weeklySuper = dailySuper * 5;
    let fortnightlySuper = weeklySuper * 2;
    let monthlySuper = fortnightlySuper * 2;
    let weeksOffSuperValue = this.state.weeksOff * 40 * this.state.superValue;
    let yearlySuper = monthlySuper * 12 - weeksOffSuperValue;

    let hourlyFees = this.state.fees;
    let dailyFees = hourlyFees * 8;
    let weeklyFees = dailyFees * 5;
    let fortnightlyFees = weeklyFees * 2;
    let monthlyFees = fortnightlyFees * 2;
    let weeksOffFeesValue = this.state.weeksOff * 40 * this.state.fees;
    let yearlyFees = monthlyFees * 12 - weeksOffFeesValue;

    let hourlyPackage = hourlyTaxable + hourlySuper - hourlyFees;
    let dailyPackage = dailyTaxable + dailySuper - dailyFees;
    let weeklyPackage = weeklyTaxable + weeklySuper - weeklyFees;
    let fortnightlyPackage = fortnightlyTaxable + fortnightlySuper - fortnightlySuper;
    let monthlyPackage = monthlyTaxable + monthlySuper - monthlyFees;
    let yearlyPackage = yearlyTaxable + yearlySuper - yearlyFees;

    let totalWeeks = totalWeeksInAYear - this.state.weeksOff;

    this.setState({
      total,
      hourlyTaxable,
      dailyTaxable,
      weeklyTaxable,
      fortnightlyTaxable,
      monthlyTaxable,
      yearlyTaxable,
      totalWeeks,

      hourlySuper,
      dailySuper,
      weeklySuper,
      fortnightlySuper,
      monthlySuper,
      yearlySuper,

      hourlyFees,
      dailyFees,
      weeklyFees,
      fortnightlyFees,
      monthlyFees,
      yearlyFees,

      hourlyPackage,
      dailyPackage,
      weeklyPackage,
      fortnightlyPackage,
      monthlyPackage,
      yearlyPackage,
    });
  }

  render() {
    return (
      <>
        <div className="theme-primary">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1>Contractor pay calculator</h1>
                <div className="h1Divider"></div>
                <p className="mt-3">Calculate your estimated pay.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="theme-grey">
          <div className="container">
            <div className="row">
              <div className="col-md-1 col-2 text-md-center">
                <i className="fas fa-lightbulb fa-2x"></i>
              </div>
              <div className="col-md-11 col-10">
                <p>Tips to use the tool:</p>
                <ul>
                  <li>Start by entering your hourly rate, for example: 100</li>
                  <li>You can change your payroll and super fees for calculation purposes</li>
                  <li>If you are taking holidays, add them to the week off input.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="theme-white">
          <Container>
            <Row>
              <Col md={6}>
                <h2>Hours</h2>
                <hr />
                <div className="form-group row">
                  <Label htmlFor="txtRate" className="col-sm-4">
                    My rate is
                  </Label>
                  <div className="col-sm-8">
                    <Input
                      id="txtRate"
                      type="number"
                      placeholder="Enter your hourly rate..."
                      value={this.state.rate}
                      onChange={(e) => this.calculate(e.currentTarget.value)}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <Label htmlFor="txtHolidays" className="col-sm-4">
                    Working weeks off
                  </Label>
                  <div className="col-sm-8">
                    <Input type="number" id="txtHolidays" value={this.state.weeksOff} onChange={(e) => this.weeksOffChange(e.currentTarget.value)} />
                  </div>
                </div>
              </Col>
              <div className="col-md-6">
                <h2>Expenses</h2>
                <hr />
                <div className="form-group row">
                  <Label htmlFor="txtPayroll" className="col-sm-3">
                    Payroll (%)
                  </Label>
                  <div className="col-sm-5">
                    <Input type="number" id="txtPayroll" value={this.state.feesPercentage} onChange={(e) => this.feesChange(e.currentTarget.value)} />
                  </div>
                  <div className="col-sm-1">=</div>
                  <div className="col-sm-3">
                    <Input type="number" className="form-control" id="txtSuper" value={this.state.fees} readOnly />
                  </div>
                </div>
                <div className="form-group row">
                  <Label htmlFor="txtSuper" className="col-sm-3 col-form-label">
                    Super (%)
                  </Label>
                  <div className="col-sm-5">
                    <Input type="number" id="txtSuper" value={this.state.superPercentage} onChange={(e) => this.superChange(e.currentTarget.value)} />
                  </div>
                  <div className="col-sm-1">=</div>
                  <div className="col-sm-3">
                    <Input type="number" className="form-control" id="txtSuper" value={this.state.superValue} readOnly />
                  </div>
                </div>
                <hr />
                <div className="form-group row">
                  <Label htmlFor="txtSuper" className="col-sm-3">
                    Total
                  </Label>
                  <div className="col-sm-5">
                    <input type="number" className="form-control" id="txtSuper" value={this.state.total} readOnly />
                  </div>
                </div>
                <hr />
              </div>
            </Row>
            <div className="row">
              <div className="col-md-12">
                <h3>All amounts in dollars ($)</h3>
                <div className="overflow-auto">
                  <table className="table table-striped">
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col"></th>
                        <th scope="col">Taxable</th>
                        <th scope="col">Super</th>
                        <th scope="col">Fees</th>
                        <th scope="col">Package</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th className="bg-info text-light">Hourly</th>
                        <td>{this.state.hourlyTaxable && this.state.hourlyTaxable.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</td>
                        <td>{this.state.hourlySuper && this.state.hourlySuper.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</td>
                        <td>{this.state.hourlyFees && this.state.hourlyFees.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</td>
                        <td>{this.state.hourlyPackage && this.state.hourlyPackage.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</td>
                      </tr>
                      <tr>
                        <th className="bg-info text-light">Daily</th>
                        <td>{this.state.dailyTaxable && this.state.dailyTaxable.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</td>
                        <td>{this.state.dailySuper && this.state.dailySuper.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</td>
                        <td>{this.state.dailyFees && this.state.dailyFees.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</td>
                        <td>{this.state.dailyPackage && this.state.dailyPackage.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</td>
                      </tr>
                      <tr>
                        <th className="bg-info text-light">Weekly</th>
                        <td>{this.state.weeklyTaxable && this.state.weeklyTaxable.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</td>
                        <td>{this.state.weeklySuper && this.state.weeklySuper.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</td>
                        <td>{this.state.weeklyFees && this.state.weeklyFees.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</td>
                        <td>{this.state.weeklyPackage && this.state.weeklyPackage.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</td>
                      </tr>
                      <tr>
                        <th className="bg-info text-light">Fortnightly</th>
                        <td>
                          {this.state.fortnightlyTaxable && this.state.fortnightlyTaxable.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}
                        </td>
                        <td>{this.state.fortnightlySuper && this.state.fortnightlySuper.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</td>
                        <td>{this.state.fortnightlyFees && this.state.fortnightlyFees.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</td>
                        <td>
                          {this.state.fortnightlyPackage && this.state.fortnightlyPackage.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}
                        </td>
                      </tr>
                      <tr>
                        <th className="bg-info text-light">Monthly</th>
                        <td>{this.state.monthlyTaxable && this.state.monthlyTaxable.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</td>
                        <td>{this.state.monthlySuper && this.state.monthlySuper.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</td>
                        <td>{this.state.monthlyFees && this.state.monthlyFees.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</td>
                        <td>{this.state.monthlyPackage && this.state.monthlyPackage.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</td>
                      </tr>
                      <tr>
                        <th className="bg-info text-light">Yearly ({this.state.totalWeeks} weeks)</th>
                        <td>{this.state.yearlyTaxable && this.state.yearlyTaxable.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</td>
                        <td>{this.state.yearlySuper && this.state.yearlySuper.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</td>
                        <td>{this.state.yearlyFees && this.state.yearlyFees.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</td>
                        <td>{this.state.yearlyPackage && this.state.yearlyPackage.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </>
    );
  }
}
