import React, { useEffect, useState } from "react";
import { ICompliantTimesheet } from "../../model/compliantTimesheet";
import * as compliantTimesheetController from "../../model/compliantTimesheet";
import { WeeklyTimesheetControl } from "../secure/timesheet/weeklyTimesheetControl";
import { useParams } from "react-router-dom";
import { OrbitProgress } from "react-loading-indicators";

export const WESign = () => {
  const { id } = useParams();
  document.title = "e-Sign | Acader";
  const [valid, setValid] = useState<boolean>(false);
  const [errorMessage, setErrorMesssage] = useState<string>("");
  const [timesheet, setTimesheet] = useState<ICompliantTimesheet>(null);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    initialise();
  }, []);

  const initialise = async () => {
    setLoading(true);
    let response: any = await compliantTimesheetController.validateToken(id);
    if (response && response.status === 200) {
      let t: ICompliantTimesheet = response.data.timesheet;
      if (t) {
        setTimesheet(t);
        setValid(true);
        setFirstName(t?.Owner?.Firstname);
        setLastName(t?.Owner?.Lastname);
      } else {
        setErrorMesssage("Timesheet not found");
      }
    } else {
      setErrorMesssage("Timesheet not found");
    }
    setLoading(false);
  };

  if (loading) {
    return (
      <div className="spinner-center">
        <OrbitProgress variant="track-disc" color={"#319dcc"} speedPlus={0} easing="linear" />
      </div>
    );
  }

  if (errorMessage !== "") {
    return <div className="alert alert-danger">{errorMessage}</div>;
  }

  return (
    <WeeklyTimesheetControl tokenUser={null} mode="manager" timesheet={timesheet} firstName={firstName} lastName={lastName} onMenuToggleClick={() => {}} />
  );
};
