import { string } from "yup";

export interface ISelectOption {
  label: string;
  value: string;
}

export interface IComment {
  userName: string;
  comment: string;
  dated: Date;
}

export const currencyBlock = {
  num: {
    mask: Number,
    thousandsSeparator: ",",
    scale: 2,
    radix: ".",
    padFractionalZeros: true,
    normalizeZeros: false,
  },
};

export const currencyBlockNoScale = {
  num: {
    mask: Number,
    thousandsSeparator: ",",
    scale: 2,
    radix: ".",
    padFractionalZeros: false,
    normalizeZeros: false,
  },
};

export const percentBlock = {
  num: {
    expose: true,
    mask: Number,
    scale: 2,
    radix: ".",
    padFractionalZeros: true,
    normalizeZeros: false,
  },
};

export const commentSchema = string().trim().label("Comment").required();
