import { Button, Collapse, Navbar } from "reactstrap";
import { logout } from "../model/login";

interface PublicNavbarProps {
  isAuthenticated: boolean;
}

export const PublicNavbar = (props: PublicNavbarProps) => {
  const iamActive = (href: string) => {
    let url = window.location.href;
    return url.indexOf(href) !== -1;
  };

  return (
    <>
      <Navbar className="navbar-expand-lg navbar-dark bg-dark">
        <a className="navbar-brand" href="/">
          <img src="/images/acader-logo.png" width="100" height="30" alt="Acader Pty Ltd" />
        </a>
        <Button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarTogglerDemo01"
          aria-controls="navbarTogglerDemo01"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </Button>
        <Collapse className="collapse navbar-collapse" id="navbarTogglerDemo01">
          <div className="navbar-nav me-auto mt-2 mt-lg-0">
            <ul className="navbar-nav me-auto mt-2 mt-lg-0">
              <li className={`nav-item ${iamActive("/#services") ? "active" : ""}`}>
                <a className="nav-link" href="/#services" id="nav-services">
                  Services
                </a>
              </li>
              <li className={`nav-item ${iamActive("/#clients") ? "active" : ""}`}>
                <a className="nav-link" href="/#clients" id="nav-clients">
                  Clients
                </a>
              </li>
              <li className={`nav-item ${iamActive("/support") ? "active" : ""}`}>
                <a className="nav-link" href="/support" id="nav-support">
                  Support
                </a>
              </li>
              <li className={`nav-item ${iamActive("/#aboutus") ? "active" : ""}`}>
                <a className="nav-link" href="/#aboutus" id="nav-about">
                  About us
                </a>
              </li>
              <li className={`nav-item ${iamActive("/#contactus") ? "active" : ""}`}>
                <a className="nav-link" href="/#contactus" id="nav-contact">
                  Contact us
                </a>
              </li>
              {props.isAuthenticated && (
                <li className={`nav-item}`}>
                  <a className="nav-link" href="/timesheet" id="nav-timesheet">
                    Timesheet
                  </a>
                </li>
              )}
            </ul>
          </div>
          {props.isAuthenticated ? (
            <>
              <Button className="btn-light my-2 my-sm-0" onClick={() => logout()} id="p-logout">
                <i className="fas fa-sign-out-alt me-1"></i>
                Logout
              </Button>
            </>
          ) : (
            <Button className="btn-light my-2 my-sm-0" onClick={() => (window.location.href = "/login")} id="p-login">
              <i className="fas fa-sign-in-alt me-1"></i>
              Login
            </Button>
          )}
        </Collapse>
      </Navbar>
    </>
  );
};
