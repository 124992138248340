/* eslint-disable react/jsx-no-target-blank */
import { useCallback, useEffect, useState } from "react";
import { ICompany, companySchema, create, list, update } from "../../../model/company";
import { useDropzone } from "react-dropzone";
import { MainContent } from "../../../components/mainContent";
import { Button, Col, Container, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from "reactstrap";
import { CompanyActions } from "./companyActions";
import Select from "react-select";
import { ISelectOption } from "../../../model/common";
import { ToggleMenu } from "../../../components/toggleMenu";
import { BaseProps } from "../../../model/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";

export const CompanyiesPage = (props: BaseProps) => {
  const newCompany: ICompany = { Subscription: "Free", CountryId: 1, Active: true };
  const [error, setError] = useState<string>(null);

  const [loading, setLoading] = useState<boolean>(true);
  const [companies, setCompanies] = useState<ICompany[]>([]);
  const [logo, setLogo] = useState<File>();
  const [company, setCompany] = useState<ICompany>(newCompany);
  const [companyErrors, setCompanyErrors] = useState<ICompany>({});
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedSubcription, setSelectedSubcription] = useState<ISelectOption>();

  const subcriptionOptions: ISelectOption[] = [
    { value: "Free", label: "Free" },
    { value: "Basic", label: "Basic" },
    { value: "Exclusive", label: "Exclusive" },
  ];

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop: (files) => setLogo(files?.at(0)),
    accept: {
      "image/png": [".png"],
    },
    multiple: false,
  });

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    let response: any = await list();
    if (response && response.status === 200) {
      setCompanies(response.data.companies);
    }
    setLoading(false);
  };

  const addcompany = async () => {
    try {
      await companySchema.validateSync(company, {
        abortEarly: false,
      });
      if (error) {
        return;
      }
      setLoading(true);
      let c: ICompany = company;
      let response: any;
      if (!c.Id) {
        response = await create(c);
      } else {
        response = await update(c);
      }
      if (response && response.status === 200) {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        setError("Error in adding company");
      }
      setLoading(false);
      setShowModal(false);
    } catch (yupErrors) {
      let errors: ICompany = {};
      yupErrors?.inner?.map((x: any) => {
        errors[x.path] = x.message;
      });
      setCompanyErrors(errors);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="theme-primary">
        <Container>
          <Row>
            <Col xs={10}>
              <h1>My Companies</h1>
              <div className="h1Divider"></div>
            </Col>
            <Col xs={2} className="text-end">
              <ToggleMenu onClick={(toggle) => props.onMenuToggleClick(toggle)} />
            </Col>
          </Row>
        </Container>
      </div>
      <MainContent loading={loading}>
        <Modal size="lg" fade id="userModal" isOpen={showModal} toggle={() => setShowModal((x) => (x = !x))}>
          <ModalHeader>
            <h5 className="modal-title">{!company?.Id ? "Add a new company" : "Update company"}</h5>
          </ModalHeader>
          <ModalBody>
            {error && <div className="alert alert-danger">{error}</div>}
            <Row>
              <Col>
                <FormGroup>
                  <Label className="required">Company name</Label>
                  <Input
                    id="txtName"
                    type="text"
                    value={company.Name || ""}
                    onChange={(e) => {
                      e.persist();
                      setCompany((x) => ({ ...x, Name: e.target.value }));
                    }}
                  />
                  {companyErrors.Name && <Label className="text-danger">{companyErrors.Name}</Label>}
                </FormGroup>
                <FormGroup>
                  <Label className="required">Email</Label>
                  <Input
                    id="txtEmail"
                    type="text"
                    value={company.Email || ""}
                    onChange={(e) => {
                      e.persist();
                      setCompany((x) => ({ ...x, Email: e.target.value }));
                    }}
                  />
                  {companyErrors.Email && <Label className="text-danger">{companyErrors.Email}</Label>}
                </FormGroup>
                <FormGroup>
                  <Label>Address line 1</Label>
                  <Input
                    id="txtAddressLine1"
                    type="text"
                    value={company.AddressLine1 || ""}
                    onChange={(e) => {
                      e.persist();
                      setCompany((x) => ({ ...x, AddressLine1: e.target.value }));
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>City</Label>
                  <Input
                    id="txtCity"
                    type="text"
                    value={company.City || ""}
                    onChange={(e) => {
                      e.persist();
                      setCompany((x) => ({ ...x, City: e.target.value }));
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Post code</Label>
                  <Input
                    id="txtPostCode"
                    placeholder="Post code"
                    type="text"
                    value={company.PostCode || ""}
                    onChange={(e) => {
                      e.persist();
                      setCompany((x) => ({ ...x, PostCode: e.target.value }));
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Logo Url</Label>
                  <Input
                    id="txtLogo"
                    type="text"
                    readOnly={company?.Subscription === "Free"}
                    value={company.LOGO || ""}
                    onChange={(e) => {
                      e.persist();
                      let logo = e.target.value;
                      setCompany((x) => ({ ...x, LOGO: logo }));
                    }}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label className="required">ABN</Label>
                  <Input
                    id="txtAbn"
                    type="number"
                    value={company?.ABN || ""}
                    onChange={(e) => {
                      e.persist();
                      setCompany((x) => ({ ...x, ABN: e.target.value }));
                    }}
                  />
                  {companyErrors.ABN && <Label className="text-danger">{companyErrors.ABN}</Label>}
                </FormGroup>
                <FormGroup>
                  <Label className="required">Phone</Label>
                  <Input
                    id="txtPhone"
                    type="number"
                    value={company?.Phone || ""}
                    onChange={(e) => {
                      e.persist();
                      setCompany((x) => ({ ...x, Phone: e.target.value }));
                    }}
                  />
                  {companyErrors.Phone && <Label className="text-danger">{companyErrors.Phone}</Label>}
                </FormGroup>
                <FormGroup>
                  <Label>Address line 2</Label>
                  <Input
                    id="txtAddressLine2"
                    placeholder="Address line 2"
                    type="text"
                    value={company.AddressLine2 || ""}
                    onChange={(e) => {
                      e.persist();
                      setCompany((x) => ({ ...x, AddressLine2: e.target.value }));
                    }}
                  />
                </FormGroup>

                <FormGroup>
                  <Label>State</Label>
                  <Input
                    id="txtState"
                    type="text"
                    value={company.State || ""}
                    onChange={(e) => {
                      e.persist();
                      setCompany((x) => ({ ...x, State: e.target.value }));
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label className="required">Country</Label>
                  <select
                    className="form-control"
                    defaultValue={company?.CountryId?.toString()}
                    onChange={(e) => {
                      e.persist();
                      setCompany((x) => ({ ...x, CountryId: parseInt(e.target.value) }));
                    }}
                  >
                    <option value={1}>Australia</option>
                  </select>
                </FormGroup>
                <FormGroup>
                  <Label className="required">Website</Label>
                  <Input
                    id="txtWebsite"
                    type="text"
                    value={company.Website || ""}
                    onChange={(e) => {
                      e.persist();
                      setCompany((x) => ({ ...x, Website: e.target.value }));
                    }}
                  />
                  {companyErrors.Website && <Label className="text-danger">{companyErrors.Website}</Label>}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label className="required">Subscription</Label>
                  <Select
                    placeholder="Please select a subscription..."
                    value={selectedSubcription}
                    name="colors"
                    options={subcriptionOptions}
                    onChange={(x) => {
                      setSelectedSubcription(x);
                      let logo = "";
                      if (x.value === "Free") {
                        logo = "https://acader.com.au/images/acader_logo.png";
                      }
                      setCompany((y) => ({ ...y, Subscription: x.value as any, LOGO: logo }));
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="dark" onClick={() => setShowModal(false)}>
              Close
            </Button>
            <Button color="acader" onClick={async () => await addcompany()}>
              <FontAwesomeIcon icon={faSave} className="me-2"></FontAwesomeIcon>
              {!company?.Id ? "Create a new company" : "Update company"}
            </Button>
          </ModalFooter>
        </Modal>

        <div className="theme-white">
          <Container>
            <Row>
              <Col>
                <Button
                  type="button"
                  onClick={() => {
                    setCompanyErrors({});
                    setError(null);
                    setShowModal(true);
                    setCompany(newCompany);
                    setSelectedSubcription({ value: "Free", label: "Free" });
                  }}
                  color="acader"
                  className="mb-2"
                >
                  <i className="fas fa-building me-2"></i>
                  Add a new company
                </Button>
                <Table responsive striped>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>ABN</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Active</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {companies?.map((x, i) => {
                      return (
                        <tr key={i}>
                          <td>{x.Name}</td>
                          <td>{x.ABN}</td>
                          <td>{x.Email}</td>
                          <td>{x.Phone}</td>
                          <td>{x.Active ? "Yes" : "No"}</td>
                          <td>
                            <CompanyActions
                              company={x}
                              onLoading={(loading) => setLoading(loading)}
                              onEdit={() => {
                                setCompanyErrors({});
                                setCompany(x);
                                setSelectedSubcription({ value: x.Subscription?.toString(), label: x.Subscription?.toString() });
                                setError(null);
                                setShowModal(true);
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Container>
        </div>
      </MainContent>
    </>
  );
};
