import { array, number, object, string } from "yup";
import { axiosAuth } from "./auth";
import { ISelectOption } from "./common";
import { ICompany } from "./company";
import { ITimesheet } from "./timesheet";
import { ICompliantTimesheet } from "./compliantTimesheet";

export type modeOptions = "Monthly" | "Weekly" | "Fornightly";
export type IRole = "Employee" | "PayrollAdmin" | "SuperAdmin";

export const getRoles = (roles: IRole[]) => {
  let options: ISelectOption[] = [];

  options.push({ label: "Employee", value: "Employee" });
  options.push({ label: "PayrollAdmin", value: "PayrollAdmin" });
  if (roles.find((x) => x === "SuperAdmin")) {
    options.push({ label: "SuperAdmin", value: "SuperAdmin" });
  }
  return options;
};

export interface IUserRole {
  Id?: number;
  UserId: number;
  Role: IRole;
}

export const managerSchema = object<IUser>({
  ManagerName: string().trim().label("Manager's name").nullable().required(),
  ManagerEmail: string().email().label("Manager's email").nullable().required(),
  Mode: string().nullable().required(),
});

export const passwordSchema = object<IUser>({
  Password: string().label("Password").nullable().required(),
});

export const userSchema = object<IUser>({
  Firstname: string().trim().nullable().required(),
  Lastname: string().trim().nullable().required(),
  Email: string().email().nullable().required(),
  ManagerName: string().trim().label("Manager's name").nullable().required(),
  ManagerEmail: string().trim().email().label("Manager's email").nullable().required(),
  Mode: string().nullable().required(),
  UserRoles: array().min(1, "Please add atleast one role").label("User role").required(),
  ManagerCompanyId: string().when(["Company", "UserRoles"], {
    is: (Company: ICompany, UserRoles: IUserRole[]) => Company?.Subscription === "Exclusive" && UserRoles?.find((x) => x.Role === "Employee"),
    then: (schema) => schema.label("Xero company").required(),
    otherwise: (schema) => schema.nullable().optional(),
  }),
  InvoiceNumberPrefix: string()
    .trim()
    .when(["Company", "UserRoles"], {
      is: (Company: ICompany, UserRoles: IUserRole[]) => Company?.Subscription === "Exclusive" && UserRoles?.find((x) => x.Role === "Employee"),
      then: (schema) => schema.label("Xero invoice reference number").required(),
      otherwise: (schema) => schema.nullable().optional(),
    }),
  ContractNumber: string()
    .trim()
    .when(["Company", "UserRoles"], {
      is: (Company: ICompany, UserRoles: IUserRole[]) => Company?.Subscription === "Exclusive" && UserRoles?.find((x) => x.Role === "Employee"),
      then: (schema) => schema.label("Xero contract number").required(),
      otherwise: (schema) => schema.nullable().optional(),
    }),
  XeroAccountCode: string()
    .trim()
    .when(["Company", "UserRoles"], {
      is: (Company: ICompany, UserRoles: IUserRole[]) => Company?.Subscription === "Exclusive" && UserRoles?.find((x) => x.Role === "Employee"),
      then: (schema) => schema.label("Xero account code").required(),
      otherwise: (schema) => schema.nullable().optional(),
    }),
  DaysFromInvoice: number().when(["Company", "UserRoles"], {
    is: (Company: ICompany, UserRoles: IUserRole[]) => Company?.Subscription === "Exclusive" && UserRoles?.find((x) => x.Role === "Employee"),
    then: (schema) => schema.label("Xero due days from invoice").required(),
    otherwise: (schema) => schema.nullable().optional(),
  }),
  PayrollType: string()
    .trim()
    .when(["Company", "UserRoles"], {
      is: (Company: ICompany, UserRoles: IUserRole[]) => Company?.Subscription === "Exclusive" && UserRoles?.find((x) => x.Role === "Employee"),
      then: (schema) => schema.label("Payroll type").required(),
      otherwise: (schema) => schema.nullable().optional(),
    }),
  TotalRate: number().when(["Company", "UserRoles"], {
    is: (Company: ICompany, UserRoles: IUserRole[]) => Company?.Subscription === "Exclusive" && UserRoles?.find((x) => x.Role === "Employee"),
    then: (schema) => schema.label("Rate to client").required(),
    otherwise: (schema) => schema.nullable().optional(),
  }),
});

export interface IUser {
  Id?: number;
  Firstname?: string;
  Lastname?: string;
  Email?: string;
  Password?: string;
  ManagerName?: string;
  ManagerEmail?: string;
  Active?: boolean;
  CreatedOn?: Date;
  LastLoginOn?: Date;
  Token?: string | null;
  Mode?: "Monthly" | "Weekly" | "Fornightly";
  ManagerCompanyName?: string;
  ManagerCompanyId?: string;
  InvoiceReferenceNumber?: string;
  InvoiceNumberPrefix?: string;
  ContractNumber?: string;
  XeroToken?: string;
  XeroAccountCode?: string;
  PayrollType?: "PayG" | "Company";
  DaysFromInvoice?: number;
  TotalRate?: number;
  PayrollTaxAmount?: number;
  PayrollIncomePercentage?: number;
  RateExclusiveSuper?: number;
  ChargeoutRate?: number;
  ServiceFee?: number;
  PayrollFeeNotApplicable?: boolean;
  XeroEmployeeId?: string;
  CompanyId?: number;
  Company?: ICompany;
  UserRoles?: IUserRole[];
  Timesheets?: ITimesheet[];
  WeeklyTimesheets?: ICompliantTimesheet[];
}

export interface ITokenUser {
  Id?: number;
  Firstname: string;
  Lastname: string;
  ManagerEmail: string;
  ManagerName: string;
  Email: string;
  Mode: modeOptions;
  IsAdmin: boolean;
  Source: "Native" | "Google" | "Xero";
  Roles?: IRole[];
  Company?: ICompany;
  iat?: number;
  exp?: number;
}

export let register = async (user: IUser) => {
  let response = await axiosAuth.post(process.env.REACT_APP_API_URL + "/user/add", { data: user }).catch(function (error) {
    if (error.response) {
      console.log(error);
    }
  });
  return response;
};

export let userExists = async (email: string) => {
  let response: any = await axiosAuth
    .get(process.env.REACT_APP_API_URL + "/user/exists", {
      params: { email: email },
    })
    .catch(function (error) {
      if (error.response) {
        console.log(error.response);
      }
    });

  return response;
};

export let forgotPassword = async (emailTo: string) => {
  let response = await axiosAuth.post(process.env.REACT_APP_API_URL + "/forgot-password", { emailTo }).catch(function (error) {
    if (error.response) {
      console.log(error);
    }
  });
  return response;
};

export let resetPassword = async (username: string, password: string) => {
  let response = await axiosAuth
    .post(process.env.REACT_APP_API_URL + "/reset-password", { data: { username: username, password: password } })
    .catch(function (error) {
      if (error.response) {
        console.log(error);
      }
    });
  return response;
};

export let updateManager = async (id: number, managerName: string, managerEmail: string, mode: modeOptions) => {
  let response = await axiosAuth
    .post(process.env.REACT_APP_API_URL + "/user/update-manager", {
      id: id,
      managerEmail: managerEmail,
      managerName: managerName,
      mode: mode,
    })
    .catch(function (error) {
      if (error.response) {
        console.log(error);
      }
    });
  return response;
};

export let update = async (user: IUser) => {
  let response = await axiosAuth
    .post(process.env.REACT_APP_API_URL + "/user/update", {
      user,
    })
    .catch(function (error) {
      if (error.response) {
        console.log(error);
      }
    });
  return response;
};

export let getUser = async (id: string) => {
  let response: any = await axiosAuth
    .get(process.env.REACT_APP_API_URL + "/user/get", {
      params: { id: id },
    })
    .catch(function (error) {
      if (error.response) {
        console.log(error.response);
      }
    });

  return response;
};

export let getUsers = async (companyId: number, active: boolean) => {
  let response: any = await axiosAuth.get(process.env.REACT_APP_API_URL + "/user/adminusers", { params: { companyId, active } }).catch(function (error) {
    if (error.response) {
      console.log(error.response);
    }
  });

  return response;
};

export let changeStatus = async (id: number, active: boolean) => {
  let response: any = await axiosAuth.post(process.env.REACT_APP_API_URL + "/user/change-status", { active, id });
  return response;
};
