import axios from "axios";
import { ICountry } from "./country";
import { axiosAuth } from "./auth";
import { object, string } from "yup";

export const companySchema = object<ICompany>({
  Name: string().trim().label("Company name").required(),
  ABN: string().trim().label("ABN").required(),
  Email: string().trim().email().label("Email").required(),
  Phone: string().trim().label("Phone").required(),
  Website: string().trim().label("Phone").required(),
});

export interface ICompany {
  Id?: number;
  Name?: string;
  ABN?: string;
  Email?: string;
  Phone?: string;
  Website?: string;
  XeroClientId?: string;
  XeroClientSecret?: string;
  XeroShortCode?: string;
  AddressLine1?: string;
  AddressLine2?: string;
  State?: string;
  City?: string;
  PostCode?: string;
  CountryId?: number;
  Country?: ICountry;
  LOGO?: string;
  Active?: boolean;
  Subscription?: "Free" | "Basic" | "Exclusive";
}

export let create = async (company: ICompany) => {
  let response: any = await axiosAuth.post(process.env.REACT_APP_API_URL + "/company/create", { company });
  return response;
};

export let update = async (company: ICompany) => {
  let response: any = await axiosAuth.post(process.env.REACT_APP_API_URL + "/company/update", { company });
  return response;
};

export let updateXeroCredentials = async (clientId: string, clientSecret: string, id: number) => {
  let response: any = await axiosAuth.post(process.env.REACT_APP_API_URL + "/company/update/xero", { clientId, clientSecret, id });
  return response;
};

export let changeStatus = async (id: number, active: boolean) => {
  let response: any = await axiosAuth.post(process.env.REACT_APP_API_URL + "/company/update/status", { active, id });
  return response;
};

export let get = async (id: number) => {
  let response: any = await axiosAuth
    .get(process.env.REACT_APP_API_URL + "/company/get", {
      params: { id: id },
    })
    .catch(function (error) {
      if (error.response) {
        console.log(error.response);
      }
    });

  return response;
};

export let list = async () => {
  let response: any = await axiosAuth.get(process.env.REACT_APP_API_URL + "/company/list", {}).catch(function (error) {
    if (error.response) {
      console.log(error.response);
    }
  });

  return response;
};
