import { useState } from "react";
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { changeStatus, IUser } from "../../../model/user";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faClock, faCogs, faEdit, faFileInvoice, faLock, faMinus, faSync } from "@fortawesome/free-solid-svg-icons";
import { BaseProps } from "../../../model/auth";
import { syncEmployee } from "../../../model/xero";

export interface UserActionProps extends BaseProps {
  user: IUser;
  onEdit: () => void;
}
export const UserActions = (props: UserActionProps) => {
  const [actionIsOpen, setActionIsOpen] = useState<boolean>(false);

  return (
    <>
      <ButtonDropdown isOpen={actionIsOpen} toggle={() => setActionIsOpen((x) => !x)} className="position-static">
        <DropdownToggle caret color="acader">
          <FontAwesomeIcon icon={faCogs} className="me-2" />
          Actions
        </DropdownToggle>
        <DropdownMenu>
          {props.user?.Active && (
            <>
              {" "}
              <DropdownItem
                onClick={() => {
                  props.onEdit();
                }}
              >
                <FontAwesomeIcon icon={faEdit} className="me-2" />
                Edit
              </DropdownItem>
              <DropdownItem href={`/timesheet/admin/user/${props.user.Id}`}>
                <FontAwesomeIcon icon={faClock} className="me-2" />
                Current timesheet
              </DropdownItem>
            </>
          )}
          <DropdownItem
            onClick={async () => {
              await changeStatus(props.user?.Id, !props?.user?.Active);
              window.location.reload();
            }}
          >
            <FontAwesomeIcon icon={props.user?.Active ? faMinus : faCheck} className="me-2" />
            {props?.user?.Active ? "Deactivate" : "Activate"}
          </DropdownItem>
          {props?.user?.PayrollType === "PayG" && (
            <DropdownItem
              onClick={async () => {
                await syncEmployee(props.user?.Email, props.user?.Id);
              }}
            >
              <FontAwesomeIcon icon={faSync} className="me-2" />
              Sync with xero
            </DropdownItem>
          )}
        </DropdownMenu>
      </ButtonDropdown>
    </>
  );
};
