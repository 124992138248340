import { BaseProps } from "../model/auth";
import { logout } from "../model/login";
import { Logo } from "./logo";
import { Button, Collapse, DropdownItem, DropdownMenu, DropdownToggle, Navbar, UncontrolledDropdown } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faChartColumn,
  faClock,
  faCog,
  faCogs,
  faFileInvoice,
  faLock,
  faMoneyBill,
  faMoneyBills,
  faSignOutAlt,
  faTasks,
  faUmbrellaBeach,
  faUser,
  faUserAlt,
  faUsers,
  faUserTie,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";
import { Sidebar, Menu, MenuItem, SubMenu, MenuItemStyles, menuClasses } from "react-pro-sidebar";
import { icon } from "@fortawesome/fontawesome-svg-core";
import { useEffect, useState } from "react";

interface NavbarProps extends BaseProps {
  openMenu: boolean;
  isAuthenticated: boolean;
}

const hexToRgba = (hex: string, alpha: number) => {
  const r = parseInt(hex?.slice(1, 3), 16);
  const g = parseInt(hex?.slice(3, 5), 16);
  const b = parseInt(hex?.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const theme: any = {
  dark: {
    sidebar: {
      backgroundColor: "#112f44",
      color: "#fff",
    },
    menu: {
      menuContent: "#082440",
      icon: "#fff",
      hover: {
        backgroundColor: "#00458b",
        color: "#b6c8d9",
      },
      disabled: {
        color: "#3e5e7e",
      },
    },
  },
};

export const SecureNavbar = (props: NavbarProps) => {
  const [toggled, setToggled] = useState<boolean>();
  const [broken, setBroken] = useState<boolean>();
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    if (!loading) {
      setToggled((x) => !x);
    } else setLoading(false);
  }, [props.openMenu]);

  const iamActive = (href: string) => {
    let url = window.location.href;
    return url.indexOf(href) !== -1;
  };

  const menuItemStyles: MenuItemStyles = {
    root: {
      fontSize: "13px",
      fontWeight: 400,
    },
    icon: {
      color: theme.dark.menu?.icon,
      [`&.${menuClasses.disabled}`]: {
        color: theme.dark.menu?.disabled.color,
      },
    },
    SubMenuExpandIcon: {
      color: "#b6b7b9",
    },
    subMenuContent: ({ level }) => ({
      backgroundColor: level === 0 ? hexToRgba(theme.dark.menu?.menuContent, 1) : "transparent",
    }),
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: theme?.menu?.disabled?.color,
      },
      "&:hover": {
        backgroundColor: hexToRgba(theme.dark.menu?.hover.backgroundColor, 1),
        color: theme.dark.menu?.hover.color,
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

  return (
    <>
      <Sidebar
        color="dark"
        toggled={toggled}
        onBackdropClick={() => setToggled(false)}
        onBreakPoint={setBroken}
        breakPoint="md"
        backgroundColor={hexToRgba(theme.dark.sidebar?.backgroundColor, 1)}
        rootStyles={{
          color: theme.dark.sidebar?.color,
        }}
      >
        <Menu menuItemStyles={menuItemStyles}>
          <MenuItem href={props.tokenUser?.Company?.Website} className="mb-4">
            <Logo url={props.tokenUser?.Company?.LOGO} companyName={props.tokenUser?.Company?.Name} />
          </MenuItem>
          {props.tokenUser?.Roles?.find((x) => x === "Employee") && (
            <>
              <div style={{ padding: "0px 24px", marginBottom: "8px" }}>
                <p className="menu-category">General</p>
              </div>

              <MenuItem href="/timesheet" icon={<FontAwesomeIcon icon={faClock} />}>
                Timesheet
              </MenuItem>
              <MenuItem href="/timesheet/charts" icon={<FontAwesomeIcon icon={faChartColumn} />}>
                Charts
              </MenuItem>
            </>
          )}

          <div style={{ padding: "0px 24px", marginBottom: "8px" }} className="pt-4">
            <p className="menu-category">Admin</p>
          </div>

          {props.tokenUser?.Roles?.find((x) => x === "PayrollAdmin") && (
            <>
              <SubMenu defaultOpen label={`Users`} icon={<FontAwesomeIcon icon={faUsers} />}>
                <MenuItem href="/timesheet/admin/users/employee" icon={<FontAwesomeIcon icon={faUsers} />}>
                  Employees
                </MenuItem>
                <MenuItem href="/timesheet/admin/users/payrolladmin" icon={<FontAwesomeIcon icon={faUserAlt} />}>
                  Admins
                </MenuItem>
                <MenuItem href="/timesheet/users/inactive" icon={<FontAwesomeIcon icon={faUserAlt} />}>
                  Inactive users
                </MenuItem>
              </SubMenu>
              <SubMenu defaultOpen label={`Administration`} icon={<FontAwesomeIcon icon={faCogs} />}>
                <MenuItem href="/timesheet/admin/company" icon={<FontAwesomeIcon icon={faBuilding} />}>
                  Company
                </MenuItem>
                {props.tokenUser?.Source === "Xero" && (
                  <>
                    <MenuItem href="/timesheet/admin/invoices" icon={<FontAwesomeIcon icon={faFileInvoice} />}>
                      Pending invoices
                    </MenuItem>
                    <MenuItem href="/timesheet/admin/pay-runs" icon={<FontAwesomeIcon icon={faMoneyBills} />}>
                      Pay runs
                    </MenuItem>
                  </>
                )}
              </SubMenu>
            </>
          )}

          <SubMenu defaultOpen label={`${props?.tokenUser?.Firstname}'s settings`} icon={<FontAwesomeIcon icon={faUser} />}>
            <MenuItem href="/timesheet/change-settings" icon={<FontAwesomeIcon icon={faCog} />}>
              Change settings
            </MenuItem>
            <MenuItem href="/timesheet/change-password" icon={<FontAwesomeIcon icon={faLock} />}>
              Change Password
            </MenuItem>
            <MenuItem onClick={async () => await logout()} icon={<FontAwesomeIcon icon={faSignOutAlt} />}>
              Logout
            </MenuItem>
          </SubMenu>

          {props.tokenUser?.Roles?.find((x) => x === "SuperAdmin") && (
            <SubMenu label={`System`} icon={<FontAwesomeIcon icon={faLock} />}>
              <MenuItem href="/timesheet/admin/companies" icon={<FontAwesomeIcon icon={faUserTie} />}>
                Companies
              </MenuItem>
              <MenuItem href="/timesheet/admin/variables" icon={<FontAwesomeIcon icon={faTasks} />}>
                Variables
              </MenuItem>
            </SubMenu>
          )}

          <SubMenu label={"Tools"} icon={<FontAwesomeIcon icon={faWrench} />}>
            <MenuItem href="/holidays" target="_blank" icon={<FontAwesomeIcon icon={faUmbrellaBeach} />}>
              ACT Public Holidays
            </MenuItem>
            <MenuItem href="/pay-calculator" target="_blank" icon={<FontAwesomeIcon icon={faMoneyBill} />}>
              Contractor pay calculator
            </MenuItem>
            <MenuItem href="/tax-calculator" target="_blank" icon={<FontAwesomeIcon icon={faFileInvoice} />}>
              Tax calculator
            </MenuItem>
          </SubMenu>
        </Menu>
      </Sidebar>
    </>
  );
};
