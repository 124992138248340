import { ReactNode, useEffect, useState } from "react";
import { OrbitProgress, ThreeDot } from "react-loading-indicators";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

interface MainContentProps {
  children: ReactNode;
  loading?: boolean;
  width?: number;
  saved?: boolean;
  className?: string;
}
export const MainContent = (props: MainContentProps) => {
  if (props.saved) {
    return (
      <div className="spinner-center">
        <ThreeDot variant="pulsate" color="#319dcc" size="medium" text="Saving, please wait..." textColor="" />
      </div>
    );
  }

  if (props.loading) {
    return (
      <div className="spinner-center">
        <OrbitProgress variant="track-disc" color={"#319dcc"} speedPlus={0} easing="linear" />
      </div>
    );
  }
  return (
    <section style={{ backgroundColor: "#DCEEF9" }} className={props.className ? props.className : `main-content-secure`}>
      <Container className="py-2 h-100">
        <Row className="d-flex justify-content-center align-items-center h-100">
          <Col className={`col-sm-12 col-md-${props.width ? props.width : 12}`}>
            <Card style={{ borderRadius: "1rem" }}>
              <Row className="g-0">
                <Col className="">
                  <CardBody className="p-4 text-black">{props.children}</CardBody>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
