import { useState } from "react";
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { changeStatus, ICompany } from "../../../model/company";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCogs, faEdit, faMinus } from "@fortawesome/free-solid-svg-icons";

export interface CompanyActionProps {
  company: ICompany;
  onLoading: (loading: boolean) => void;
  onEdit: () => void;
}
export const CompanyActions = (props: CompanyActionProps) => {
  const [actionIsOpen, setActionIsOpen] = useState<boolean>(false);
  const [company, setCompany] = useState<ICompany>(props.company);

  return (
    <>
      <ButtonDropdown isOpen={actionIsOpen} toggle={() => setActionIsOpen((x) => !x)} className="position-static">
        <DropdownToggle caret color="acader">
          <FontAwesomeIcon icon={faCogs} className="me-2" />
          Actions
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            onClick={() => {
              props.onEdit();
            }}
          >
            <FontAwesomeIcon icon={faEdit} className="me-2" />
            Edit
          </DropdownItem>
          <DropdownItem
            onClick={async () => {
              await changeStatus(company.Id, !company.Active);
              window.location.reload();
            }}
          >
            <FontAwesomeIcon icon={company.Active ? faMinus : faCheck} className="me-2" />
            {company?.Active ? "Deactivate" : "Activate"}
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    </>
  );
};
