import { useEffect, useState } from "react";
import { TimesheetControl } from "./timesheet/timesheetControl";
import { IUser } from "../../model/user";
import * as userController from "../../model/user";
import { WeeklyTimesheetControl } from "./timesheet/weeklyTimesheetControl";
import { OrbitProgress } from "react-loading-indicators";
import { useParams } from "react-router-dom";
import { BaseProps } from "../../model/auth";

interface AdminTimesheetProps extends BaseProps {}

export const AdminTimesheet = (props: AdminTimesheetProps) => {
  var { id } = useParams();
  const [user, setUser] = useState<IUser>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    initialise();
  }, []);

  const initialise = async () => {
    let response: any = await userController.getUser(id);
    if (response && response.status === 200) {
      let user: IUser = response.data.user;
      setUser(user);
      setLoading(false);
      if (!user || !user.ManagerEmail || user.ManagerEmail === "") {
        window.location.href = "/dashboard";
      }
    }
  };
  if (loading) {
    return (
      <div className="spinner-center">
        <OrbitProgress variant="track-disc" color={"#319dcc"} speedPlus={0} easing="linear" />
      </div>
    );
  }
  return (
    <>
      {user?.Mode === "Monthly" ? (
        <TimesheetControl tokenUser={props.tokenUser} mode="employee" userId={id as any} onMenuToggleClick={() => {}} />
      ) : (
        <WeeklyTimesheetControl mode="employee" userId={id as any} tokenUser={props.tokenUser} onMenuToggleClick={() => {}} />
      )}
    </>
  );
};
