import { useState, useEffect } from "react";
import moment, { Moment, Duration } from "moment";
import TimePicker from "rc-time-picker";
import { IUser } from "../../../model/user";
import { ICompliantTimesheetData } from "../../../model/compliantTimesheet";
import * as compliantTimesheetController from "../../../model/compliantTimesheet";
import { Badge } from "reactstrap";

interface TimeMobileEntryControlProps {
  mode: compliantTimesheetController.modeOption;
  day: Moment;
  data?: ICompliantTimesheetData;
  user?: IUser;
  status: string;
  OnChange?: (start: string, end: string, breakDuration: string, day: Moment, dayTotal: string) => void;
}

export const TimeMobileEntryControl = (props: TimeMobileEntryControlProps) => {
  const [day, setDay] = useState<Moment>(props.day || null);
  const [data, setData] = useState<ICompliantTimesheetData>(props.data || null);
  const [start, setStart] = useState<Moment>(null);
  const [end, setEnd] = useState<Moment>(null);
  const [breakDuration, setBreakDuration] = useState<Moment>(null);
  const [user, setUser] = useState<IUser>(props.user || null);
  const [dayTotal, setDayTotal] = useState<string>("");
  const [disabled, setDisabled] = useState<boolean>(false);

  useEffect(() => {
    initialise();
  }, [data, day]);

  useEffect(() => {
    if (props.data !== data || props.day !== day) {
      setData(props.data);
      setDay(props.day);
    }
  }, [props.data, props.day]);

  useEffect(() => {
    handleChange(dayTotal);
  }, [dayTotal]);

  const initialise = () => {
    let disabled: boolean = props.mode !== "employee" || props.status === "Approved" || props.status === "Sent";
    let start: Moment = null;
    let end: Moment = null;
    let breakDuration: Moment = moment({ hours: 0, minutes: 30 });
    let dayTotal: string = "0";
    if (data) {
      start = data.start ? moment(data.start, "hh:mm a") : moment({ hours: 0, minutes: 0 });
      end = data.end ? moment(data.end, "hh:mm a") : moment({ hours: 0, minutes: 0 });
      breakDuration = data.breakDuration ? moment(data.breakDuration, "hh:mm") : moment({ hours: 0, minutes: 0 });
      dayTotal = data.hours;
    }
    setStart(start);
    setEnd(end);
    setBreakDuration(breakDuration);
    setDayTotal(dayTotal);
    setDisabled(disabled);
  };

  const onStartChange = (start: Moment) => {
    let dayTotal: string = "0";
    if (start && end) {
      let breakHour: Duration = moment.duration(breakDuration.hours(), "hours");
      let breakMinute: Duration = moment.duration(breakDuration.minutes(), "minutes");

      let duration = moment.duration(end.diff(start));
      if (breakHour.hours() > 0) {
        duration = duration.subtract(breakHour);
      }
      if (breakMinute.minutes() > 0) {
        duration = duration.subtract(breakMinute);
      }
      dayTotal = duration.hours().toString() + "." + duration.minutes().toString();
    } else if (!start) {
      start = moment({ hours: 0, minutes: 0 });
    }
    setStart(start);
    setDayTotal(dayTotal);
  };

  const onEndChange = (end: Moment) => {
    let dayTotal: string = "0";
    if (end && start) {
      let breakHour: Duration = moment.duration(breakDuration.hours(), "hours");
      let breakMinute: Duration = moment.duration(breakDuration.minutes(), "minutes");
      let duration = moment.duration(end.diff(start));
      if (breakHour.hours() > 0) {
        duration = duration.subtract(breakHour);
      }
      if (breakMinute.minutes() > 0) {
        duration = duration.subtract(breakMinute);
      }
      dayTotal = duration.hours().toString() + "." + duration.minutes().toString();
    } else if (!end) {
      end = moment({ hours: 0, minutes: 0 });
    }
    setEnd(end);
    setDayTotal(dayTotal);
  };

  const onBreakChange = (breakDuration: Moment) => {
    let dayTotal: string = "0";
    if (breakDuration && start && end) {
      let breakHour: Duration = moment.duration(breakDuration.hours(), "hours");
      let breakMinute: Duration = moment.duration(breakDuration.minutes(), "minutes");
      let duration = moment.duration(end.diff(start));
      if (breakHour.hours() > 0) {
        duration = duration.subtract(breakHour);
      }
      if (breakMinute.minutes() > 0) {
        duration = duration.subtract(breakMinute);
      }
      dayTotal = duration.hours().toString() + "." + duration.minutes().toString();
    } else {
      breakDuration = moment({ hours: 0, minutes: 0 });
    }
    setBreakDuration(breakDuration);
    setDayTotal(dayTotal);
  };

  const handleChange = (dayTotal: string) => {
    let breakTime: string = breakDuration ? breakDuration.hours() + ":" + breakDuration.minutes() : "";
    let startTime: string = start ? start.format("hh:mm a") : "";
    let endTime: string = end ? end.format("hh:mm a") : "";
    props.OnChange && props.OnChange(startTime, endTime, breakTime, props.day, dayTotal);
  };

  if (!day) {
    return <></>;
  }
  return (
    <>
      <tbody>
        <tr>
          <th>Day</th>
          <td className="text-end">
            {props.day.format("dddd")}
            <Badge color="dark" className="time-badge ms-2">
              {props.day.format("DD")} <span>{props.day.format("MMM YYYY")}</span>
            </Badge>
          </td>
        </tr>
        <tr>
          <th>Start</th>
          <td className="text-end">
            <TimePicker
              className={`${disabled ? "disabled" : ""} weekly-start`}
              showSecond={false}
              value={start}
              onChange={(e) => onStartChange(e)}
              placeholder=""
              disabled={disabled}
              minuteStep={15}
            />
          </td>
        </tr>
        <tr>
          <th>Break</th>
          <td className="text-end">
            <TimePicker
              className={`${disabled ? "disabled" : ""} weekly-break`}
              showSecond={false}
              value={breakDuration}
              onChange={(e) => onBreakChange(e)}
              disabled={disabled}
              minuteStep={15}
            />
          </td>
        </tr>
        <tr>
          <th>End</th>
          <td className="text-end">
            <TimePicker
              className={`${disabled ? "disabled" : ""} weekly-end`}
              showSecond={false}
              value={end}
              onChange={(e) => onEndChange(e)}
              disabled={disabled}
              minuteStep={15}
            />
          </td>
        </tr>
        <tr>
          <th>Total</th>
          <td className="text-end">{<span className="line-total">{dayTotal}</span>}</td>
        </tr>
        <tr>
          <td colSpan={2}>
            <hr />
          </td>
        </tr>
      </tbody>
    </>
  );
};
