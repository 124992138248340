import * as React from "react";
import { Helmet } from "react-helmet";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

interface HomePageProps {}

interface HomePageState {
  responsive: any;
}

export default class HomePage extends React.Component<HomePageProps, HomePageState> {
  constructor(props: HomePageProps) {
    super(props);
    this.state = { responsive: {} };
  }

  componentDidMount() {
    document.title = "Home | Welcome to Acader Pty Ltd";
    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      },
    };

    this.setState({ responsive });
  }

  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Acader Pty Ltd</title>
          <meta name="description" content="Welcome to Acader Pty Ltd, Software development and consulting." />
        </Helmet>
        <div className="theme-primary home">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1>Digital, business and technology consulting.</h1>
                <div className="h1Divider"></div>
                <p className="mt-4">A LEADING DIGITAL SOLUTION PROVIDER</p>
              </div>
            </div>
          </div>
        </div>
        <div className="theme-white home">
          <div className="container mission">
            <div className="row">
              <div className="col">
                <div className="text-center">
                  <div className="divider divider-small">
                    <hr className="bg-dark border-radius m-auto"></hr>
                  </div>
                  <span className="section">Our mission</span>
                  <h2> We are dedicated to offering our clients the most exceptional strategic guidance possible.</h2>
                </div>
                <div className="row m-4">
                  <div className="col">
                    <h1>
                      <i className="fab fa-digital-ocean fa-8x"></i>
                    </h1>
                  </div>
                  <div className="col">
                    <p className="text">
                      Our mission is to empower businesses with innovative digital solutions, driving their growth and success in an ever-evolving digital
                      landscape.
                    </p>
                    <p className="sub-text">Our fundamentals:</p>
                    <ul className="fa-ul">
                      <li>
                        <span className="fa-li">
                          <i className="far fa-check-circle"></i>
                        </span>
                        Streamlined processes, increased productivity.
                      </li>
                      <li>
                        <span className="fa-li">
                          <i className="far fa-check-circle"></i>
                        </span>
                        Competitive edge through innovation, and adaptability.
                      </li>
                      <li>
                        <span className="fa-li">
                          <i className="far fa-check-circle"></i>
                        </span>
                        Strategic growth, expanded reach, customer connections.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="theme-light-blue" id="services">
          <div className="container services">
            <div className="row p-5 text-center">
              <div className="col-md-12 mt-2">
                <div className="divider divider-small">
                  <hr className="bg-dark border-radius m-auto"></hr>
                </div>
                <span className="section">HOW CAN WE HELP</span>
                <h2>Our Amazing Services</h2>
                <p className="text mt-3">
                  Cutting-edge IT solutions tailored for efficiency, security, and scalability, ensuring seamless digital transformation and driving
                  organizational success.
                </p>
              </div>
            </div>
            <div className="row cards text-center d-flex justify-content-center">
              <div className="col-md-4">
                <div className="card">
                  <div className="card-body">
                    <i className="fas fa-code fa-5x"></i>
                    <h5 className="card-title">Software development</h5>
                    <p className="card-text">Innovative solutions crafted to surpassing industry standards.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card">
                  <div className="card-body">
                    <i className="fas fa-tasks fa-5x"></i>
                    <h5 className="card-title">Project management</h5>
                    <p className="card-text">Efficient IT project management ensuring timely delivery.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card">
                  <div className="card-body">
                    <i className="fas fa-window-restore fa-5x"></i>
                    <h5 className="card-title">Analysis and Testing</h5>
                    <p className="card-text">Comprehensive analysis and testing for optimal performance.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="theme-white" id="clients">
          <div className="container">
            <div className="row p-5 text-center">
              <div className="col-md-12 mt-2">
                <div className="divider divider-small">
                  <hr className="bg-dark border-radius m-auto"></hr>
                </div>
                <span className="section">OUR CLIENTS</span>
                <h2>Trusted partners serving diverse federal government needs.</h2>
                <Carousel responsive={this.state.responsive}>
                  <div className="card" style={{ width: "18rem" }}>
                    <div className="card-body">
                      <img className="card-img-top" src="/images/australian-govt.png" alt="Australian Government" />
                      <hr />
                      <h5 className="card-title">National Capital Authority</h5>
                    </div>
                  </div>
                  <div className="card" style={{ width: "18rem" }}>
                    <div className="card-body">
                      <img className="card-img-top" src="/images/australian-govt.png" alt="Australian Government" />
                      <hr />
                      <h5 className="card-title">Commonwealth Director of Public Prosecutions</h5>
                    </div>
                  </div>
                  <div className="card" style={{ width: "18rem" }}>
                    <div className="card-body">
                      <img className="card-img-top" src="/images/australian-govt.png" alt="Australian Government" />
                      <hr />
                      <h5 className="card-title">Australian Centre for International Agricultural Research</h5>
                    </div>
                  </div>
                  <div className="card" style={{ width: "18rem" }}>
                    <div className="card-body">
                      <img className="card-img-top" src="/images/australian-govt.png" alt="Australian Government" />
                      <hr />
                      <h5 className="card-title">Australian Communications and Media Authority (ACMA)</h5>
                    </div>
                  </div>
                  <div className="card" style={{ width: "18rem" }}>
                    <div className="card-body">
                      <img className="card-img-top" src="/images/australian-govt.png" alt="Australian Government" />
                      <hr />
                      <h5 className="card-title">Department of Climate Change, Energy, the Environment and Water</h5>
                    </div>
                  </div>
                  <div className="card" style={{ width: "18rem" }}>
                    <div className="card-body">
                      <img className="card-img-top" src="/images/australian-govt.png" alt="Australian Government" />
                      <hr />
                      <h5 className="card-title">Department of Agriculture, Fisheries and Forestry</h5>
                    </div>
                  </div>
                </Carousel>
              </div>
            </div>
          </div>
        </div>

        <div className="theme-light-blue" id="aboutus">
          <div className="container">
            <div className="row p-5 text-center">
              <div className="col mt-2">
                <div className="divider divider-small">
                  <hr className="bg-dark border-radius m-auto"></hr>
                </div>
                <span className="section">ABOUT US</span>
                <h2>
                  We are leading Canberra-based digital solutions provider delivering innovative, tailored services to drive growth and success in the digital
                  landscape.
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div className="theme-white" id="contactus">
          <div className="container">
            <div className="row p-5 text-center">
              <div className="col-md-8 mt-3 col-sm-12 mt-5">
                <div className="divider divider-small">
                  <hr className="bg-dark border-radius m-auto"></hr>
                </div>
                <span className="section">CONTACT US</span>
                <h2>Feel free to reach out to us for further information about our products and services.</h2>
                <p className="text mt-3" style={{ userSelect: "none" }}>
                  <i className="fa fa-paper-plane me-2"></i>
                  info@acader.com.au
                </p>
              </div>
              <div className="col-md-4 col-sm-12 order-first order-sm-2">
                <h2>
                  <i className="fas fa-comments fa-8x"></i>
                </h2>
                <br />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
