import * as React from "react";
import { Helmet } from "react-helmet";

interface ReferralPageProps {}

interface ReferralPageState {}

export default class ReferralPage extends React.Component<ReferralPageProps, ReferralPageState> {
  constructor(props: ReferralPageProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.title = "Referral Program | Acader Pty Ltd";
  }

  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Referral Program | Acader Pty Ltd</title>
          <meta name="description" content="Referral Program | Acader Pty Ltd" />
        </Helmet>
        <div className="theme-primary home">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1>Candidate placement referral Program</h1>
                <div className="h1Divider"></div>
                <p className="mt-4">$300 is coming your way. This is our way to say thank you.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="theme-grey home">
          <div className="container">
            <div className="row">
              <div className="col">
                <h2>How does this referral program work?</h2>
                <hr />
                <div className="row">
                  <div className="col-md-6">
                    <ul>
                      <li>Candidate must be successful in an interview and must sign a contract with the client.</li>
                      <li>
                        Once the candidate has started with the new position, after the first pay cycle, we will send a cash card of $300 to the referring
                        person.
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6 text-end">
                    <h1>
                      <span className="fas fa-solid fa-credit-card fa-7x"></span>
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
