import { useState, useEffect } from "react";
import * as userController from "../../model/user";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { OrbitProgress } from "react-loading-indicators";
import { Button, Col, Container, Input, Label, Row } from "reactstrap";
import { MainContent } from "../../components/mainContent";
const instance = axios.create({
  withCredentials: true,
});

export const ResetPasswordPage = () => {
  document.title = "Reset password | Welcome to Timesheet approval";
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const auth = searchParams.get("auth");
  const [password, setPassword] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    setLoading(true);
    let response: any = await validateToken(token, auth);
    if (response && response.status === 200) {
      setUsername(response.data.email);
    } else {
      setErrorMessage("InvalidToken");
    }
    setLoading(false);
  };

  const handleClick = async () => {
    let response: any = await userController.resetPassword(username, password);
    if (response && response.status === 200) {
      setMessage("Password has been rest successfully");
      setTimeout(() => {
        window.location.href = "/login";
      }, 1000);
    } else {
      setErrorMessage("An error has occurred, please contact support!");
    }
  };

  const validateToken = async (token: string, auth: string) => {
    let response = await instance.post(process.env.REACT_APP_API_URL + "/validate-token", { data: { token: token, auth: auth } }).catch(function (error) {
      if (error.response) {
        console.log(error);
      }
    });
    return response;
  };

  if (loading) {
    return (
      <div className="spinner-center">
        <OrbitProgress variant="track-disc" color={"#319dcc"} speedPlus={0} easing="linear" />
      </div>
    );
  }

  return (
    <>
      <div className="theme-primary">
        <Container>
          <Row>
            <Col>
              <h1>Reset your password</h1>
              <div className="h1Divider"></div>
            </Col>
          </Row>
        </Container>
      </div>
      <MainContent width={4} className="main-content">
        {message !== "" ? (
          <>
            <div className="alert alert-success">{message}</div>
          </>
        ) : (
          <>
            <h5 className="fw-normal mb-3 pb-3" style={{ letterSpacing: "1px" }}>
              Please enter your new password
            </h5>

            <div data-mdb-input-init className="form-outline mb-4">
              <Input type="password" id="txtPassword" value={password} onChange={(e) => setPassword(e.currentTarget.value)} />
              <Label>New password</Label>
            </div>

            <div className="pt-1 mb-4">
              <Button data-mdb-button-init data-mdb-ripple-init className="btn-dark" type="button" onClick={async () => await handleClick()}>
                Reset password
              </Button>
            </div>
          </>
        )}
      </MainContent>
    </>
  );
};
