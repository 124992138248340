import * as React from "react";
import { Helmet } from "react-helmet";

interface SupportPageProps {}

interface SupportPageState {}

export default class SupportPage extends React.Component<SupportPageProps, SupportPageState> {
  constructor(props: SupportPageProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.title = "Support services | Acader Pty Ltd";
  }

  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Support | Acader Pty Ltd</title>
          <meta name="description" content="Acader Pty Ltd Support" />
        </Helmet>
        <div className="theme-primary">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1>Support</h1>
                <div className="h1Divider"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="theme-white">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2>Rely on our capable and talented staff to support your business and users</h2>
                <p>
                  While we deliver reliable and quality software development and consulting services, we understand that your people need ongoing technical
                  support. We have created an automated ticketing system which generates a unique ticket number as soon as contacted via our support email.
                  Clients can also contact using phone during business hours. The issue will then be entered in our ticketing system by our technical staff.
                </p>
                <p>We have a simple model of technical support where a request may falls under two categories.</p>
                <ul>
                  <li>Urgent</li>
                  <li>Moderate</li>
                </ul>
                <p>The table below describes how the two categories are different.</p>
                <table className="table table-striped support">
                  <thead>
                    <tr className="head">
                      <th scope="col"></th>
                      <th scope="col">Urgent</th>
                      <th scope="col">Moderate</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Acknowledgement</td>
                      <td>Immediate with automated ticket number</td>
                      <td>Immediate with automated ticket number</td>
                    </tr>
                    <tr>
                      <td>Response time</td>
                      <td> 2 business hours</td>
                      <td>12 business hours</td>
                    </tr>
                    <tr>
                      <td>Investigation and resolution to disruption</td>
                      <td>Immediately after response</td>
                      <td>Immediately after response</td>
                    </tr>
                  </tbody>
                </table>

                <br />
                <img src="images/support-flow.png" alt="Acader Pty Ltd Support Flow" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
