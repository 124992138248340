import { useState, useEffect } from "react";
import html2canvas from "html2canvas";
import moment from "moment";
import { OrbitProgress } from "react-loading-indicators";

import jsPDF from "jspdf";
import { Badge } from "reactstrap";
import { getById, getByToken, ITimesheet, ITimesheetData } from "../../model/timesheet";
import { Logo } from "../logo";

interface PrintControlProps {
  hidden?: boolean;
  id: string;
  mode: string;
}

export const PrintControl = (props: PrintControlProps) => {
  const [timesheet, setTimesheet] = useState<ITimesheet>();
  const [loading, setLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    initialise();
  }, []);

  const initialise = async () => {
    setLoading(true);
    let timesheet: ITimesheet;
    if (props.mode === "id") {
      let response = await getById(props.id);
      if (response && response.status === 200 && response.data.timesheet !== null) {
        timesheet = response.data.timesheet;
        setTimesheet(timesheet);
      }
    } else {
      let response = await getByToken(props.id);
      if (response && response.status === 200 && response.data.timesheet !== null) {
        timesheet = response.data.timesheet;
        setTimesheet(timesheet);
      }
    }

    if (!timesheet) {
      setErrorMessage("Timesheet not found");
    } else {
      setErrorMessage(null);
    }
    setLoading(false);
  };

  const getDaysHtml = () => {
    let li: any[] = [];
    moment.weekdaysShort().map((d, i) => {
      li.push(<li>{d}</li>);
    });
    return li;
  };

  const getData = (timesheet: ITimesheet) => {
    let dObject = moment("01/" + timesheet?.Month + "/" + timesheet?.Year, "DD/MMMM/YYYY", true);
    let numberOfDays: number = moment(dObject).daysInMonth();
    let data: ITimesheetData[] = [];
    if (Array.isArray(timesheet?.Data)) {
      data = timesheet?.Data;
    } else {
      data = JSON.parse(timesheet?.Data as string);
    }

    let allDaysInMonth: any[] = [];
    for (let i = 0; i < numberOfDays; i++) {
      allDaysInMonth.push(
        <li key={i} style={{ textAlign: "center" }}>
          <Badge color="dark" className="time-badge">
            <span>{i + 1}</span>{" "}
            <span className="d-none d-md-inline">
              {dObject.format("MMMM")} {dObject.format("YYYY")}
            </span>
          </Badge>
          <br />
          <br />

          <b style={{ fontSize: "large" }}>{data?.find((x) => x.day === (i + 1).toString())?.hours || "-"}</b>
        </li>
      );
    }

    let firstDay: string = moment(dObject).startOf("month").format("d");
    let blanks: any[] = [];
    for (let i = 0; i < parseInt(firstDay); i++) {
      blanks.push(
        <li key={i + numberOfDays}>
          <Badge className="" style={{ visibility: "hidden" }}>
            -
          </Badge>
          <br />
          <br />
          <b style={{ visibility: "hidden" }}>"-"</b>
        </li>
      );
    }
    let totalSlots: any[] = [...blanks, ...allDaysInMonth];
    return totalSlots;
  };

  const print = async () => {
    const element = document.getElementById("print");
    const canvas = await html2canvas(element);
    const imgData = canvas.toDataURL("image/png");

    const pdf = new jsPDF("l", "mm", "a4", 1 as any);

    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    // Calculate aspect ratio to fit the image within the PDF page
    const imgWidth = canvas.width;
    const imgHeight = canvas.height;
    const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);

    const imgWidthScaled = imgWidth * ratio;
    const imgHeightScaled = imgHeight * ratio;

    // Add the image to the PDF
    pdf.addImage(imgData, "JPEG", 0, 0, imgWidthScaled, imgHeightScaled, undefined, "FAST");
    let fileName = `${timesheet?.Owner.Firstname}-${timesheet?.Owner.Lastname}-${timesheet?.Month}-${timesheet?.Year}.pdf`;
    // Save the PDF
    pdf.save(fileName);
  };

  if (loading) {
    return (
      <div className="spinner-center">
        <OrbitProgress variant="track-disc" color={"#319dcc"} speedPlus={0} easing="linear" />
      </div>
    );
  }

  if (errorMessage) {
    return (
      <div className={`spinner-center ${props.hidden && "d-none"}`}>
        <div className="alert alert-danger">{errorMessage}</div>
      </div>
    );
  }

  return (
    <>
      <div id="print" className={props.hidden && "d-none"}>
        <div className="logo">
          <div className="row">
            <div className="col-8">
              <Logo url={timesheet?.Owner?.Company?.LOGO} companyName={timesheet?.Owner?.Company?.Name} />
            </div>
            <div className="col-4 ">
              <button data-html2canvas-ignore className="btn btn-light" type="button" onClick={() => print()}>
                <i className="fas fa-cloud-download-alt me-2"></i>
                <span className="d-none d-md-inline">Download this timesheet</span>
              </button>
            </div>
          </div>
        </div>
        <div className="holder">
          <h1>
            {timesheet?.Status === "Approved" || props.hidden ? (
              <>
                Timesheet{" "}
                <span title="Approved" className={`approved timesheet-status`}>
                  Approved
                </span>{" "}
                by {timesheet?.Owner?.ManagerName}
              </>
            ) : (
              <>
                Timesheet status: <span className={`${timesheet?.Status?.toLowerCase()} timesheet-status`}>{timesheet?.Status}</span>
                <br />
                Total hours: <span className="total-hours">{timesheet?.TotalHours}</span>
              </>
            )}
          </h1>
          <hr />
          {(timesheet?.Status === "Approved" || props.hidden) && (
            <table className="table mb-2 printDetails">
              <tr>
                <th>Date approved:</th>
                <td>{moment(timesheet?.ApprovedOn).format("DD MMMM YYYY")}</td>
                <th>Month:</th>
                <td>
                  {timesheet?.Month} - {timesheet?.Year}
                </td>
              </tr>
              <tr>
                <th>Employee:</th>
                <td>
                  {timesheet?.Owner?.Firstname} {timesheet?.Owner?.Lastname}
                </td>
                <th>Total hours:</th>
                <td className="total-hours">{timesheet?.TotalHours}</td>
              </tr>
            </table>
          )}

          <ul className="weekdays">{getDaysHtml()}</ul>

          <ul className="days">{timesheet && getData(timesheet)}</ul>
        </div>
        <hr />
      </div>
    </>
  );
};
