import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Layout } from "./layouts/layout";
import * as serviceWorker from "./serviceWorker";
import { ILogin } from "./model/login";

require("./styles/index.scss");

declare global {
  interface Document {
    documentMode?: any;
  }
}
const root = ReactDOM.createRoot(document.getElementById("acader-root") as HTMLElement);

root.render(
  <BrowserRouter>
    <Layout />
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
