import { Component } from "react";
import { Helmet } from "react-helmet";
import { NumericFormat } from "react-number-format";

interface ITaxCallback {
  tax: number;
  remainingSalary: number;
}
interface TaxCalculatorProps {}

interface TaxCalculatorState {
  salary: number;
  tax: number;
}

export default class TaxCalculator extends Component<TaxCalculatorProps, TaxCalculatorState> {
  constructor(props: TaxCalculatorProps) {
    super(props);
    this.state = {
      salary: null,
      tax: null,
    };
  }

  componentDidMount() {
    document.title = "Australia Simple Tax Calculator | Welcome to Timesheet approval";
  }

  handleSalaryChange(value: string) {
    let salary = parseInt(value);
    let tax: number = 0;
    if (salary >= 0 && salary <= 18200) {
      tax = 0;
    } else if (salary > 18200 && salary <= 37000) {
      let callback = this.calculateTax(salary, 18200, 0.19);
      tax = callback.tax;
    } else if (salary > 37000 && salary <= 90000) {
      let callback = this.calculateTax(37000, 18200, 0.19);
      tax = callback.tax;
      callback = this.calculateTax(salary, 37000, 0.325);
      tax = tax + callback.tax;
    } else if (salary > 90000 && salary <= 180000) {
      let callback = this.calculateTax(37000, 18200, 0.19);
      tax = callback.tax;
      callback = this.calculateTax(90000, 37000, 0.325);
      tax = tax + callback.tax;
      callback = this.calculateTax(salary, 90000, 0.37);
      tax = tax + callback.tax;
    } else if (salary > 180000) {
      let callback = this.calculateTax(37000, 18200, 0.19);
      tax = callback.tax;
      callback = this.calculateTax(90000, 37000, 0.325);
      tax = tax + callback.tax;
      callback = this.calculateTax(180000, 90000, 0.37);
      tax = tax + callback.tax;
      callback = this.calculateTax(salary, 180000, 0.45);
      tax = tax + callback.tax;
    }
    this.setState({ salary, tax });
  }

  calculateTax(salary: number, threshold: number, multiplier: number) {
    let callback: ITaxCallback;
    let remainingSalary: number = salary - threshold;
    let tax = remainingSalary * multiplier;
    callback = { tax, remainingSalary };
    return callback;
  }

  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Australia Simple Tax Calculator | Acader HR</title>
          <meta name="description" content="Estimate your tax returns within Australia" />
        </Helmet>
        <div className="theme-primary">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1>Australia Simple Tax Calculator</h1>
                <div className="h1Divider"></div>
                <p className="mt-3">Estimate your tax calculator for current financial year (for Australian residents only).</p>
              </div>
            </div>
          </div>
        </div>
        <div className="theme-white">
          <div className="container">
            <div className="row tax-calculator">
              <div className="col-md-5">
                <h2>Enter your yearly salary</h2>
                <hr />
                <NumericFormat
                  placeholder="Estimated salary"
                  className="form-control mb-5"
                  thousandSeparator={true}
                  prefix={"$"}
                  value={this.state.salary}
                  onValueChange={(e) => this.handleSalaryChange(e.value)}
                ></NumericFormat>
                <h3>Your estimated tax:</h3>
                <hr />
                <NumericFormat className="form-control" disabled thousandSeparator={true} prefix={"$"} value={this.state.tax}></NumericFormat>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
