import { Navigate, Outlet } from "react-router-dom";
import { SecureNavbar } from "../components/navbar";

import { BaseProps } from "../model/auth";
import { Button, Col, Container, Row } from "reactstrap";
import { Footer } from "./footer";
import { Logo } from "../components/logo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

interface SecureLayoutProps extends BaseProps {
  openMenu: boolean;
  isAuthenticated: boolean;
}

export const SecureLayout = (props: SecureLayoutProps) => {
  if (!props.isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return (
    <>
      <div className="d-flex">
        <SecureNavbar {...props} tokenUser={props.tokenUser} openMenu={props.openMenu} />
        <Container fluid className="p-0">
          <Outlet />
        </Container>
      </div>
      <Footer />
    </>
  );
};
