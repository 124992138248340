import { Button } from "reactstrap";
import { companyLogo } from "../model/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { faBars } from "@fortawesome/free-solid-svg-icons";

interface LogoProps {
  onClick: (toggle: boolean) => void;
}

export const ToggleMenu = (props: LogoProps) => {
  const [toggle, setToggle] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // create an event listener
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    props.onClick(toggle);
  }, [toggle]);

  return (
    <>
      {isMobile && (
        <Button className="b-0" style={{ backgroundColor: "#112f44" }} onClick={() => setToggle((x) => !x)}>
          <FontAwesomeIcon icon={faBars} />
        </Button>
      )}
    </>
  );
};
