import { useEffect, useRef, useState } from "react";
import { ILogin, loginSchema, login, deleteCookie } from "../../model/login";
import { ITokenUser, IUser } from "../../model/user";
import { Helmet } from "react-helmet";
import { tokenName, tokenValue } from "../../model/auth";
import { jwtDecode } from "jwt-decode";
import { MainContent } from "../../components/mainContent";
import { Button, Col, Container, FormGroup, Input, Label, Row } from "reactstrap";
import { GoogleLoginButton } from "../../components/google/googleLoginButton";
import Cookies from "js-cookie";
import { XeroLoginButton } from "../../components/xero/peroLoginButton";
import { useSearchParams } from "react-router-dom";

export const LoginPage = () => {
  const [searchParams] = useSearchParams();
  const message = searchParams.get("error");

  const loginButtonRef = useRef<HTMLButtonElement>();
  document.title = "Login | Acader Pty Ltd";
  const [loginModel, setLoginModel] = useState<ILogin>({ username: "", password: "" });
  const [errors, setErrors] = useState<ILogin>({});
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [googleButtonWith, setGoogleButtonWidth] = useState<number>();
  const [user, setUser] = useState<IUser>({
    Email: "",
    Firstname: "",
    Password: "",
    Lastname: "",
    ManagerEmail: null,
    ManagerName: null,
    Active: false,
    Mode: "Monthly",
    UserRoles: [],
  });

  useEffect(() => {
    try {
      setLoading(true);
      if (message) {
        setError("login failed, please try again");
      }
      const token = Cookies.get("jwt");
      console.log(window.location.origin);

      if (token) {
        setLoading(true);
        initialiseCookieToken(token);
      } else {
        initialiseStorageToken(tokenValue);
      }
    } finally {
      setLoading(false);
    }
  }, []);

  const initialiseStorageToken = (token: string) => {
    const user: ITokenUser = token ? jwtDecode(token) : null;
    var exp = user?.exp * 1000;
    let tokenIsExpired = !exp || new Date() > new Date(exp);
    if (tokenIsExpired) {
      localStorage.setItem(tokenName, null);
    } else {
      if (user?.Roles?.find((x) => x === "Employee")) {
        window.location.href = "/timesheet";
      } else {
        window.location.href = "/timesheet/admin/users/employee";
      }
    }
  };

  const initialiseCookieToken = (token: string) => {
    const user: ITokenUser = token ? jwtDecode(token) : null;
    var exp = user?.exp * 1000;
    let tokenIsExpired = !exp || new Date() > new Date(exp);
    if (tokenIsExpired) {
      deleteCookie();
    } else {
      localStorage.setItem(tokenName, JSON.stringify(token));
      deleteCookie().then(() => {
        setLoading(true);
        if (user?.Roles?.find((x) => x === "Employee")) {
          window.location.href = "/timesheet";
        } else {
          window.location.href = "/timesheet/admin/users/employee";
        }
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    setGoogleButtonWidth(loginButtonRef.current?.offsetWidth);
  }, [loginButtonRef?.current]);

  const handleClick = async () => {
    try {
      await loginSchema.validateSync(loginModel, {
        abortEarly: false,
      });
      setLoading(true);
      let response: any = await login(loginModel.username, loginModel.password);
      if (response && response.status === 200) {
        if (response?.data?.token) {
          let token = response?.data?.token;
          const user: ITokenUser = token ? jwtDecode(token) : null;
          localStorage.setItem(tokenName, JSON.stringify(response.data?.token));
          if (user?.Roles?.find((x) => x === "Employee")) {
            window.location.href = "/timesheet";
          } else {
            window.location.href = "/timesheet/admin/users/employee";
          }
        }
      } else {
        setError("login failed, please try again");
        setLoading(false);
      }
    } catch (yupErrors) {
      let errors: ILogin = {};
      yupErrors?.inner?.map((x: any) => {
        errors[x.path] = x.message;
      });
      setErrors(errors);
      setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Login to your timesheet | Acader Pty Ltd</title>
        <meta name="description" content="Login to access timesheet approval system" />
      </Helmet>
      <div className="theme-primary">
        <Container>
          <Row>
            <Col>
              <h1>Login to your timesheet system</h1>
              <div className="h1Divider"></div>
            </Col>
          </Row>
        </Container>
      </div>

      <MainContent loading={loading} width={4} className="main-content">
        <div style={{ minHeight: "400px" }}>
          {error !== "" ? (
            <div className="alert alert-danger" id="login-error">
              {error}
            </div>
          ) : (
            ""
          )}
          <h5 className="fw-normal mb-3 pb-3" style={{ letterSpacing: "1px" }}>
            Sign into your account
          </h5>

          <FormGroup>
            <Label className="required">Email address</Label>

            <Input
              type="email"
              id="txtUserNameLogin"
              value={loginModel.username}
              onChange={(e) => {
                e.persist();
                setLoginModel((x) => ({ ...x, username: e.target.value }));
              }}
              onKeyDown={async (e) => {
                if (e.key === "Enter") await handleClick();
              }}
            />
            {errors.username && <Label className="text-danger">{errors.username}</Label>}
          </FormGroup>

          <FormGroup>
            <Label className="required">Password</Label>
            <Input
              type="password"
              id="txtPasswordLogin"
              value={loginModel.password}
              onChange={(e) => {
                e.persist();
                setLoginModel((x) => ({ ...x, password: e.target.value }));
              }}
              onKeyDown={async (e) => {
                if (e.key === "Enter") await handleClick();
              }}
            />
            {errors.password && <Label className="text-danger">{errors.password}</Label>}
          </FormGroup>
          <div className="d-grid gap-2">
            <button
              ref={loginButtonRef}
              id="login-button"
              data-mdb-button-init
              data-mdb-ripple-init
              className="btn btn-dark"
              type="button"
              onClick={async () => await handleClick()}
            >
              Login
            </button>

            <Button className="btn-warning" type="button" onClick={() => (window.location.href = "/forgot-password")}>
              Forgot password?
            </Button>
            <div className="seperator">
              <span>OR</span>
            </div>

            <GoogleLoginButton width={googleButtonWith} />
            <XeroLoginButton width={googleButtonWith} />
          </div>
        </div>
      </MainContent>
    </>
  );
};
