import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export const ErrorPage = () => {
  document.title = "Error page | Acader HR";
  const [searchParams] = useSearchParams();
  const message = searchParams.get("message");
  const redirectTo = searchParams.get("redirectTo");

  useEffect(() => {
    setTimeout(() => {
      window.location.href = redirectTo;
    }, 2000);
  }, []);

  return (
    <>
      {message && (
        <div className="spinner-center">
          <div className="alert alert-danger">{message}</div>
        </div>
      )}
    </>
  );
};
