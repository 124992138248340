import { useEffect, useState } from "react";

import { BaseProps } from "../../model/auth";
import { MainContent } from "../../components/mainContent";
import { IUser, getUser, managerSchema, modeOptions, updateManager } from "../../model/user";
import { Button, Col, Container, FormGroup, Input, Label, Row } from "reactstrap";
import { ToggleMenu } from "../../components/toggleMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";

interface ManagerPageProps extends BaseProps {}
export const ManagerPage = (props: ManagerPageProps) => {
  document.title = "My Settings | Acader HR";
  const [user, setUser] = useState<IUser>(null);
  const [managerErrors, setManagerErrors] = useState<IUser>({});
  const [saved, setSaved] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (props.tokenUser) {
      initialise();
    }
  }, [props.tokenUser]);

  const handleClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    try {
      setManagerErrors({});
      await managerSchema.validateSync(user, {
        abortEarly: false,
      });
      setSaved(true);
      let response: any = await updateManager(props.tokenUser?.Id, user.ManagerName, user.ManagerEmail, user.Mode);
      if (response && response.status === 200) {
        setSaved(false);
      }
    } catch (yupErrors) {
      let errors: IUser = {};
      yupErrors?.inner?.map((x: any) => {
        errors[x.path] = x.message;
      });
      setManagerErrors(errors);
    } finally {
      setSaved(false);
    }
  };

  const initialise = async () => {
    setLoading(true);
    let response: any = await getUser(props.tokenUser.Id?.toString());
    if (response && response.status === 200) {
      let user: IUser = response.data.user;
      setUser(user);
      setLoading(false);
    }
  };

  return (
    <>
      <div className="theme-primary">
        <Container>
          <Row>
            <Col xs={10}>
              <h1>Manage settings</h1>
              <div className="h1Divider"></div>
            </Col>
            <Col xs={2} className="text-end">
              <ToggleMenu onClick={(toggle) => props.onMenuToggleClick(toggle)} />
            </Col>
          </Row>
        </Container>
      </div>
      <MainContent loading={loading} width={4} saved={saved}>
        <FormGroup>
          <Label className="required">Manager's full name</Label>
          <Input
            type="text"
            id="txtManagerName"
            value={user?.ManagerName}
            onChange={(e) => {
              e.persist();
              setUser((x) => ({ ...x, ManagerName: e.target.value }));
            }}
          />
          {managerErrors.ManagerName && <Label className="text-danger">{managerErrors.ManagerName}</Label>}
        </FormGroup>

        <FormGroup>
          <Label className="required">Manager's email address</Label>
          <Input
            type="text"
            id="txtManagerEmail"
            value={user?.ManagerEmail}
            onChange={(e) => {
              e.persist();
              setUser((x) => ({ ...x, ManagerEmail: e.target.value }));
            }}
          />
          {managerErrors.ManagerEmail && <Label className="text-danger">{managerErrors.ManagerEmail}</Label>}
        </FormGroup>

        <FormGroup>
          <Label className="required">Timesheet mode</Label>
          <select
            id="ddlMode"
            className="form-control"
            defaultValue={user?.Mode?.toString()}
            onChange={(e) => {
              e.persist();
              setUser((x) => ({ ...x, Mode: e.target.value as modeOptions }));
            }}
          >
            <option value={"Monthly"}>Monthly</option>
            <option value={"Weekly"}>Weekly</option>
          </select>
          {managerErrors.Mode && <Label className="text-danger">{managerErrors.Mode}</Label>}
        </FormGroup>

        <div className="d-grid gap-2">
          <Button id="btnSettingChange" data-mdb-button-init data-mdb-ripple-init color="acader" type="button" onClick={async (e) => await handleClick(e)}>
            <FontAwesomeIcon icon={faSave} className="me-2" />
            Save
          </Button>
        </div>
      </MainContent>
    </>
  );
};
