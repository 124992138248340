import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { ITimesheet } from "./timesheet";
import axios from "axios";
import { axiosAuth } from "./auth";

export interface ITimesheetAttachment {
  Id?: number;
  Name: string;
  Data: any;
  MimeType: string;
  TimesheetId?: number;
  CompliantTimesheetId?: number;
  Timesheet?: ITimesheet;
}

export const uploadTimesheet = async (timesheet: ITimesheet) => {
  var element = document.getElementById("print");

  element.removeAttribute("class");
  console.log(element);

  html2canvas(element).then(async (canvas) => {
    const imgData = canvas.toDataURL("image/png");
    // Create a new jsPDF instance
    const pdf = new jsPDF("l", "mm", "a4", true);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    // Calculate aspect ratio to fit the image within the PDF page
    const imgWidth = canvas.width;
    const imgHeight = canvas.height;
    const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);

    const imgWidthScaled = imgWidth * ratio;
    const imgHeightScaled = imgHeight * ratio;

    // Add the image to the PDF
    pdf.addImage(imgData, "JPEG", 0, 0, imgWidthScaled, imgHeightScaled, undefined, "FAST");
    const pdfBlob = pdf.output("blob");
    let fileName = `${timesheet?.Owner.Firstname}-${timesheet?.Owner.Lastname}-${timesheet?.Month}-${timesheet?.Year}.pdf`;

    let postContent = new FormData();
    postContent.append("Mode", "Monthly");
    postContent.append("Name", fileName);
    postContent.append("TimesheetId", timesheet?.Id?.toString());

    postContent.append("file", pdfBlob, fileName);

    await axios.post(process.env.REACT_APP_API_URL + "/a/create", postContent, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  });
};

export const uploadTimesheetFromFile = async (timesheet: ITimesheet, file: File) => {
  let fileName = `${timesheet?.Owner.Firstname}-${timesheet?.Owner.Lastname}-${timesheet?.Month}-${timesheet?.Year}.pdf`;

  let postContent = new FormData();
  postContent.append("Mode", "Monthly");
  postContent.append("Name", fileName);
  postContent.append("TimesheetId", timesheet?.Id?.toString());

  postContent.append("file", file);

  await axiosAuth.post(process.env.REACT_APP_API_URL + "/a/create", postContent, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export let deleteOne = async (id: number) => {
  let response: any = await axiosAuth.post(process.env.REACT_APP_API_URL + "/attachment/delete", { id });
  return response;
};

export const download = async (id: number) => {
  let response: any = await axiosAuth.get(process.env.REACT_APP_API_URL + "/attachment/download", { responseType: "blob", params: { id: id } });
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `timesheet.pdf`);
  document.body.appendChild(link);
  link.click();
};
