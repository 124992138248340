import { Fragment } from "react/jsx-runtime";
import { companyLogo } from "../model/auth";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";

export interface IBreadcrumbItems {
  name: string;
  link?: string;
}

interface BreadcrumbControlProps {
  items: IBreadcrumbItems[];
}

export const BreadcrumbControl = (props: BreadcrumbControlProps) => {
  return (
    <>
      <Breadcrumb>
        {props.items?.map((x, i) => {
          return (
            <BreadcrumbItem key={i} active={x.link !== null || x.link !== undefined}>
              {x.link && <a href={x.link}>{x.name}</a>}
              {!x.link && <span>{x.name}</span>}
            </BreadcrumbItem>
          );
        })}
      </Breadcrumb>
    </>
  );
};
