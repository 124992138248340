import { useState, useEffect } from "react";
import { BaseProps } from "../../../model/auth";

import { getPayrollCalendars, getPayruns } from "../../../model/xero";
import { Col, Container, Row, Table } from "reactstrap";
import { ToggleMenu } from "../../../components/toggleMenu";
import { PayrollCalendar } from "xero-node/dist/gen/model/payroll-au/payrollCalendar";
import { MainContent } from "../../../components/mainContent";

import { PayRun } from "xero-node/dist/gen/model/payroll-au/payRun";
import { PayrunControl } from "./payrunControl";
import { logout } from "../../../model/login";

export const PayrunPage = (props: BaseProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [payRollCalendars, setPayRollCalendars] = useState<PayrollCalendar[]>([]);
  const [payRuns, setPayRuns] = useState<PayRun[]>([]);

  useEffect(() => {
    initialise();
  }, []);

  const initialise = async () => {
    setLoading(true);
    let response: any = await getPayrollCalendars();
    if (response && response.status === 200) {
      setPayRollCalendars(response?.data.payrollCalendars);

      let payRunResponse: any = await getPayruns();
      setPayRuns(payRunResponse?.data.payRuns);
    } else {
      await logout();
    }

    setLoading(false);
  };

  return (
    <>
      <div className="theme theme-primary">
        <Container>
          <Row>
            <Col xs={10}>
              <h1>Pay runs</h1>
              <div className="h1Divider"></div>
            </Col>
            <Col xs={2} className="text-end">
              <ToggleMenu onClick={(toggle) => props.onMenuToggleClick(toggle)} />
            </Col>
          </Row>
        </Container>
      </div>
      <MainContent loading={loading}>
        <h2>Pay calendars</h2>
        <hr />
        <Table striped responsive>
          <thead>
            <th className="col-5">Name</th>
            <th className="col-4">Start date</th>
            <th className="col-3"></th>
          </thead>
          <tbody>
            {payRollCalendars?.map((x, i) => {
              return <PayrunControl key={i} payRunCalendar={x} payRun={payRuns.find((p) => p.payrollCalendarID === x.payrollCalendarID)} />;
            })}
          </tbody>
        </Table>
      </MainContent>
    </>
  );
};
