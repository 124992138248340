import { useState, useEffect } from "react";

import { createPayRun, getPayrun } from "../../../model/xero";
import { Button, Collapse, Table } from "reactstrap";

import { PayrollCalendar } from "xero-node/dist/gen/model/payroll-au/payrollCalendar";
import { faExternalLinkAlt, faEye, faPlus } from "@fortawesome/free-solid-svg-icons";

import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PayRun } from "xero-node/dist/gen/model/payroll-au/payRun";
import { PayslipControl } from "./payslipControl";

interface PayrunControlProps {
  payRun?: PayRun;
  payRunCalendar: PayrollCalendar;
}

export const PayrunControl = (props: PayrunControlProps) => {
  const [collapseIsOpen, setCollapseIsOpen] = useState<boolean>(false);

  useEffect(() => {}, []);

  return (
    <>
      <tr>
        <td>{props.payRunCalendar.name}</td>
        <td>{moment(props.payRunCalendar.startDate).format("DD MMM YYYY")}</td>
        <td>
          {props.payRun ? (
            <Button size="sm" color="acader" className="text-end" onClick={() => setCollapseIsOpen((x) => !x)}>
              <FontAwesomeIcon icon={faEye} className="me-2" /> View draft payrun
            </Button>
          ) : (
            <Button
              size="sm"
              color="acader"
              className="text-end"
              onClick={async () => {
                await createPayRun(props.payRunCalendar?.payrollCalendarID);
              }}
            >
              <FontAwesomeIcon icon={faPlus} className="me-2" /> Create draft payrun
            </Button>
          )}
        </td>
      </tr>
      <tr>
        <td colSpan={3} className="p-0">
          <Collapse isOpen={collapseIsOpen}>
            <Table>
              <thead>
                <tr className="table-warning">
                  <th>Period</th>
                  <th>Payment date</th>
                  <th>Wages</th>
                  <th>Tax</th>
                  <th>Super</th>
                  <th>Net pay</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <PayslipControl payRun={props.payRun} />
              </tbody>
            </Table>
          </Collapse>
        </td>
      </tr>
    </>
  );
};
