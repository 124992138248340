import { IUser } from "./user";
import { axiosAuth } from "./auth";
import axios from "axios";
import { IComment } from "./common";
import { ITimesheetAttachment } from "./attachment";

export type statusOption = "New" | "Draft" | "Sent" | "Approved" | "Closed" | "Rejected";
export type modeOption = "employee" | "manager";

export interface ITimesheetData {
  day: string;
  hours: string;
}

export interface ITimesheet {
  Id?: number;
  Month: string;
  Year: string;
  Status: statusOption;
  Data: ITimesheetData[];
  UserId: number;
  Token: string;
  TotalHours: number;
  InvoiceId?: string;
  InvoiceStatus?: "DRAFT" | "SUBMITTED" | "AUTHORISED" | "DELETED" | "VOIDED";
  InvoiceSent?: boolean;
  ApprovedOn?: Date;
  Comments?: IComment[];
  Owner?: IUser;
  TimesheetAttachments?: ITimesheetAttachment[];
}

export let getChartData = async (id: number, year: string) => {
  let response: any = await axiosAuth
    .get(process.env.REACT_APP_API_URL + "/timesheet/charts", {
      params: { id: id, year: year },
    })
    .catch(function (error) {
      if (error.response) {
        console.log(error.response);
      }
    });

  return response;
};

export let getTimesheet = async (month: string, year: string, userId: number) => {
  let params = { month: month, year: year, userId: userId };

  let response: any = await axiosAuth
    .get(process.env.REACT_APP_API_URL + "/timesheet/get", {
      params: params,
    })
    .catch(function (error) {
      if (error.response) {
        console.log(error.response);
      }
    });

  return response;
};

export let getById = async (id: string) => {
  let params = { id: id };

  let response: any = await axiosAuth
    .get(process.env.REACT_APP_API_URL + "/timesheet/id/get", {
      params: params,
    })
    .catch(function (error) {
      if (error.response) {
        console.log(error.response);
      }
    });

  return response;
};

export let getApprovedAndNotInvoiced = async (id: string) => {
  let response: any = await axiosAuth.get(process.env.REACT_APP_API_URL + "/timesheet/approved", { params: { id } }).catch(function (error) {
    if (error.response) {
      console.log(error.response);
    }
  });

  return response;
};

export let getByToken = async (token: string) => {
  let params = { token: token };

  let response: any = await axios
    .get(process.env.REACT_APP_API_URL + "/t/token/get", {
      params: params,
    })
    .catch(function (error) {
      if (error.response) {
        console.log(error.response);
      }
    });

  return response;
};

export let saveTimesheet = async (timesheet: ITimesheet) => {
  let response = await axiosAuth.post(process.env.REACT_APP_API_URL + "/timesheet/save", { timesheet }).catch(function (error) {
    if (error.response) {
      console.log(error);
    }
  });
  return response;
};

export let sendForApproval = async (timesheet: ITimesheet) => {
  let response = await axiosAuth.post(process.env.REACT_APP_API_URL + "/timesheet/send-for-approval", { timesheet }).catch(function (error) {
    if (error.response) {
      console.log(error);
    }
  });
  return response;
};

export let approve = async (id: number) => {
  let response = await axiosAuth.post(process.env.REACT_APP_API_URL + "/t/approve", { id }).catch(function (error) {
    if (error.response) {
      console.log(error);
    }
  });
  return response;
};

export let reject = async (id: number, comment: string, userName: string) => {
  let response = await axiosAuth.post(process.env.REACT_APP_API_URL + "/t/reject", { id, comment, userName }).catch(function (error) {
    if (error.response) {
      console.log(error);
    }
  });
  return response;
};

export let changeTimesheetStatus = async (timesheet: ITimesheet) => {
  let response = await axiosAuth.post(process.env.REACT_APP_API_URL + "/timesheet/change-status", { timesheet }).catch(function (error) {
    if (error.response) {
      console.log(error);
    }
  });
  return response;
};

export let validateToken = async (id: string) => {
  let response: any = await axiosAuth
    .get(process.env.REACT_APP_API_URL + "/t/validateeSign", {
      params: { id: id },
    })
    .catch(function (error) {
      if (error.response) {
        console.log(error.response);
      }
    });

  return response;
};
