import React, { useState, useEffect } from "react";
import * as userController from "../../model/user";
import { Alert, Button, Col, Container, Input, Label, Row } from "reactstrap";
import { MainContent } from "../../components/mainContent";

export const ForgotPasswordPage = () => {
  document.title = "Forgot password | Welcome to Timesheet approval";
  const [username, setUsername] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleClick = async () => {
    setErrorMessage("");
    if (username.trim() === "") {
      setErrorMessage("Please provide a valid email address");
      return;
    }
    let response: any = await userController.forgotPassword(username);
    if (response && response.status === 200) {
      setMessage("Please check your email for further instructions.");
    } else {
      setMessage("");
    }
  };

  return (
    <>
      <div className="theme-primary">
        <Container>
          <Row>
            <Col>
              <h1>Forgotten password</h1>
              <div className="h1Divider"></div>
            </Col>
          </Row>
        </Container>
      </div>
      <MainContent width={4} className="main-content">
        {errorMessage !== "" && <Alert color="danger">{errorMessage}</Alert>}
        {message !== "" ? (
          <>
            <Alert color="success">{message}</Alert>

            <div className="d-grid gap-2">
              <Button data-mdb-button-init data-mdb-ripple-init className="btn-dark" type="button" onClick={async () => (window.location.href = "/login")}>
                Go back to login page
              </Button>
            </div>
          </>
        ) : (
          <>
            <h5 className="fw-normal mb-3 pb-3" style={{ letterSpacing: "1px" }}>
              Please enter your email address
            </h5>

            <div data-mdb-input-init className="form-outline mb-4">
              <Input type="email" id="txtUserName" value={username} onChange={(e) => setUsername(e.currentTarget.value)} />
              <Label>Email address</Label>
            </div>

            <div className="d-grid gap-2">
              <Button data-mdb-button-init data-mdb-ripple-init className="btn-dark" type="button" onClick={async () => await handleClick()}>
                Request password reset
              </Button>
            </div>
          </>
        )}
      </MainContent>
    </>
  );
};
