/* eslint-disable react/jsx-no-target-blank */
import { useEffect, useState } from "react";
import { ICompany, get } from "../../../model/company";
import { MainContent } from "../../../components/mainContent";
import { Col, Container, FormGroup, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import { BaseProps } from "../../../model/auth";
import { ToggleMenu } from "../../../components/toggleMenu";

interface CompanyProps extends BaseProps {}

export const CompanyPage = (props: CompanyProps) => {
  const newCompany: ICompany = { Subscription: "Free", CountryId: 1, Active: true };

  const [loading, setLoading] = useState<boolean>(true);
  const [company, setCompany] = useState<ICompany>(newCompany);
  const [companyErrors, setCompanyErrors] = useState<ICompany>({});

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    let response: any = await get(props.tokenUser?.Company?.Id);
    if (response && response.status === 200) {
      setCompany(response.data.company);
    }
    setLoading(false);
  };

  return (
    <>
      <div className="theme-primary">
        <Container>
          <Row>
            <Col xs={10}>
              <h1>My Company</h1>
              <div className="h1Divider"></div>
            </Col>
            <Col xs={2} className="text-end">
              <ToggleMenu onClick={(toggle) => props.onMenuToggleClick(toggle)} />
            </Col>
          </Row>
        </Container>
      </div>
      <MainContent loading={loading}>
        <Row>
          <Col>
            <FormGroup>
              <Label className="required">Company name</Label>
              <Input id="txtName" type="text" value={company.Name} disabled />
            </FormGroup>
            <FormGroup>
              <Label className="required">Email</Label>
              <Input id="txtEmail" type="text" value={company.Email} disabled />
            </FormGroup>
            <FormGroup>
              <Label>Address line 1</Label>
              <Input id="txtAddressLine1" type="text" value={company.AddressLine1} disabled />
            </FormGroup>
            <FormGroup>
              <Label>City</Label>
              <Input id="txtCity" type="text" value={company.City} disabled />
            </FormGroup>
            <FormGroup>
              <Label>Post code</Label>
              <Input id="txtPostCode" type="text" value={company.PostCode} disabled />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label className="required">ABN</Label>
              <Input id="txtAbn" type="number" value={company?.ABN} disabled />
            </FormGroup>
            <FormGroup>
              <Label className="required">Phone</Label>
              <Input id="txtPhone" type="number" value={company?.Phone} disabled />
            </FormGroup>
            <FormGroup>
              <Label>Address line 2</Label>
              <Input id="txtAddressLine2" type="text" value={company.AddressLine2} disabled />
            </FormGroup>

            <FormGroup>
              <Label>State</Label>
              <Input id="txtState" type="text" value={company.State} disabled />
            </FormGroup>
            <FormGroup>
              <Label className="required">Country</Label>
              <select className="form-control" defaultValue={company?.CountryId?.toString()} disabled>
                <option value={1}>Australia</option>
              </select>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label className="required">Subscription</Label>
              <Select value={{ value: company?.Subscription, label: company?.Subscription }} isDisabled />
            </FormGroup>
          </Col>
        </Row>
      </MainContent>
    </>
  );
};
