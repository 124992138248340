import { useState, useEffect } from "react";
import { TimesheetControl } from "./timesheetControl";
import { IUser, getUser } from "../../../model/user";
import { WeeklyTimesheetControl } from "./weeklyTimesheetControl";
import { OrbitProgress } from "react-loading-indicators";
import { BaseProps } from "../../../model/auth";

interface TimesheetProps extends BaseProps {}

export const Timesheet = (props: TimesheetProps) => {
  const [user, setUser] = useState<IUser>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (props.tokenUser) {
      initialise();
    }
  }, [props.tokenUser]);

  const initialise = async () => {
    let response: any = await getUser(props.tokenUser?.Id.toString());
    if (response && response.status === 200) {
      let user: IUser = response.data.user;
      setUser(user);
      setLoading(false);
    }
  };
  if (loading) {
    return (
      <div className="spinner-center">
        <OrbitProgress variant="track-disc" color={"#319dcc"} speedPlus={0} easing="linear" />
      </div>
    );
  }

  return (
    <>
      {user?.Mode === "Monthly" ? (
        <TimesheetControl {...props} mode="employee" onMenuToggleClick={(openMenu) => props.onMenuToggleClick(openMenu)} />
      ) : (
        <WeeklyTimesheetControl {...props} mode="employee" onMenuToggleClick={(openMenu) => props.onMenuToggleClick(openMenu)} />
      )}
    </>
  );
};
