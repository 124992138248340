import { Outlet } from "react-router-dom";

import { BaseProps } from "../model/auth";
import { PublicNavbar } from "../components/publicNavbar";
import { Footer } from "./footer";
import { Col, Container, Row } from "reactstrap";

interface PublicLayoutProps extends BaseProps {
  isAuthenticated: boolean;
}

export const PublicLayout = (props: PublicLayoutProps) => {
  return (
    <>
      <div className="wrapper">
        <div className="theme theme-dark">
          <Container>
            <Row>
              <Col>
                <PublicNavbar isAuthenticated={props.isAuthenticated} />
              </Col>
            </Row>
          </Container>
        </div>
        <div className="content">
          <Outlet />
          <div className="push"></div>
        </div>
        <Footer />
      </div>
    </>
  );
};
