import { axiosAuth } from "./auth";

export let importTimesheet = async (file: File) => {
  let postContent = new FormData();
  postContent.append("file", file);
  let response = await axiosAuth
    .post(process.env.REACT_APP_API_URL + "/openai/import", postContent, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .catch(function (error) {
      if (error.response) {
        console.log(error);
      }
    });
  return response;
  return response;
};

export let importWeeklyTimesheet = async (file: File) => {
  let postContent = new FormData();
  postContent.append("file", file);
  let response = await axiosAuth
    .post(process.env.REACT_APP_API_URL + "/openai/importWeekly", postContent, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .catch(function (error) {
      if (error.response) {
        console.log(error);
      }
    });
  return response;
  return response;
};
