import axios from "axios";
import { ITokenUser } from "./user";

export const tokenName = "timesheet-jwt";
export const companyLogo = "company-logo";

export const tokenValue = JSON.parse(localStorage.getItem(tokenName));
export const xeroAuthUrl = process.env.REACT_APP_API_URL + "/xero/init";
export const googleAuthUrl = process.env.REACT_APP_API_URL + "/google/auth";

const headers = {
  Authorization: tokenValue,
  "Content-Type": "application/json",
};

export interface BaseProps {
  onMenuToggleClick?: (openMenu: boolean) => void;
  tokenUser: ITokenUser;
}

export const axiosAuth = axios.create({
  withCredentials: true,
  headers: headers,
});
