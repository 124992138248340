import axios from "axios";
import { axiosAuth } from "./auth";
import { IUser } from "./user";
import { Moment } from "moment";
import { IComment } from "./common";

export type statusOption = "New" | "Draft" | "Sent" | "Approved" | "Closed" | "Rejected";
export type modeOption = "employee" | "manager";

export interface IDayOption {
  day: Moment;
  data: ICompliantTimesheetData;
  timesheet?: ICompliantTimesheet;
}

export interface ICompliantTimesheetData {
  day: string;
  hours: string;
  start: string;
  end: string;
  breakDuration: string;
}

export interface ICompliantTimesheet {
  Id?: number;
  StartDate: string;
  Year: string;
  Status: statusOption;
  Data: ICompliantTimesheetData[];
  UserId: number;
  Token: string;
  TotalHours: number;
  InvoiceId?: string;
  InvoiceStatus?: "DRAFT" | "SUBMITTED" | "AUTHORISED" | "DELETED" | "VOIDED";
  InvoiceSent?: boolean;
  Comments?: IComment[];
  ApprovedOn?: Date;
  Owner?: IUser;
}

export let getTimesheet = async (startDate: string, userId: number) => {
  let params = { startDate: startDate, userId: userId };
  let response: any = await axiosAuth
    .get(process.env.REACT_APP_API_URL + "/weekly/get", {
      params: params,
    })
    .catch(function (error) {
      if (error.response) {
        console.log(error.response);
      }
    });

  return response;
};

export let getById = async (id: string) => {
  let params = { id: id };

  let response: any = await axiosAuth
    .get(process.env.REACT_APP_API_URL + "/weekly/id/get", {
      params: params,
    })
    .catch(function (error) {
      if (error.response) {
        console.log(error.response);
      }
    });

  return response;
};

export let getByToken = async (token: string) => {
  let params = { token: token };

  let response: any = await axios
    .get(process.env.REACT_APP_API_URL + "/w/token/get", {
      params: params,
    })
    .catch(function (error) {
      if (error.response) {
        console.log(error.response);
      }
    });

  return response;
};

export let getChartData = async (id: number, year: string) => {
  let response: any = await axiosAuth
    .get(process.env.REACT_APP_API_URL + "/weekly/charts", {
      params: { id: id, year: year },
    })
    .catch(function (error) {
      if (error.response) {
        console.log(error.response);
      }
    });

  return response;
};

export let saveTimesheet = async (timesheet: ICompliantTimesheet) => {
  let response = await axiosAuth.post(process.env.REACT_APP_API_URL + "/weekly/save", { timesheet }).catch(function (error) {
    if (error.response) {
      console.log(error);
    }
  });
  return response;
};

export let sendForApproval = async (timesheet: ICompliantTimesheet) => {
  let response = await axiosAuth.post(process.env.REACT_APP_API_URL + "/weekly/send-for-approval", { timesheet }).catch(function (error) {
    if (error.response) {
      console.log(error);
    }
  });
  return response;
};

export let approve = async (id: number) => {
  let response = await axiosAuth.post(process.env.REACT_APP_API_URL + "/w/approve", { id }).catch(function (error) {
    if (error.response) {
      console.log(error);
    }
  });
  return response;
};

export let reject = async (id: number, comment: string, userName: string) => {
  let response = await axiosAuth.post(process.env.REACT_APP_API_URL + "/w/reject", { id, comment, userName }).catch(function (error) {
    if (error.response) {
      console.log(error);
    }
  });
  return response;
};

export let changeTimesheetStatus = async (timesheet: ICompliantTimesheet) => {
  let response = await axiosAuth.post(process.env.REACT_APP_API_URL + "/weekly/change-status", { timesheet }).catch(function (error) {
    if (error.response) {
      console.log(error);
    }
  });
  return response;
};

export let validateToken = async (id: string) => {
  let response: any = await axiosAuth
    .get(process.env.REACT_APP_API_URL + "/w/validateeSign", {
      params: { id: id },
    })
    .catch(function (error) {
      if (error.response) {
        console.log(error.response);
      }
    });

  return response;
};

export let printFromToken = async (token: string) => {
  let params = { token: token };
  let response: any = await axios
    .get(process.env.REACT_APP_API_URL + "/w/token/print", {
      params,
      responseType: "blob",
    })
    .catch(function (error) {
      if (error.response) {
        console.log(error);
      }
    });
  let filename = response.headers["download-filename"];
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.target = "_blank";

  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  return response;
};

export let print = async (startDate: string, userId: number) => {
  let params = { startDate: startDate, userId: userId };
  let response: any = await axiosAuth
    .get(process.env.REACT_APP_API_URL + "/weekly/print", {
      params,
      responseType: "blob",
    })
    .catch(function (error) {
      if (error.response) {
        console.log(error);
      }
    });
  let filename = response.headers["download-filename"];
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.target = "_blank";

  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  return response;
};
