import { useState, useEffect } from "react";
import { ICompliantTimesheet, ICompliantTimesheetData, IDayOption } from "../model/compliantTimesheet";
import * as compliantTimesheetController from "../model/compliantTimesheet";
import { useParams } from "react-router-dom";
import moment from "moment";
import { OrbitProgress } from "react-loading-indicators";
import { Logo } from "../components/logo";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Badge, Table } from "reactstrap";

export const WPrintTimesheet = () => {
  const { id, mode } = useParams();
  const [timesheet, setTimesheet] = useState<ICompliantTimesheet>();
  const [loading, setLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    initialise();
  }, []);

  const initialise = async () => {
    setLoading(true);
    let timesheet: ICompliantTimesheet;
    if (mode === "id") {
      let response = await compliantTimesheetController.getById(id);
      if (response && response.status === 200 && response.data.timesheet !== null) {
        timesheet = response.data.timesheet;
        setTimesheet(timesheet);
      }
    } else {
      let response = await compliantTimesheetController.getByToken(id);
      if (response && response.status === 200 && response.data.timesheet !== null) {
        timesheet = response.data.timesheet;
        setTimesheet(timesheet);
      }
    }
    if (!timesheet) {
      setErrorMessage("Timesheet not found");
    } else {
      setErrorMessage(null);
    }
    setLoading(false);
  };

  const getData = (timesheet: ICompliantTimesheet) => {
    let html: any[] = [];
    let data: ICompliantTimesheetData[] = JSON.parse(timesheet?.Data?.toString());
    let startDate = moment(timesheet.StartDate).startOf("isoWeek");
    let endDate = moment(timesheet.StartDate).endOf("isoWeek");
    let days: IDayOption[] = [];
    let day = startDate.clone();
    while (day <= endDate) {
      let d = data.find((x) => x?.day === day.format("D"));

      days.push({ data: d!, day: day.clone() });
      day = day.add(1, "d");
    }
    console.log(days);
    days?.map((x, i) => {
      html.push(
        <tr key={i} className="time-data">
          <td className="text-start">
            <span className="d-inline d-md-none bg-transparent p-0">{x.day.format("ddd")}</span>
            <span className="d-none d-md-inline bg-transparent">{x.day.format("dddd")}</span>
            <br />
            <Badge color="dark" className="time-badge">
              {x.day.format("DD")} <span className="d-none d-md-inline">{x.day.format("MMM YYYY")}</span>
            </Badge>
          </td>
          <td>{!x.data || x.data?.start === "0" ? "-" : x.data.start}</td>
          <td>{!x.data || x.data?.breakDuration === "0" ? "-" : x.data.breakDuration}</td>
          <td>{!x.data || x.data?.end === "0" ? "-" : x.data.end}</td>
          <td>{!x.data || x.data?.hours === "0" ? "-" : x.data.hours}</td>
        </tr>
      );
    });
    return html;
  };

  const print = async () => {
    const element = document.getElementById("print");
    const canvas = await html2canvas(element);
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("l", "mm", "a4", 1 as any);

    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    // Calculate aspect ratio to fit the image within the PDF page
    const imgWidth = canvas.width;
    const imgHeight = canvas.height;
    const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);

    const imgWidthScaled = imgWidth * ratio;
    const imgHeightScaled = imgHeight * ratio;

    // Add the image to the PDF
    pdf.addImage(imgData, "JPEG", 0, 0, imgWidthScaled, imgHeightScaled, undefined, "FAST");
    let fileName = `${timesheet?.Owner.Firstname}-${timesheet?.Owner.Lastname}-week-ending-${moment(timesheet.StartDate)
      .endOf("isoWeek")
      .format("DD-MM-YYYY")}.pdf`;
    // Save the PDF
    pdf.save(fileName);
  };

  if (loading) {
    return (
      <div className="spinner-center">
        <OrbitProgress variant="track-disc" color={"#319dcc"} speedPlus={0} easing="linear" />
      </div>
    );
  }

  if (errorMessage) {
    return (
      <div className="spinner-center">
        <div className="alert alert-danger">{errorMessage}</div>
      </div>
    );
  }

  return (
    <>
      <div id="print">
        <div className="logo">
          <div className="row">
            <div className="col-8">
              <Logo url={timesheet?.Owner?.Company?.LOGO} companyName={timesheet?.Owner?.Company?.Name} />
            </div>
            <div className="col-4 ">
              <button data-html2canvas-ignore className="btn btn-light" type="button" onClick={() => print()}>
                <i className="fas fa-cloud-download-alt me-2"></i>
                <span className="d-none d-md-inline">Download this timesheet</span>
              </button>
            </div>
          </div>
        </div>
        <div className="holder">
          <h1>
            {timesheet?.Status === "Approved" ? (
              <>
                Timesheet <span className={timesheet?.Status?.toLowerCase()}>{timesheet?.Status}</span> by {timesheet?.Owner?.ManagerName}
              </>
            ) : (
              <>
                Timesheet status: <span className={timesheet?.Status?.toLowerCase()}>{timesheet?.Status}</span>
                <br />
                Total hours: {timesheet?.TotalHours}
              </>
            )}
          </h1>
          <hr />
          {timesheet?.Status === "Approved" && (
            <Table className="mb-2 printDetails">
              <tr>
                <th>Date approved:</th>
                <td>{moment(timesheet?.ApprovedOn).format("DD MMMM YYYY")}</td>
                <th>Week ending:</th>
                <td>{moment(timesheet?.StartDate).endOf("isoWeek").format("DD MMMM YYYY")}</td>
              </tr>
              <tr>
                <th>Employee:</th>
                <td>
                  {timesheet?.Owner?.Firstname} {timesheet?.Owner?.Lastname}
                </td>
                <th>Total hours:</th>
                <td>{timesheet?.TotalHours}</td>
              </tr>
            </Table>
          )}

          <Table striped className="printTable ">
            <tr className="time">
              <th>Day</th>
              <th>Start</th>
              <th>Break</th>
              <th>End</th>
              <th>Total</th>
            </tr>
            {timesheet && getData(timesheet)}
          </Table>
        </div>
        <hr />
      </div>
    </>
  );
};
