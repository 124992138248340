import { useState, useEffect, Fragment } from "react";
import { OrbitProgress } from "react-loading-indicators";
import { BaseProps } from "../../../model/auth";

import { getPayrollCalendars, getPayrun, getPayruns, verify } from "../../../model/xero";
import { Button, Col, Collapse, Container, Row, Table } from "reactstrap";
import { ToggleMenu } from "../../../components/toggleMenu";
import { PayrollCalendar } from "xero-node/dist/gen/model/payroll-au/payrollCalendar";
import { faExternalLinkAlt, faEye, faL, faPlug, faPlus } from "@fortawesome/free-solid-svg-icons";
import { MainContent } from "../../../components/mainContent";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PayRun } from "xero-node/dist/gen/model/payroll-au/payRun";

interface PayslipControlProps {
  payRun?: PayRun;
}

export const PayslipControl = (props: PayslipControlProps) => {
  const [collapseIsOpen, setCollapseIsOpen] = useState<boolean>(false);
  const [selectedPayrun, setSelectedPayrun] = useState<PayRun>();

  useEffect(() => {}, []);

  return (
    <>
      <tr>
        <td>Ending {moment(props.payRun?.payRunPeriodEndDate).format("DD MMM YYYY")}</td>
        <td>{moment(props.payRun?.paymentDate).format("DD MMM YYYY")}</td>
        <td>${props?.payRun?.wages.toLocaleString()}</td>
        <td>${props?.payRun?.tax.toLocaleString()}</td>
        <td>${props?.payRun?._super.toLocaleString()}</td>
        <td>${props?.payRun?.netPay.toLocaleString()}</td>
        <td>
          <Button
            size="sm"
            color="dark"
            onClick={async () => {
              if (!selectedPayrun) {
                const response = await getPayrun(props.payRun?.payRunID);
                if (response && response.status === 200) {
                  setSelectedPayrun(response?.data.payRun);
                }
              }
              setCollapseIsOpen((x) => !x);
            }}
          >
            <FontAwesomeIcon icon={faExternalLinkAlt} className="me-1" />
            Show payslips
          </Button>
        </td>
      </tr>
      <tr>
        <td colSpan={7} className="p-0">
          <Collapse isOpen={collapseIsOpen}>
            <Table striped>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Earnings</th>
                  <th>Tax</th>
                  <th>Super</th>
                  <th>Net pay</th>
                </tr>
              </thead>
              <tbody>
                {selectedPayrun?.payslips?.map((x, i) => {
                  return (
                    <Fragment key={i}>
                      <tr>
                        <td>
                          {x.firstName} {x.lastName}
                        </td>
                        <td>{x.wages}</td>
                        <td>{x.tax}</td>
                        <td>{x._super}</td>
                        <td>{x.netPay}</td>
                      </tr>
                    </Fragment>
                  );
                })}
              </tbody>
            </Table>
          </Collapse>
        </td>
      </tr>
    </>
  );
};
