import { BaseProps } from "../../../model/auth";
import { TimesheetInvoice } from "./timesheetInvoice";

interface InvoiceProps extends BaseProps {}

export const Invoice = (props: InvoiceProps) => {
  return (
    <>
      <TimesheetInvoice {...props} />
    </>
  );
};
