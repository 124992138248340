import React, { useState, useEffect } from "react";
import { TimesheetControl } from "../secure/timesheet/timesheetControl";
import { ITimesheet } from "../../model/timesheet";
import * as timesheetController from "../../model/timesheet";
import { useParams } from "react-router-dom";
import { OrbitProgress } from "react-loading-indicators";
import { Alert } from "reactstrap";

export const ESign = () => {
  const { id } = useParams();
  document.title = "e-Sign | Acader";
  const [valid, setValid] = useState<boolean>(false);
  const [errorMessage, setErrorMesssage] = useState<string>("");
  const [timesheet, setTimesheet] = useState<ITimesheet>(null);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    initialise();
  }, []);

  const initialise = async () => {
    setLoading(true);
    let response: any = await timesheetController.validateToken(id);
    if (response && response.status === 200) {
      let t: ITimesheet = response.data.timesheet;
      if (t) {
        setTimesheet(t);
        setValid(true);
        setFirstName(t?.Owner?.Firstname);
        setLastName(t?.Owner?.Lastname);
      } else {
        setErrorMesssage("Timesheet not found");
      }
    } else {
      setErrorMesssage("Timesheet not found");
    }
    setLoading(false);
  };

  if (loading) {
    return (
      <div className="spinner-center">
        <OrbitProgress variant="track-disc" color={"#319dcc"} speedPlus={0} easing="linear" />
      </div>
    );
  }

  if (errorMessage !== "") {
    return <Alert color="danger">{errorMessage}</Alert>;
  }

  return <TimesheetControl mode="manager" timesheet={timesheet} firstName={firstName} lastName={lastName} tokenUser={null} onMenuToggleClick={() => {}} />;
};
