import { Organisation, Organisations } from "xero-node";
import { axiosAuth } from "./auth";
import { ITimesheet } from "./timesheet";

export interface IXeroCustomer {
  contactID: string;
  name: string;
}

export const getInvoicesUrl = async (invoiceId: string, mode: string, shortCode: string) => {
  return `https://go.xero.com/app/${shortCode}/invoicing/${mode}/${invoiceId}`;
};

export const contacts = async (id: number) => {
  let response: any = await axiosAuth.get(process.env.REACT_APP_API_URL + "/xero/admin/contacts", { params: { id } }).catch(function (error) {
    if (error.response) {
      console.log(error.response);
    }
  });

  return response;
};

export let disconnect = async () => {
  let response = await axiosAuth.post(process.env.REACT_APP_API_URL + "/xero/admin/disconnect").catch(function (error) {
    if (error.response) {
      console.log(error);
    }
  });
  return response;
};

export let approveAndSend = async (invoiceIds: string[]) => {
  let response = await axiosAuth.post(process.env.REACT_APP_API_URL + "/xero/admin/approve-send", { invoiceIds }).catch(function (error) {
    if (error.response) {
      console.log(error);
    }
  });
  return response;
};

export let verify = async () => {
  let response = await axiosAuth.get(process.env.REACT_APP_API_URL + "/xero/admin/verify", {}).catch(function (error) {
    if (error.response) {
      console.log(error);
    }
  });
  return response;
};

export let getInvoices = async (id: string, mode: string) => {
  let response = await axiosAuth.get(process.env.REACT_APP_API_URL + "/xero/admin/invoices", { params: { id, mode } }).catch(function (error) {
    if (error.response) {
      console.log(error);
    }
  });
  return response;
};

export let getPayruns = async () => {
  let response = await axiosAuth.get(process.env.REACT_APP_API_URL + "/xero/admin/payRuns").catch(function (error) {
    if (error.response) {
      console.log(error);
    }
  });
  return response;
};

export let getPayrun = async (payRunId) => {
  let response = await axiosAuth.get(process.env.REACT_APP_API_URL + "/xero/admin/payRun", { params: { payRunId } }).catch(function (error) {
    if (error.response) {
      console.log(error);
    }
  });
  return response;
};

export let getPayrollCalendars = async () => {
  let response = await axiosAuth.get(process.env.REACT_APP_API_URL + "/xero/admin/calendars").catch(function (error) {
    if (error.response) {
      console.log(error);
    }
  });
  return response;
};

export let createInvoices = async (timesheetIds: number[], date: Date, dueDate: Date, invoiceNumber: string) => {
  let response = await axiosAuth
    .post(process.env.REACT_APP_API_URL + "/xero/admin/invoices", { timesheetIds, date, dueDate, invoiceNumber }, {})
    .catch(function (error) {
      if (error.response) {
        console.log(error);
      }
    });
  return response;
};

export let createPayRun = async (payrollCalendarID: string) => {
  let response = await axiosAuth.post(process.env.REACT_APP_API_URL + "/xero/admin/payRun", { payrollCalendarID }).catch(function (error) {
    if (error.response) {
      console.log(error);
    }
  });
  return response;
};

export let syncEmployee = async (email: string, id: number) => {
  let response = await axiosAuth.post(process.env.REACT_APP_API_URL + "/xero/admin/syncEmployee", { email, id }).catch(function (error) {
    if (error.response) {
      console.log(error);
    }
  });
  return response;
};
