import { useState, useEffect } from "react";
import { ITimesheet } from "../../../model/timesheet";
import { Alert, Button, Col, FormGroup, Input, Label, PopoverBody, PopoverHeader, Row, Table, UncontrolledPopover } from "reactstrap";
import { IUser } from "../../../model/user";
import moment from "moment";
import { useParams } from "react-router-dom";

import { createInvoices } from "../../../model/xero";
import DatePicker from "react-datepicker";
import enAu from "date-fns/locale/en-AU";

interface TimesheetUserInvoiceProps {
  user: IUser;
  onCreateClicked: (loading: boolean) => void;
}

export const TimesheetUserInvoice = (props: TimesheetUserInvoiceProps) => {
  const { id } = useParams();
  const [selectedTimesheets, setSelectedTimesheets] = useState<ITimesheet[]>([]);
  const [date, setDate] = useState<Date>(new Date());
  const [dueDate, setDueDate] = useState<Date>();
  const [invoiceNumber, setInvoiceNumber] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    setErrorMessage(null);
    initialise();
  }, []);

  const createDraftInvoices = async () => {
    try {
      props.onCreateClicked(true);
      let timesheetIds = selectedTimesheets.map((x) => x.Id);

      const response: any = await createInvoices(timesheetIds, date, dueDate, invoiceNumber);
      if (!response?.data?.success) {
        setErrorMessage(response.data.error);
      } else {
        setErrorMessage(null);
        initialise();
        props.onCreateClicked(false);
      }
    } finally {
      props.onCreateClicked(false);
    }
  };

  const initialise = async () => {
    let invoiceNumber = `${props.user?.InvoiceNumberPrefix}${moment(new Date()).format("DDMMYYYY")}`;
    setInvoiceNumber(invoiceNumber);
    let dueDate = moment(new Date())
      .add(props.user?.DaysFromInvoice || 15, "d")
      .toDate();

    setDueDate(dueDate);
  };

  return (
    <>
      {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
      {props.user?.Timesheets?.length > 0 ? (
        <>
          <h2>
            {props.user?.Firstname} {props.user?.Lastname}
          </h2>
          <hr />
          <Row className="bg-light p-2">
            <Col md={2}>
              <FormGroup>
                <Label>Invoice date</Label>
                <DatePicker
                  id="dtDeadline"
                  autoComplete="off"
                  className="form-control"
                  dateFormat="dd-MM-yyyy"
                  locale={enAu as any}
                  selected={date}
                  onChange={(date: Date) => {
                    setDate(date);
                  }}
                />
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                <Label>Due date</Label>
                <DatePicker
                  id="dtDeadline"
                  autoComplete="off"
                  className="form-control"
                  dateFormat="dd-MM-yyyy"
                  locale={enAu as any}
                  selected={dueDate}
                  onChange={(date: Date) => {
                    setDueDate(date);
                  }}
                />
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                <Label>Invoice number</Label>
                <Input
                  value={invoiceNumber}
                  onChange={(e) => {
                    e.persist();
                    setInvoiceNumber(e.target.value);
                  }}
                ></Input>
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                <Label>Account code</Label>
                <Input disabled value={props.user?.XeroAccountCode}></Input>
              </FormGroup>
            </Col>
          </Row>
          <Table striped>
            <thead>
              <tr>
                <th></th>
                <th>Period</th>
                <th>Status</th>
                <th>Approved by</th>
                <th>Approved on</th>
                <th>Total hours</th>
                <th>Attachments</th>
              </tr>
            </thead>
            <tbody>
              {props.user?.Timesheets?.map((x: ITimesheet, i) => {
                return (
                  <tr key={i}>
                    <td>
                      <Input
                        type="checkbox"
                        onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                          if (e.currentTarget.checked) {
                            let selected = [...selectedTimesheets];
                            selected.push(x as ITimesheet);
                            setSelectedTimesheets(selected);
                          } else {
                            let selected = (selectedTimesheets as ITimesheet[])?.filter((y) => y.Id !== x.Id);
                            setSelectedTimesheets(selected);
                          }
                        }}
                      />
                    </td>
                    <td>
                      <>
                        {(x as ITimesheet).Month}-{x.Year}
                      </>
                    </td>
                    <td>{x.Status}</td>
                    <td>{props.user?.ManagerName}</td>
                    <td>{moment(x.ApprovedOn).format("DD MMM YYYY")}</td>
                    <td>{x.TotalHours}</td>
                    <th>
                      {x.TimesheetAttachments?.length > 0 ? (
                        <>
                          <Button id="attachmentButton" type="button" onClick={(e: any) => e.target.focus()}>
                            {x.TimesheetAttachments?.length}
                          </Button>
                          <UncontrolledPopover placement="auto" target="attachmentButton" trigger="focus">
                            <PopoverHeader>Attachments</PopoverHeader>
                            <PopoverBody>
                              {x.TimesheetAttachments?.map((y, i) => {
                                return <p key={i}>{y.Name}</p>;
                              })}
                            </PopoverBody>
                          </UncontrolledPopover>
                        </>
                      ) : (
                        <>No attachments</>
                      )}
                    </th>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <Row>
            <Col className="text-end">
              <Button
                color="acader"
                disabled={selectedTimesheets?.length === 0}
                onClick={async () => {
                  await createDraftInvoices();
                }}
              >
                Create Draft Xero invoice
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        <Alert color="warning" className=" mt-2">
          No approved timesheet found
        </Alert>
      )}
    </>
  );
};
