import { useEffect, useState } from "react";
import { ITimesheet } from "../../model/timesheet";
import * as timesheetController from "../../model/timesheet";
import * as compliantTimesheetController from "../../model/compliantTimesheet";
import * as userController from "../../model/user";

import moment from "moment";
import { IUser } from "../../model/user";
import { ICompliantTimesheet } from "../../model/compliantTimesheet";
import { Chart } from "react-google-charts";
import { BaseProps } from "../../model/auth";
import { MainContent } from "../../components/mainContent";
import { Button, Col, Container, Row } from "reactstrap";
import { BreadcrumbControl } from "../../components/breadcrumb";
import { ToggleMenu } from "../../components/toggleMenu";

interface ChartsProps extends BaseProps {}

export const Charts = (props: ChartsProps) => {
  document.title = "Charts";
  const [timesheets, setTimesheets] = useState<ITimesheet[]>([]);
  const [compliantTimesheets, setCompliantTimesheets] = useState<ICompliantTimesheet[]>([]);
  const [currentYear, setCurrentYear] = useState<string>(moment().format("YYYY"));
  const [user, setUser] = useState<IUser>();
  const [chartData, setChartData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (props.tokenUser) {
      initialise();
    }
  }, [props.tokenUser]);

  useEffect(() => {
    if (user) {
      if (user?.Mode === "Monthly") {
        initialiseTimesheet();
      } else {
        initialiseWeekly();
      }
    }
  }, [currentYear]);

  useEffect(() => {
    initialiseChart();
  }, [timesheets, compliantTimesheets?.length]);

  const initialiseChart = () => {
    let data: any[] = [["Months", "Hours"]];
    if (timesheets?.length > 0) {
      moment.months().map((x) => {
        let timesheet = timesheets?.find((y) => y.Month === x);
        data.push([x, timesheet ? timesheet.TotalHours : 0]);
      });
    } else if (compliantTimesheets?.length > 0) {
      moment.months().map((x) => {
        let timesheets = compliantTimesheets?.filter((y) => y.StartDate.indexOf(x) !== -1);
        let hours = 0;
        timesheets?.map((y) => {
          hours += y.TotalHours ? y.TotalHours : 0;
        });
        data.push([x, hours]);
      });
    }
    if (data?.length === 1) {
      moment.months().map((x) => {
        data.push([x, 0]);
      });
    }
    setChartData(data);
  };

  const initialise = async () => {
    let response: any = await userController.getUser(props.tokenUser?.Id?.toString());
    if (response && response.status === 200) {
      let u: IUser = response.data.user;
      setUser(u);
      if (u?.Mode === "Monthly") {
        initialiseTimesheet();
      } else {
        initialiseWeekly();
      }
    }
  };

  const initialiseTimesheet = async () => {
    try {
      setLoading(true);
      let response: any = await timesheetController.getChartData(props.tokenUser?.Id, currentYear);
      if (response && response.status === 200) {
        let timesheets: ITimesheet[] = response.data.timesheets;
        setTimesheets(timesheets);
        setLoading(false);
      }
    } finally {
      setLoading(false);
    }
  };

  const initialiseWeekly = async () => {
    setLoading(true);
    let response: any = await compliantTimesheetController.getChartData(props.tokenUser?.Id, currentYear);
    if (response && response.status === 200) {
      let timesheets: ICompliantTimesheet[] = response.data.timesheets;

      setCompliantTimesheets(timesheets);
      setLoading(false);
    }
    setLoading(false);
  };

  const options = {
    title: `Timesheet overview of ${currentYear}`,
    chartArea: { width: "90%" },
    bar: { groupWidth: "95%" },
    hAxis: {
      title: "Months",
    },
    vAxis: {
      title: "Hours",
      minValue: 0,
    },
    legend: { position: "top" },
  };

  const previous = () => {
    let currentYearInt: number = parseInt(currentYear);
    currentYearInt--;
    let cy: string = currentYearInt.toString();
    setCurrentYear(cy);
  };

  const next = () => {
    let currentYearInt: number = parseInt(currentYear);
    currentYearInt++;
    let cy: string = currentYearInt.toString();
    setCurrentYear(cy);
  };

  return (
    <>
      <div className="theme-primary">
        <Container>
          <Row>
            <Col xs={10}>
              <h1>Charts</h1>
              <div className="h1Divider"></div>
            </Col>
            <Col xs={2} className="text-end">
              <ToggleMenu onClick={(toggle) => props.onMenuToggleClick(toggle)} />
            </Col>
          </Row>
        </Container>
      </div>
      <MainContent loading={loading}>
        <BreadcrumbControl items={[{ name: "Timesheet", link: "/timesheet" }, { name: "Charts" }]} />
        <div className="theme-white ">
          <Container>
            <Row>
              <Col>
                <Row>
                  <Col>
                    <Button id="previous" color="acader" onClick={() => previous()}>
                      <i className="fas fa-chevron-left me-2"></i>
                      <span>Previous</span>
                    </Button>
                  </Col>
                  <Col id="next" className="text-end">
                    <Button color="acader" onClick={() => next()}>
                      <span>Next</span>
                      <i className="fas fa-chevron-right ms-2"></i>
                    </Button>
                  </Col>
                </Row>
                <Chart chartType="ColumnChart" width="100%" height="400px" data={chartData} options={options} />
              </Col>
            </Row>
          </Container>
        </div>
      </MainContent>
    </>
  );
};
