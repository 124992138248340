import { object, string } from "yup";
import { axiosAuth } from "./auth";

export const variableSchema = object<ISystemVariable>({
  Value: string().trim().label("Value").required(),
});

export interface ISystemVariable {
  Id?: string;
  Name?: "PayrollTax" | "SuperAnnuation" | "GST";
  Value?: string;
}

export const create = async (variable: ISystemVariable) => {
  let response = await axiosAuth.post(process.env.REACT_APP_API_URL + "/variable/create", { variable }).catch(function (error) {
    if (error.response) {
      console.log(error);
    }
  });
  return response;
};

export const update = async (variable: ISystemVariable) => {
  let response = await axiosAuth.post(process.env.REACT_APP_API_URL + "/variable/update", { variable }).catch(function (error) {
    if (error.response) {
      console.log(error);
    }
  });
  return response;
};

export let get = async (id: string) => {
  let response: any = await axiosAuth
    .get(process.env.REACT_APP_API_URL + "/variable/get", {
      params: { id: id },
    })
    .catch(function (error) {
      if (error.response) {
        console.log(error.response);
      }
    });

  return response;
};

export let list = async () => {
  let response: any = await axiosAuth.get(process.env.REACT_APP_API_URL + "/variable/list").catch(function (error) {
    if (error.response) {
      console.log(error.response);
    }
  });

  return response;
};
