import { companyLogo } from "../model/auth";

interface LogoProps {
  url: string;
  companyName: string;
}

export const Logo = (props: LogoProps) => {
  return <img alt={props.companyName} src={props.url} width="100" height="30" />;
};
