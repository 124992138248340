import { useState, useEffect, Fragment } from "react";
import moment, { Moment } from "moment";
import { ITimesheet, ITimesheetData, reject } from "../../../model/timesheet";
import * as timesheetController from "../../../model/timesheet";
import { BaseProps } from "../../../model/auth";
import { Alert, Badge, Button, Col, Container, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from "reactstrap";
import { MainContent } from "../../../components/mainContent";
import { useDropzone } from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCloudDownload, faCloudUpload, faExternalLinkAlt, faFileImport, faFileInvoice, faTimes } from "@fortawesome/free-solid-svg-icons";
import { importTimesheet } from "../../../model/openai";
import { ChatCompletion } from "openai/resources";
import { useNavigate } from "react-router-dom";
import { commentSchema, IComment } from "../../../model/common";
import { ToggleMenu } from "../../../components/toggleMenu";
import { PrintControl } from "../../../components/print/printControl";
import { deleteOne, download, uploadTimesheet, uploadTimesheetFromFile } from "../../../model/attachment";
import { getInvoicesUrl } from "../../../model/xero";

interface TimesheetControlProps extends BaseProps {
  mode: timesheetController.modeOption;
  timesheet?: ITimesheet;
  firstName?: string;
  lastName?: string;
  managerName?: string;
  userId?: number;
}

export const TimesheetControl = (props: TimesheetControlProps) => {
  const navigate = useNavigate();

  document.title = "Timesheet | Acader";
  const [dateObject, setDateObject] = useState<Moment>(moment());
  const [saved, setSaved] = useState<boolean>(false);
  const [timesheet, setTimesheet] = useState<ITimesheet>();
  const [totalHours, setTotalHours] = useState<number>(0);
  const [successMessageContent, setSuccessMessageContent] = useState<string>("");
  const [userId, setUserId] = useState<number>(props.userId || props.tokenUser?.Id);
  const [loading, setLoading] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showRejectModal, setShowRejectModal] = useState<boolean>(false);
  const [importFile, setImportFile] = useState<File>();
  const [importComplete, setImportComplete] = useState<boolean>(false);
  const [importedTimesheetError, setImportedTimesheetError] = useState<string>();
  const [rejectComment, setRejectComment] = useState<string>();
  const [rejectComentError, setRejectCommentError] = useState<string>();

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop: (files) => setImportFile(files?.at(0)),
    accept: {
      "application/pdf": [".pdf"],
    },
    multiple: false,
  });

  useEffect(() => {
    setImportFile(null);
    populateData();
  }, [dateObject]);

  useEffect(() => {
    if (timesheet) {
      setImportComplete(false);
      setImportedTimesheetError(null);
      initialise();
    }
  }, [timesheet]);

  useEffect(() => {
    if (saved) {
      setTimeout(() => {
        setSaved(false);
        setSuccessMessageContent("");
      }, 1000);
    }
  }, [saved]);

  const initialise = () => {
    let t = timesheet;
    let dObject = dateObject.clone();
    if (props.timesheet) {
      dObject = moment("01/" + props.timesheet.Month + "/" + props.timesheet.Year, "DD/MMMM/YYYY", true);
    }

    let numberOfDays: number = moment(dObject).daysInMonth();
    if (t && !t?.Data) {
      let data: ITimesheetData[] = [];
      for (let i = 1; i <= numberOfDays; i++) {
        data.push({ day: i.toString(), hours: "" });
      }

      t.Data = data;
    }
    let totalHours: number = 0;
    t?.Data?.map((x) => {
      totalHours += x.hours && x.hours !== "" ? parseFloat(x.hours) : 0;
    });
    setTotalHours(totalHours);
  };

  const isReadOnly = (timesheet: ITimesheet) => {
    if (timesheet.Status === "Approved" || timesheet.Status === "Sent" || props.mode === "manager" || timesheet.Status === "Closed") {
      return true;
    } else {
      return false;
    }
  };

  const getDaysHtml = () => {
    let li: any[] = [];
    moment.weekdaysShort().map((d, i) => {
      li.push(<li>{d}</li>);
    });
    return li;
  };

  const getData = (timesheet: ITimesheet) => {
    let dObject = moment("01/" + timesheet?.Month + "/" + timesheet?.Year, "DD/MMMM/YYYY", true);
    let numberOfDays: number = moment(dObject).daysInMonth();
    let data: ITimesheetData[] = [];
    if (Array.isArray(timesheet?.Data)) {
      data = timesheet?.Data;
    } else {
      data = JSON.parse(timesheet?.Data as string);
    }

    let allDaysInMonth: any[] = [];
    for (let i = 0; i < numberOfDays; i++) {
      allDaysInMonth.push(
        <li key={i} style={{ textAlign: "center" }}>
          <Badge color="dark" className="time-badge">
            <span>{i + 1}</span>{" "}
            <span className="d-none d-md-inline">
              {dObject.format("MMMM")} {dObject.format("YYYY")}
            </span>
          </Badge>
          <br />
          <br />
          {isReadOnly(timesheet) ? (
            <b style={{ fontSize: "large" }}>{data?.find((x) => x.day === (i + 1).toString())?.hours || "-"}</b>
          ) : (
            <>
              <input
                id={(i + 1).toString()}
                value={timesheet?.Data?.find((x) => x.day === (i + 1).toString())?.hours || ""}
                className="form-control hours"
                type="number"
                step="any"
                maxLength={2}
                name={(i + 1).toString()}
                disabled={isReadOnly(timesheet)}
                onChange={(e) => {
                  let t = timesheet;
                  let d = t?.Data?.find((x) => x?.day === (i + 1).toString());
                  if (d) {
                    d.hours = e.currentTarget.value;
                    setTimesheet(t);
                    initialise();
                  }
                }}
              ></input>
            </>
          )}
        </li>
      );
    }

    let firstDay: string = moment(dObject).startOf("month").format("d");
    let blanks: any[] = [];
    for (let i = 0; i < parseInt(firstDay); i++) {
      blanks.push(
        <li key={i + numberOfDays}>
          <Badge className="" style={{ visibility: "hidden" }}>
            -
          </Badge>
          <br />
          <br />
          <b style={{ visibility: "hidden" }}>"-"</b>
        </li>
      );
    }
    let totalSlots: any[] = [...blanks, ...allDaysInMonth];
    return totalSlots;
  };

  const populateData = async () => {
    setLoading(true);
    let response: any;
    let timesheet: ITimesheet = null;
    if (props.mode === "manager" && !props.timesheet) {
      return;
    }
    if (props.timesheet) {
      timesheet = props.timesheet;
    } else {
      response = await timesheetController.getTimesheet(dateObject.format("MMMM"), dateObject.format("YYYY"), userId);
      if (response && response.status === 200 && response.data.timesheet !== null) {
        timesheet = response.data.timesheet;
      }
    }

    if (timesheet) {
      let data = JSON.parse(timesheet.Data.toString());
      timesheet.Data = data;
      if (timesheet.Comments) {
        let comments: IComment[] = JSON.parse(timesheet.Comments?.toString());
        timesheet.Comments = comments;
      }
    } else {
      timesheet = {
        Data: null,
        Status: "New",
        Month: dateObject.format("MMMM"),
        Year: dateObject.format("YYYY"),
        UserId: userId,
        Token: "",
        TotalHours: 0,
      };
    }
    setLoading(false);
    setTimesheet(timesheet);
    return timesheet;
  };

  const saveTimesheet = async () => {
    try {
      setSaved(true);
      let totalHours = 0;
      let t = timesheet;
      t?.Data?.map((x) => {
        totalHours += x.hours && x.hours !== "" ? parseFloat(x.hours) : 0;
      });
      t.TotalHours = totalHours;
      t.Status = "Draft";

      let response: any = await timesheetController.saveTimesheet(t);
      if (response && response.status === 200) {
        let successMessageContent: string = "Timesheet saved successfully!";
        setSuccessMessageContent(successMessageContent);
        setSaved(false);
        if (t.Id == null) {
          await populateData();
        } else {
          setTimesheet(t);
          setTotalHours(totalHours);
        }
      }
    } finally {
      setSaved(false);
    }
  };

  const getStatusClass = () => {
    let className: string = "";
    switch (timesheet.Status) {
      case "New":
        className = "secondary";
        break;
      case "Draft":
        className = "warning";
        break;
      case "Sent":
        className = "info";
        break;
      case "Approved":
        className = "success";
        break;
      case "Rejected":
        className = "danger";
        break;
      case "Closed":
        className = "dark";
        break;
    }
    return className;
  };

  const previous = () => {
    let dObject: Moment = dateObject.clone().subtract(1, "M");
    setDateObject(dObject);
  };

  const next = () => {
    let dObject: Moment = dateObject.clone().add(1, "M");
    setDateObject(dObject);
  };

  const sendForApproval = async () => {
    try {
      await saveTimesheet();
      setLoading(true);
      let t: ITimesheet = timesheet;
      t.Status = "Sent";
      let response: any = await timesheetController.sendForApproval(t);
      if (response && response.status === 200) {
        let successMessageContent: string = "Timesheet sent for approval!";
        setTimesheet(t);
        setSuccessMessageContent(successMessageContent);
      }
      initialise();
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
      setShowModal(false);
    }
  };

  const rejectTimesheet = async () => {
    try {
      setRejectCommentError(null);
      await commentSchema.validateSync(rejectComment, {
        abortEarly: false,
      });
      setSaved(true);
      let t: ITimesheet = timesheet;
      t.Status = "Rejected";
      let response: any = await reject(timesheet?.Id, rejectComment, timesheet?.Owner?.ManagerName);
      if (response && response.status === 200) {
        setTimesheet(t);
        setShowRejectModal(false);
        setSaved(false);
      }
      initialise();
    } catch (err) {
      setRejectCommentError(err.message);
    } finally {
      setSaved(false);
    }
  };

  const approve = async () => {
    try {
      let t: ITimesheet = timesheet;
      t.Status = "Approved";

      setSaved(false);

      let response: any = await timesheetController.approve(t.Id);
      if (response && response.status === 200) {
        let successMessageContent: string = "Timesheet has been approved!";
        setTimesheet(t);
        setSuccessMessageContent(successMessageContent);
        setSaved(true);
        t.ApprovedOn = new Date();
        await uploadTimesheet(t);
      }
    } catch (e) {
      console.log(e);
      setSaved(true);
    }
  };

  const closeTimesheet = async () => {
    let t = timesheet;
    t.Status = "Closed";
    let response: any = await timesheetController.changeTimesheetStatus(t);
    if (response && response.status === 200) {
      setTimesheet(t);
      setSuccessMessageContent("This timesheet is now closed");
      setSaved(true);
    }
  };

  const footerActions = () => {
    let actions: any;
    switch (timesheet.Status) {
      case "New":
        if (props.mode === "employee") {
          actions = (
            <>
              <Button
                id="btn-new-save"
                color="acader"
                className="mt-3"
                onClick={async () => {
                  await saveTimesheet();
                }}
              >
                <i className="fas fa-save me-1"></i>
                Save
              </Button>
            </>
          );
        } else {
          actions = <></>;
        }
        break;
      case "Draft":
      case "Rejected":
        if (props.mode === "employee") {
          actions = (
            <>
              <Button className="btn btn-success mt-3 me-3" onClick={() => setShowModal(true)}>
                <i className="fas fa-paper-plane me-1"></i>
                Send for approval
              </Button>
              <Button id="btn-draft-save" color="acader" className="mt-3" onClick={async () => await saveTimesheet()}>
                <i className="fas fa-save me-1"></i>
                Save
              </Button>
            </>
          );
        } else {
          actions = <></>;
        }
        break;
      case "Sent":
        if (props.mode === "employee") {
          actions = <></>;
        } else {
          actions = (
            <Row className="mt-3">
              <Col md={8}></Col>
              <Col md={4} className="text-end">
                <Button color="success" onClick={async () => await approve()}>
                  <FontAwesomeIcon icon={faCheck} className={"me-2"}></FontAwesomeIcon>
                  Approve
                </Button>
                <Button color="danger" onClick={() => setShowRejectModal(true)} className="ms-2">
                  <FontAwesomeIcon icon={faTimes} className={"me-2"}></FontAwesomeIcon>
                  Reject
                </Button>
              </Col>
            </Row>
          );
        }
        break;
      case "Approved":
        if (props.mode === "manager") {
          actions = (
            <>
              <a
                href={`/print/token/${timesheet?.Token}`}
                className="btn btn-acader"
                role="Button"
                aria-pressed="true"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fas fa-file-pdf me-2"></i>
                Print view
                <i className="fas fa-external-link-alt ms-2"></i>
              </a>
            </>
          );
        } else if (props.mode === "employee") {
          actions = (
            <>
              <a href={`/print/id/${timesheet?.Id}`} className="btn btn-acader" role="Button" aria-pressed="true" target="_blank" rel="noopener noreferrer">
                <i className="fas fa-file-pdf me-2"></i>
                Print view
                <i className="fas fa-external-link-alt ms-2"></i>
              </a>
            </>
          );
        }
        break;
      case "Closed":
        actions = (
          <>
            <a href={`/print/token/${timesheet?.Token}`} className="btn btn-acader" role="Button" aria-pressed="true" target="_blank" rel="noopener noreferrer">
              <i className="fas fa-file-pdf me-2"></i>
              Print view
              <i className="fas fa-external-link-alt ms-2"></i>
            </a>
          </>
        );

        break;
    }
    return actions;
  };

  const getHeading = () => {
    let heading: any;
    if (props.mode === "employee") {
      heading = (
        <>
          <h1>
            Timesheet: {dateObject.format("MMMM YYYY")}
            <span id="status-label" className={` ms-2 badge bg-${getStatusClass()}`}>
              {timesheet.Status}
            </span>
          </h1>
          {timesheet?.Status === "Rejected" && (
            <Alert color="warning">
              <h2>Comments</h2>

              {timesheet?.Comments?.map((x, i) => {
                return (
                  <Fragment key={i}>
                    <p>
                      {x.userName}: "{x.comment}""
                    </p>
                  </Fragment>
                );
              })}
            </Alert>
          )}
        </>
      );
    } else {
      if (timesheet.Status === "Sent") {
        heading = (
          <>
            <h1>
              Please review timesheet for {props.firstName} {props.lastName}: {timesheet.Month + " " + timesheet.Year}
            </h1>
          </>
        );
      } else if (timesheet.Status === "Approved" || timesheet.Status === "Closed" || timesheet.Status === "Rejected") {
        heading = (
          <>
            <h1>
              Timesheet for {props.firstName} {props.lastName}: {timesheet.Month + " " + timesheet.Year}
              <span className={` ms-2 badge bg-${getStatusClass()}`}>{timesheet.Status}</span>
            </h1>
          </>
        );
      }
    }
    return heading;
  };

  return (
    <>
      <div className="theme-primary home">
        <Container>
          <Row>
            <Col xs={10}>
              {timesheet && getHeading()}
              <div className="h1Divider"></div>
            </Col>
            <Col xs={2} className="text-end">
              {props.mode !== "manager" && <ToggleMenu onClick={(toggle) => props.onMenuToggleClick(toggle)} />}
            </Col>
          </Row>
        </Container>
      </div>
      <MainContent loading={loading} saved={saved}>
        <Input id="timesheet-id" type="hidden" value={timesheet?.Id} />
        <Modal fade id="confirmModal" isOpen={showModal} toggle={() => setShowModal((x) => (x = !x))}>
          <ModalHeader>
            <h5 className="modal-title" id="confirmSendModal">
              Confirm Send
            </h5>
          </ModalHeader>
          <ModalBody>
            <p>Are you sure you want to send your timesheet for approval?</p>
            <p>Press 'OK' to send now or 'Cancel' to return</p>
          </ModalBody>
          <ModalFooter>
            <Button className="btn btn-dark" onClick={() => setShowModal(false)}>
              Cancel
            </Button>
            <Button id="confirm-send" className="btn btn-info" data-dismiss="modal" onClick={async () => await sendForApproval()}>
              <i className="fas fa-paper-plane me-1"></i>
              OK
            </Button>
          </ModalFooter>
        </Modal>

        <Modal fade id="confirmRejectModal" isOpen={showRejectModal} toggle={() => setShowRejectModal((x) => (x = !x))}>
          <ModalHeader>
            <h5 className="modal-title" id="confirmSendModal">
              Reject timesheet
            </h5>
          </ModalHeader>
          <ModalBody>
            <p>Please provide reason:</p>
            <Input
              type="textarea"
              onChange={(e) => {
                e.persist();
                setRejectComment(e.target.value);
              }}
            ></Input>
            {rejectComentError && <Label className="text-danger">{rejectComentError}</Label>}
          </ModalBody>
          <ModalFooter>
            <Button color="dark" onClick={() => setShowRejectModal(false)}>
              Cancel
            </Button>
            <Button color="danger" id="confirm-reject" onClick={async () => rejectTimesheet()}>
              <FontAwesomeIcon icon={faTimes} className="me-2"></FontAwesomeIcon>
              Reject
            </Button>
          </ModalFooter>
        </Modal>

        <div className="theme theme-white">
          <Container>
            <Row>
              <Col>
                {timesheet && props.mode === "employee" ? (
                  <Row className="mb-2">
                    <Col>
                      <Button id="previous" color="acader" onClick={() => previous()}>
                        <i className="fas fa-chevron-left me-2"></i>
                        <span>Previous</span>
                      </Button>
                    </Col>
                    <Col className="text-end">
                      <Button id="next" color="acader" onClick={() => next()}>
                        <span>Next</span>
                        <i className="fas fa-chevron-right ms-2"></i>
                      </Button>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </Container>

          <ul className="weekdays">{getDaysHtml()}</ul>
          <ul className="days">{timesheet && getData(timesheet)}</ul>
          <Container>
            <div className="text-end">
              <h2>
                <span className="badge bg-dark">
                  Total hours: <span id="total-hours-label">{totalHours}</span>
                </span>
              </h2>
            </div>
            <div className="row">
              <div className="col-12 text-end">{timesheet && footerActions()}</div>
            </div>
          </Container>
          {props.tokenUser?.Roles?.find((x) => x === "PayrollAdmin" || x === "SuperAdmin") &&
            timesheet?.Status !== "Sent" &&
            timesheet?.TimesheetAttachments?.length === 0 && (
              <Container>
                {importedTimesheetError && <Alert color="danger">{importedTimesheetError}</Alert>}
                <Row>
                  <Col></Col>
                </Row>
                <Row>
                  <Label>Import pdf timesheet</Label>
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <p>Drag 'n' Drop timesheet pdf or image, or click to select a file</p>
                  </div>
                </Row>
                {importFile && (
                  <>
                    <br />
                    <Row className="mt-2">
                      <Col>{importFile.name}</Col>
                      <Col>
                        {timesheet?.Status === "Draft" && (
                          <Button
                            color="info"
                            onClick={async () => {
                              setImportedTimesheetError(null);
                              setLoading(true);
                              let response: any = await importTimesheet(importFile);
                              const chatChoices: ChatCompletion.Choice[] = response.data;

                              const choice = chatChoices.find((x) => x.message?.role === "assistant");
                              const importedTimesheet: ITimesheet = JSON.parse(choice.message.content);
                              console.log(importedTimesheet);
                              if (importedTimesheet.Month !== timesheet.Month) {
                                setImportedTimesheetError(
                                  `Imported timesheet is for the month of ${importedTimesheet.Month} however the current timesheet is for ${timesheet.Month}. Import failed.`
                                );
                                setLoading(false);
                                return;
                              }

                              if (importedTimesheet.Year !== timesheet.Year) {
                                setImportedTimesheetError(
                                  `Imported timesheet is for the year ${importedTimesheet.Year} however the current timesheet is year ${timesheet.Year}. Import failed.`
                                );
                                setLoading(false);
                                return;
                              }

                              if (importedTimesheet.Status !== "Approved") {
                                setImportedTimesheetError(`The imported timesheet is not approved`);
                                setLoading(false);
                                return;
                              }

                              importedTimesheet.Status = timesheet?.Status || "New";
                              importedTimesheet.UserId = userId || props.tokenUser?.Id;
                              let t = timesheet;
                              t.Data = importedTimesheet.Data;
                              t.TotalHours = importedTimesheet.TotalHours;
                              setTotalHours(importedTimesheet.TotalHours);
                              setTimesheet(t);
                              setImportComplete(true);
                              setLoading(false);
                            }}
                          >
                            <FontAwesomeIcon icon={faFileImport} className="me-2"></FontAwesomeIcon>
                            Import approved timesheet
                          </Button>
                        )}
                        {importFile && timesheet?.Status !== "Approved" && timesheet?.TotalHours !== 0 && (
                          <Button
                            className="ms-2"
                            color="success"
                            onClick={async () => {
                              setLoading(true);
                              const t = timesheet;
                              t.Status = "Approved";
                              await timesheetController.changeTimesheetStatus(t);
                              await uploadTimesheetFromFile(t, importFile);
                              await populateData();
                              setLoading(false);
                            }}
                          >
                            <FontAwesomeIcon icon={faCheck} className="me-2"></FontAwesomeIcon>Mark as approved and upload
                          </Button>
                        )}

                        {importFile && timesheet?.Status === "Approved" && (
                          <Button
                            className="ms-2"
                            color="success"
                            onClick={async () => {
                              setLoading(true);
                              const t = timesheet;
                              t.Status = "Approved";
                              await uploadTimesheetFromFile(t, importFile);
                              await populateData();

                              setLoading(false);
                            }}
                          >
                            <FontAwesomeIcon icon={faCloudUpload} className="me-2"></FontAwesomeIcon>Upload
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </>
                )}
              </Container>
            )}
          {props.tokenUser?.Roles?.find((x) => x === "PayrollAdmin" || x === "SuperAdmin") &&
            timesheet?.TimesheetAttachments?.map((x, i) => {
              return (
                <Fragment key={i}>
                  <hr />
                  <Row>
                    <Col xs={4} md={6}>
                      {x.Name}
                    </Col>
                    <Col xs={8} md={6} className="text-end">
                      <Button
                        title="Delete"
                        color="danger"
                        className="me-2"
                        onClick={async () => {
                          await deleteOne(x.Id);
                          setImportFile(null);
                          await populateData();
                        }}
                      >
                        <FontAwesomeIcon icon={faFileInvoice} className="me-2"></FontAwesomeIcon>
                        <span className="d-none d-md-inline">Delete</span>
                      </Button>

                      <Button
                        title="Download"
                        color="acader"
                        className="me-2"
                        onClick={async () => {
                          await download(x.Id);
                        }}
                      >
                        <FontAwesomeIcon icon={faCloudDownload} className="me-2"></FontAwesomeIcon>
                        <span className="d-none d-md-inline">Download</span>
                      </Button>

                      {props.tokenUser?.Source === "Xero" &&
                        (timesheet?.InvoiceId && timesheet?.InvoiceId !== "0" ? (
                          <Button
                            title="View invoice"
                            color="acader"
                            onClick={async () => {
                              window.open(
                                await getInvoicesUrl(timesheet?.InvoiceId, timesheet?.InvoiceSent ? "view" : "edit", props.tokenUser?.Company?.XeroShortCode),
                                "_blank",
                                "rel=noopener noreferrer"
                              );
                            }}
                          >
                            <FontAwesomeIcon icon={faExternalLinkAlt} className="me-2"></FontAwesomeIcon>
                            <span className="d-none d-md-inline">Go to xero invoice</span>
                          </Button>
                        ) : (
                          <Button
                            title="Create invoice"
                            color="acader"
                            onClick={() => {
                              navigate("/timesheet/admin/invoices/" + userId);
                            }}
                          >
                            <FontAwesomeIcon icon={faFileInvoice} className="me-2"></FontAwesomeIcon>
                            <span className="d-none d-md-inline">Create invoice</span>
                          </Button>
                        ))}
                    </Col>
                  </Row>
                </Fragment>
              );
            })}
        </div>
        {timesheet?.Token && <PrintControl hidden={true} id={timesheet?.Token} mode="token" />}
      </MainContent>
    </>
  );
};
