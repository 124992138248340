import { Route, Routes } from "react-router-dom";

import HomePage from "../Pages/public/home";
import PublicHoliday from "../Pages/public/publicHoliday";
import ContractorPayCalculator from "../Pages/public/contractorpaycalculator";

import { LoginPage } from "../Pages/public/login";
import { ForgotPasswordPage } from "../Pages/public/forgotPassword";

import NotFoundPage from "../Pages/public/notfound";
import { Timesheet } from "../Pages/secure/timesheet/timesheet";

import { ESign } from "../Pages/public/esign";
import { WESign } from "../Pages/public/wesign";

import { ResetPasswordPage } from "../Pages/public/resetPassword";
import { Charts } from "../Pages/secure/charts";
import TaxCalculator from "../Pages/public/taxCalculator";
import ReferralPage from "../Pages/public/referral";
import SupportPage from "../Pages/public/support";
import PayrollPage from "../Pages/public/payroll";
import { UsersPage } from "../Pages/secure/user/users";
import { AdminTimesheet } from "../Pages/secure/admin-timesheet";
import { jwtDecode } from "jwt-decode";
import { ITokenUser, IUser } from "../model/user";
import { tokenName } from "../model/auth";
import { PrintTimesheet } from "../printpages/printTimesheet";
import { WPrintTimesheet } from "../printpages/wprintTimesheet";
import { SecureLayout } from "./secureLayout";
import { PublicLayout } from "./publicLayout";
import { CompanyiesPage } from "../Pages/secure/company/companies";
import { ErrorPage } from "../Pages/public/error";
import { ManagerPage } from "../Pages/secure/manager";
import { ChangePasswordPage } from "../Pages/secure/changePassword";
import { Invoice } from "../Pages/secure/invoice/invoice";
import { CompanyPage } from "../Pages/secure/company/company";
import { PrivacyPage } from "../Pages/public/privacy";
import { useState } from "react";
import { VariablesPage } from "../Pages/secure/variables";
import { InactiveUsersPage } from "../Pages/secure/user/inactive";
import { PayrunPage } from "../Pages/secure/pay/payruns";

export const Layout = () => {
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  let token = JSON.parse(localStorage.getItem(tokenName));
  const user: ITokenUser = token ? jwtDecode(token) : null;
  var exp = user?.exp * 1000;
  let tokenIsExpired = !exp || new Date() > new Date(exp);

  const isAuthenticated: boolean = token && !tokenIsExpired ? true : false;

  return (
    <>
      <Routes>
        <Route path="/" element={<PublicLayout isAuthenticated={isAuthenticated} tokenUser={user} onMenuToggleClick={() => {}} />}>
          <Route index element={<HomePage />} />
          <Route path="/referral" element={<ReferralPage />} />
          <Route path="/holidays" element={<PublicHoliday />} />
          <Route path="/pay-calculator" element={<ContractorPayCalculator />} />
          <Route path="/tax-calculator" element={<TaxCalculator />} />
          <Route path="/esign/:id" element={<ESign />} />
          <Route path="/wesign/:id" element={<WESign />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/reset-password" element={<ResetPasswordPage />} />
          <Route path="/support" element={<SupportPage />} />
          <Route path="/payroll" element={<PayrollPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/error" element={<ErrorPage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
        </Route>

        <Route path="/timesheet" element={<SecureLayout isAuthenticated={isAuthenticated} tokenUser={user} openMenu={openMenu} onMenuToggleClick={() => {}} />}>
          {user?.Roles?.find((x) => x === "Employee") && (
            <>
              <Route
                index
                element={
                  <Timesheet
                    tokenUser={user}
                    onMenuToggleClick={(openMenu) => {
                      setOpenMenu(openMenu);
                    }}
                  />
                }
              />
              <Route path="charts" element={<Charts tokenUser={user} onMenuToggleClick={(openMenu) => setOpenMenu(openMenu)} />} />
            </>
          )}
          <Route path="change-settings" element={<ManagerPage tokenUser={user} onMenuToggleClick={(openMenu) => setOpenMenu(openMenu)} />} />
          <Route path="change-password" element={<ChangePasswordPage tokenUser={user} onMenuToggleClick={(openMenu) => setOpenMenu(openMenu)} />} />
          {user?.Roles?.find((x) => x === "PayrollAdmin") && (
            <>
              <Route path="admin/users/:id" element={<UsersPage tokenUser={user} onMenuToggleClick={(openMenu) => setOpenMenu(openMenu)} />} />
              <Route path="users/inactive" element={<InactiveUsersPage tokenUser={user} onMenuToggleClick={(openMenu) => setOpenMenu(openMenu)} />} />
              <Route path="admin/timesheets/:id" element={<AdminTimesheet tokenUser={user} onMenuToggleClick={(openMenu) => setOpenMenu(openMenu)} />} />
              <Route path="admin/user/:id" element={<AdminTimesheet tokenUser={user} onMenuToggleClick={(openMenu) => setOpenMenu(openMenu)} />} />
              <Route path="admin/invoices/:id?" element={<Invoice tokenUser={user} onMenuToggleClick={(openMenu) => setOpenMenu(openMenu)} />} />
              <Route path="admin/pay-runs" element={<PayrunPage tokenUser={user} onMenuToggleClick={(openMenu) => setOpenMenu(openMenu)} />} />
              <Route path="admin/company" element={<CompanyPage tokenUser={user} onMenuToggleClick={(openMenu) => setOpenMenu(openMenu)} />} />
            </>
          )}

          {user?.Roles?.find((x) => x === "SuperAdmin") && (
            <>
              <Route path="admin/companies" element={<CompanyiesPage tokenUser={user} onMenuToggleClick={(openMenu) => setOpenMenu(openMenu)} />} />
              <Route path="admin/variables" element={<VariablesPage tokenUser={user} onMenuToggleClick={(openMenu) => setOpenMenu(openMenu)} />} />
            </>
          )}
        </Route>

        <Route element={<NotFoundPage />} />
        <Route path="/print/:mode/:id" element={<PrintTimesheet />} />
        <Route path="/weekly/:mode/:id" element={<WPrintTimesheet />} />
      </Routes>
    </>
  );
};
